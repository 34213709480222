import styled, { css } from 'styled-components';

export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 52px;
  padding-top: 30px;
`;

export const TableFooterLinks = styled.div`
  padding-left: 1rem;
`;

export const NotFound = styled.div`
  p {
    line-height: 1.1875rem;
    color: #9299a2;
    margin: 0 0 3rem;
  }
`;

export const NotFoundTitle = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.222222222222222;
  color: #000000;
  margin: 0 0 1.125rem;
`;

export const FilterActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 1rem;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  .form {
    display: flex;
    justify-content: ${(props) => (props.filtersLine ? 'space-between' : 'flex-start')};
    width: 100%;
    flex-wrap: wrap;
    & > div {
      ${(props) =>
        props.filtersLine &&
        css`
          flex-grow: 1;
          margin: 0 0.5rem;
        `};
    }
    ${(props) =>
      props.filtersLine &&
      css`
        margin-left: -0.5rem;
        margin-right: -0.5rem;
      `}
  }
  &.with-filter .form-title {
    color: #f00;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const FilterControls = styled.div`
  text-align: right;
  position: absolute;
  right: 0;
  top: -2rem;
`;

export const FilterItem = styled.div`
  margin: 0 1rem 1.5rem 0;
  width: ${(props) => (props.$width ? props.$width : '13rem')};
  ${(props) =>
    props.$hidden &&
    css`
      display: none;
    `}
`;
export const FilterItemLabel = styled.div`
  margin: 0 0 0.25rem;
  font-size: 0.75rem;
  line-height: 1.25;
  min-height: 1rem;
  color: #9299a2;
`;

export const TableLoader = styled.div`
  display: block;
  background: rgba(255, 255, 255, 0.85) url(/images/loader.gif) no-repeat center;
  min-height: 200px;
  ${(props) =>
    props.fixed &&
    css`
      min-height: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999999;
    `}
`;
