import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Group = styled.div`
  display: flex;

  & > div {
    margin: 0;
  }
  & > div:nth-child(1) .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & > div:nth-child(2) .MuiInputBase-root {
    border-radius: 0;
  }
  & > button {
    min-width: 125px;
    width: 125px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${(props) =>
    props.$onlyFirst &&
    css`
      & > div:nth-child(2) {
        display: none !important;
      }
      & > button {
        display: none !important;
      }
    `};

  @media (max-width: 959px) {
    display: block;
    & > div {
      margin: 0 0 2rem;
    }
    & > button {
      width: 100%;
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }
    & > div:nth-child(1) .MuiInputBase-root {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem;
    }
    & > div:nth-child(2) .MuiInputBase-root {
      border-radius: 0.3125rem;
    }
  }
`;

export const Inline = (props) => {
  return <Group $onlyFirst={props.onlyFirst}>{props.children}</Group>;
};

Inline.defaultProps = {
  onlyFirst: false,
};

Inline.propTypes = {
  onlyFirst: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Inline;
