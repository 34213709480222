import userService from 'services/userService';

export const unsetUser = () => ({ type: 'UNSET_USER' });
export const setUser = (values) => ({ type: 'SET_USER', user: values });
export const setOrders = (orders, orderId) => ({ type: 'SET_ORDERS', orders: orders, orderId: orderId });
export const setNotification = (values) => ({ type: 'SET_NOTIFICATION', summ: values });

export const setCards = (values) => ({ type: 'SET_CARDS', cards: values });
export const setBoosters = (values) => ({ type: 'SET_BOOSTERS', boosters: values });

export const setOperations = (operations, hasMore, offset) => ({ type: 'SET_OPERATIONS', operations: operations, hasMore: hasMore, offset: offset });
export const addOperations = (operations, hasMore, offset) => ({ type: 'ADD_OPERATIONS', operations: operations, hasMore: hasMore, offset: offset });

export const setOrder = (order) => ({ type: 'SET_ORDER', order: order });

/**
 * Get full user info and set store
 *
 * @param {*} onSuccess
 */
export const getUser = (onSuccess, onError) => (dispatch) => {
  //console.log('userActions::getUser');
  userService.getUser(
    (result) => {
      dispatch(setUser(result));
      if (onSuccess) {
        onSuccess(result);
      }
    },
    (result) => {
      if (onError) {
        onError(result);
      }
    }
  );
};

/**
 * Login user action with check version inside
 * 1. login
 * 2. checkVersion
 * 3. get full user info
 *
 * @param {*} data {alias, password}
 * @param {*} onSuccess
 * @param {*} onError
 */
export const loginUser = (data, onSuccess, onError) => (dispatch) => {
  //console.log('userActions::loginUser');

  userService.login(
    data,
    () => {
      // success
      dispatch(getUser(onSuccess, onError)); // TODO: onError from login
    },
    onError
  );
};

/**
 * Check user token actuality
 *
 * @param {*} callback
 */
export const checkUser = (callback) => (dispatch) => {
  //console.log('userActions::checkUser');

  userService.init((tokenActive) => {
    //console.log('userActions::checkUser tokenActive ' + tokenActive);
    if (tokenActive) {
      dispatch(
        getUser(
          () => {
            dispatch({ type: 'USER_CHECKED', value: true, result: tokenActive });
            if (callback) {
              callback();
            }
          },
          () => {
            userService.logoutUser(false);
            dispatch({ type: 'USER_CHECKED', value: true, result: false });
          }
        )
      );
    } else {
      //console.log('3');
      dispatch({ type: 'USER_CHECKED', value: true, result: false });
    }
  });
};

/**
 * User logout
 */
export const logoutUser =
  (redirect = true) =>
  (dispatch) => {
    //console.log('userActions::logoutUser');
    window.continueLink = '';
    window.continueLinkAfterLogout = true;
    dispatch(unsetUser());
    userService.logoutUser(redirect);
  };
