import projectInfo from '../../package.json';
import meta from '../metadata.json';
import { httpErrors, errors, consts, adminMenu, clientMenu } from './defaults';
import './func';

// prepare config
const local = window.location.href.indexOf('localhost') !== -1;
const test = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.REACT_APP_DEV === '1' || local;

const out = { ...window.config };

// version
out.version = projectInfo.version + ', build: ' + meta.build;

// constants
if (!window.API_KEY) {
  window.API_KEY = '00';
}
out.API_KEY = window.API_KEY;

// default
out.httpErrors = httpErrors;
out.errors = errors;
out.defaultPath = '/main';
out.defaultAdminPath = '/clients';
out.consts = consts;
out.adminMenu = adminMenu;
out.clientMenu = clientMenu;
out.failAfter = 5000;

// test settings
if (test) {
  out.debug = true;
  out.test = true;
  if (local) {
    out.localhost = true;
    out.networkConfig.apiUrl = 'https://local.myboostr.ru/api';
    out.localhostLogin = { alias: 'admin@admin.ru', password: 'AngryGreenFish' };
    out.networkConfig.apiUrl = 'https://stage.myboostr.ru/api';
    out.localhostLogin = { alias: 'admin@stage.ru', password: '3oC6nEtLOHlf' };
  }

  console.log('env: test');
  console.log('API url: ' + out.networkConfig.apiUrl);
}

console.log('version: ' + out.version);

export const config = { ...out };
