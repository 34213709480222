import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

import { Button, Dialog } from 'libs/ui';
import { FormFooter, FormGroup } from 'libs/form';

export const Confirm = (props) => {
  const { error, open, action, closeModal, title, info, buttonTitle } = props;

  return (
    <Dialog title={title} withToolbar={false} open={open} onClose={closeModal}>
      <FormGroup fullWidth>{info}</FormGroup>
      {error && <Alert severity="error">{error}</Alert>}
      <FormFooter $nomargin $align="flex-end">
        <Button tabIndex="1" $color="link" onClick={closeModal}>
          Отменить
        </Button>
        <Button tabIndex="0" color="primary" onClick={() => action()}>
          {buttonTitle}
        </Button>
      </FormFooter>
    </Dialog>
  );
};

Confirm.defaultProps = {
  title: 'Подтвердите действие',
  info: '',
  error: '',
  buttonTitle: 'Подтвердить',
};

Confirm.propTypes = {
  buttonTitle: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.string,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

export default Confirm;
