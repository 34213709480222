import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';

import { config } from 'app/config';
import Header from './Header';
import Footer from './Footer';
import CookieConfirm from './CookieConfirm';
import theme from 'themes/themeCommon';
import { Container } from 'libs/ui';
import { Frontend } from './LayoutPublic.styled';

export const LayoutPublic = (props) => {
  const { readyForBackend, children, root } = props;
  const userLogined = useSelector((state) => state.user.userLogined);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  let doNavigate = false;

  useEffect(() => {
    if (readyForBackend /* && (['/confirmEmail', '/restorepass', '/registration', '/prefill'].indexOf(rest.path) === -1)*/) {
      const linkOld = window.continueLink;
      window.continueLink = '';
      let nextUrl = linkOld ? linkOld : root ? config.defaultAdminPath : config.defaultPath;
      if (typeof nextUrl === 'object' && nextUrl.pathname) {
        nextUrl = nextUrl.pathname;
      }
      console.log(' >> ' + nextUrl);
      doNavigate = true;
      navigate(nextUrl);
    }
  }, [readyForBackend]);

  if (doNavigate) {
    return null;
  }
  
  //console.log('🚀 ~ LayoutPublic');

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Frontend>
          <CssBaseline />
          <Header user={user} userLogined={userLogined} />
          <Container>{children}</Container>
          <Footer />
          <CookieConfirm />
        </Frontend>
      </StylesProvider>
    </ThemeProvider>
  );
};

LayoutPublic.propTypes = {
  children: PropTypes.node,
  readyForBackend: PropTypes.bool.isRequired,
  root: PropTypes.bool,
};

export default LayoutPublic;
