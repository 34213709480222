import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledInfo = styled.div`
  text-align: ${(props) => props.align};
  color: #4f4f4f;
  font-size: 1.125rem;
  line-height: 1.4;
  padding: 0 2.6875rem;
  margin: 0 0 3.5rem;
  ${(props) =>
    props.bg === 'gray' &&
    css`
      padding: 1.6875rem 2.75rem;
      background: #f2f4f5;
      border-left: 3px solid #dadada;
    `}
  ${(props) =>
    props.small === true &&
    css`
      padding: 1rem 2rem;
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1.428;
      border-left: 3px solid rgba(116, 163, 199, 0.6);
    `}
    
    ${(props) =>
    props.wide &&
    css`
      margin: 0 -3.625rem 3.5rem;
    `}

    ${(props) =>
    props.admin &&
    css`
      padding: 0;
      text-align: left;
    `}

    ${(props) =>
    props.admin &&
    props.wide &&
    css`
      padding: 1.6875rem 2.75rem;
      margin: 0 -3.625rem 3.5rem 0;
      text-align: center;
    `}
`;

export const FormInfo = (props) => {
  const { children, ...rest } = props;

  return <StyledInfo {...rest}>{children}</StyledInfo>;
};

FormInfo.propTypes = {
  bg: PropTypes.string,
  align: PropTypes.string,
  wide: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node,
};
FormInfo.defaultProps = {
  bg: '',
  align: 'center',
  small: false,
  wide: false,
};

export default FormInfo;
