import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as Checked } from './assets/checked.svg';
import { ReactComponent as Unchecked } from './assets/unchecked.svg';
import { ReactComponent as Down } from './assets/down.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as ArrowDown } from './assets/arrow_down.svg';
import { ReactComponent as ArrowDownSmall } from './assets/arrow_down_small.svg';
import { ReactComponent as Search } from './assets/search.svg';
import { ReactComponent as Plus } from './assets/plus.svg';

import { ReactComponent as SendTag } from './assets/actions/sendtag.svg';
import { ReactComponent as CloseAccount } from './assets/actions/closeaccount.svg';
import { ReactComponent as RequestBill } from './assets/actions/requestbill.svg';
import { ReactComponent as Correct } from './assets/actions/correct.svg';

import { ReactComponent as Download } from './assets/download.svg';
import { ReactComponent as Upload } from './assets/upload.svg';
import { ReactComponent as OperationsPassage } from './assets/operations/passage.svg';
import { ReactComponent as OperationsTopup } from './assets/operations/topup.svg';

import { ReactComponent as OperationsPurchase } from './assets/operations/purchase.svg';
import { ReactComponent as OperationsReturn } from './assets/operations/return.svg';
import { ReactComponent as OperationsCorrect } from './assets/operations/correct.svg';

import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Doc } from './assets/doc.svg';

import { ReactComponent as Passage } from './assets/passage.svg';
import { ReactComponent as Edit } from './assets/actions/edit.svg';
import { ReactComponent as Menu } from './assets/menu.svg';

import { ReactComponent as ActionClose } from './assets/actions2/close.svg';
import { ReactComponent as ActionAccounting } from './assets/actions2/accounting.svg';
import { ReactComponent as ActionContract } from './assets/actions2/contract.svg';
import { ReactComponent as ActionPrint } from './assets/actions2/print.svg';
import { ReactComponent as ActionEmpty } from './assets/actions2/empty.svg';
import { ReactComponent as ActionConfirm } from './assets/actions2/confirm.svg';
import { ReactComponent as ActionReject } from './assets/actions2/reject.svg';

import { ReactComponent as SetupZones } from './assets/setup/zones.svg';
import { ReactComponent as SetupLimits } from './assets/setup/limits.svg';
import { ReactComponent as SetupMasks } from './assets/setup/masks.svg';
import { ReactComponent as SetupTariffs } from './assets/setup/tariffs.svg';
import { ReactComponent as Setup } from './assets/setup/setup.svg';

import { ReactComponent as LeftArrow } from './assets/left.svg';
import { ReactComponent as Edited } from './assets/edited.svg';

import { ReactComponent as RadioChecked } from './assets/radiochecked.svg';
import { ReactComponent as RadioUnchecked } from './assets/radiounchecked.svg';

export const MenuIcon = (props) => {
  return <SvgIcon component={Menu} viewBox="0 0 18 12" {...props} />;
};

export const EditIcon = (props) => {
  return <SvgIcon component={Edit} viewBox="0 0 13 13" {...props} />;
};

export const PassageIcon = (props) => {
  return <SvgIcon component={Passage} viewBox="0 0 24 24" {...props} />;
};

export const CheckedIcon = (props) => {
  return <SvgIcon component={Checked} viewBox="0 0 32 32" {...props} />;
};

export const UncheckedIcon = (props) => {
  return <SvgIcon component={Unchecked} viewBox="0 0 32 32" {...props} />;
};

export const DownIcon = (props) => {
  return <SvgIcon component={Down} viewBox="0 0 10 6" {...props} />;
};

export const CheckIcon = (props) => {
  return <SvgIcon component={Check} viewBox="0 0 18 13" {...props} />;
};

export const ArrowDownIcon = (props) => {
  return <SvgIcon component={ArrowDown} viewBox="0 0 8 11" {...props} />;
};

export const ArrowDownSmallIcon = (props) => {
  return <SvgIcon component={ArrowDownSmall} viewBox="0 0 7 6" {...props} />;
};

export const SearchIcon = (props) => {
  return <SvgIcon component={Search} viewBox="0 0 16 16" {...props} />;
};

export const SendTagIcon = (props) => {
  return <SvgIcon component={SendTag} viewBox="0 0 21 20" {...props} />;
};

export const CloseAccountIcon = (props) => {
  return <SvgIcon component={CloseAccount} viewBox="0 0 21 20" {...props} />;
};

export const DownloadIcon = (props) => {
  return <SvgIcon component={Download} viewBox="0 0 24 24" {...props} />;
};

export const UploadIcon = (props) => {
  return <SvgIcon component={Upload} viewBox="0 0 24 24" {...props} />;
};

export const OperationsPassageIcon = (props) => {
  return <SvgIcon component={OperationsPassage} viewBox="0 0 24 24" {...props} />;
};

export const OperationsTopupIcon = (props) => {
  return <SvgIcon component={OperationsTopup} viewBox="0 0 24 24" {...props} />;
};

export const OperationsReturnIcon = (props) => {
  return <SvgIcon component={OperationsReturn} viewBox="0 0 16 16" {...props} />;
};
export const OperationsCorrectIcon = (props) => {
  return <SvgIcon component={OperationsCorrect} viewBox="0 0 19 19" {...props} />;
};
export const OperationsPurchaseIcon = (props) => {
  return <SvgIcon component={OperationsPurchase} viewBox="0 0 24 24" {...props} />;
};

export const CloseIcon = (props) => {
  return <SvgIcon component={Close} viewBox="0 0 15 15" {...props} />;
};

export const RequestBillIcon = (props) => {
  return <SvgIcon component={RequestBill} viewBox="0 0 18 22" {...props} />;
};

export const CorrectIcon = (props) => {
  return <SvgIcon component={Correct} viewBox="0 0 17 17" {...props} />;
};

export const DocIcon = (props) => {
  return <SvgIcon component={Doc} viewBox="0 0 17 25" {...props} />;
};

/**
 * actions in client detail
 *
 */
export const ActionCloseIcon = (props) => {
  return <SvgIcon component={ActionClose} viewBox="0 0 19 25" {...props} />;
};
export const ActionAccountingIcon = (props) => {
  return <SvgIcon component={ActionAccounting} viewBox="0 0 23 23" {...props} />;
};
export const ActionContractIcon = (props) => {
  return <SvgIcon component={ActionContract} viewBox="0 0 23 23" {...props} />;
};
export const ActionPrintIcon = (props) => {
  return <SvgIcon component={ActionPrint} viewBox="0 0 23 23" {...props} />;
};
export const ActionEmptyIcon = (props) => {
  return <SvgIcon component={ActionEmpty} viewBox="0 0 23 23" {...props} />;
};
export const ActionConfirmIcon = (props) => {
  return <SvgIcon component={ActionConfirm} viewBox="0 0 23 24" {...props} />;
};
export const ActionRejectIcon = (props) => {
  return <SvgIcon component={ActionReject} viewBox="0 0 23 24" {...props} />;
};

export const PlusIcon = (props) => {
  return <SvgIcon component={Plus} viewBox="0 0 13 13" {...props} />;
};

export const SetupLimitsIcon = (props) => {
  return <SvgIcon component={SetupLimits} viewBox="0 0 32 32" {...props} />;
};
export const SetupMasksIcon = (props) => {
  return <SvgIcon component={SetupMasks} viewBox="0 0 32 32" {...props} />;
};
export const SetupZonesIcon = (props) => {
  return <SvgIcon component={SetupZones} viewBox="0 0 34 34" {...props} />;
};
export const SetupTarifsIcon = (props) => {
  return <SvgIcon component={SetupTariffs} viewBox="0 0 32 32" {...props} />;
};
export const SetupIcon = (props) => {
  return <SvgIcon component={Setup} viewBox="0 0 32 32" {...props} />;
};

export const LeftArrowIcon = (props) => {
  return <SvgIcon component={LeftArrow} viewBox="0 0 20 20" {...props} />;
};

export const EditedIcon = (props) => {
  return <SvgIcon component={Edited} viewBox="0 0 16 16" {...props} />;
};

export const RadioCheckedIcon = (props) => {
  return <SvgIcon component={RadioChecked} viewBox="0 0 24 24" {...props} />;
};
export const RadioUncheckedIcon = (props) => {
  return <SvgIcon component={RadioUnchecked} viewBox="0 0 24 24" {...props} />;
};
