import PropTypes from 'prop-types';

import { Bar, StyledTitle } from './title-bar.styled';

export const TitleBar = (props) => {
  const { children, title, small, nowrap, ...rest } = props;

  return (
    <Bar {...rest}>
      {title && (
        <StyledTitle $nowrap={nowrap} $small={small}>
          {title}
        </StyledTitle>
      )}
      {children}
    </Bar>
  );
};

TitleBar.defaultProps = {
  $center: false,
  $bigMargin: false,
  nowrap: false,
  small: false,
};

TitleBar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  small: PropTypes.bool,
  $center: PropTypes.bool,
  nowrap: PropTypes.bool,
  $bigMargin: PropTypes.bool,
};

export default TitleBar;
