import { useState } from 'react';
import PropTypes from 'prop-types';
//import styled from 'styled-components';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';

import { params } from 'themes/themeCommon';
const paramsNew = JSON.parse(JSON.stringify(params));

export const DropZone = (props) => {
  const { showAlerts, previewTitle, title, filesLimit, disabled, success, ...rest } = props;
  const [file, setFile] = useState('');

  //console.log(rest);
  // TODO: initialFiles

  const onChange = (val) => {
    //console.log('drop', val, b)
    if (val[0] === undefined) {
      return;
    }
    //console.log('dropzone change', val[0])
    setFile(val[0].name);
    props.onChange(val[0]);
  };

  const onDelete = () => {
    //console.log('del', val);
    setFile('');
    props.onChange(null);
  };

  const useStyles = makeStyles({
    root: {
      position: 'relative',
      minHeight: props.small ? '120px' : '148px',
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: props.small ? '18px' : '25px',
        top: '50%',
        marginTop: props.small ? '-42px' : '-50px',
        width: props.small ? '85px' : '100px',
        height: props.small ? '85px' : '100px',
        backgroundImage: 'url(' + props.icon + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '-1px 50%',
        backgroundSize: 'contain',
      },
    },
  });
  const classes = useStyles();

  paramsNew.overrides.MuiDropzonePreviewList['root'].width = props.small ? '85px' : '100px';
  paramsNew.overrides.MuiDropzonePreviewList['root'].height = props.small ? '85px' : '100px';
  paramsNew.overrides.MuiDropzonePreviewList['root'].marginTop = props.small ? '-42px' : '-50px';
  paramsNew.overrides.MuiDropzonePreviewList['root'].left = props.small ? '18px' : '25px';
  paramsNew.overrides.MuiDropzonePreviewList['image'].height = props.small ? '85px' : '100px';
  paramsNew.overrides.MuiDropzonePreviewList['imageContainer'].textAlign = props.small ? 'left' : 'center';

  const theme = createTheme(paramsNew);

  if (success) {
    rest.dropzoneClass = 'success';
  }

  const inputTitle = file ? file : title;

  return (
    <MuiThemeProvider theme={theme}>
      <DropzoneArea
        {...rest}
        dropzoneProps={{ disabled: disabled }}
        classes={classes}
        showAlerts={showAlerts}
        previewText={previewTitle}
        dropzoneText={inputTitle}
        filesLimit={filesLimit}
        onChange={onChange}
        onDelete={onDelete}
      />
    </MuiThemeProvider>
  );
};

DropZone.propTypes = {
  showAlerts: PropTypes.bool,
  previewTitle: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  filesLimit: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  maxFileSize: PropTypes.number,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  small: PropTypes.bool,
  acceptedFiles: PropTypes.array,
};

DropZone.defaultProps = {
  acceptedFiles: undefined,
  disabled: false,
  success: false,
  small: true,
  showAlerts: true,
  filesLimit: 1,
  maxFileSize: 14000000,
};

export default DropZone;
