import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Router from './router/router';
import * as userActions from 'store/actions/userActions';

class App extends React.Component {
  componentDidMount() {
    console.log('app mount');

    // check user token and set store
    this.props.dispatch(userActions.checkUser());
  }

  render() {
    const { readyForBackend, userChecked, root } = this.props;

    // if not user checked not render
    if (!userChecked) {
      return null;
    }

    // render router
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
        <Router readyForBackend={readyForBackend} root={root} />
      </MuiPickersUtilsProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  readyForBackend: PropTypes.bool,
  userChecked: PropTypes.bool,
  root: PropTypes.bool,
};

const mapStateToProps = function (store) {
  return {
    userChecked: store.user.userChecked, // user token checked
    readyForBackend: store.user.readyForBackend, // user ready for backend
    user: store.user.user, // user
    root: store.user.root, //root
  };
};

export default connect(mapStateToProps)(App);
