import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { config } from 'app/config';
import { Link } from 'libs/ui';
import { Place } from './logo.styled';

export const Logo = (props) => {
  const { inline } = props;
  const readyForBackend = useSelector((store) => store.user.readyForBackend);
  const root = useSelector((store) => store.user.root);

  let logo = 'logo.svg';
  if (config.organisations[config.API_KEY]) {
    const org = config.organisations[config.API_KEY];
    if (org.logo) {
      logo = 'logos/' + org.logo;
    }
  }

  logo = '/images/' + logo;
  const to = readyForBackend ? (root ? config.defaultAdminPath : config.defaultPath) : '/login';

  return (
    <Place inline={inline}>
      <Link to={to}>
        <img alt="BSTR ENTERPRISE" src={logo} />
      </Link>
    </Place>
  );
};

Logo.propTypes = {
  inline: PropTypes.bool,
};

export default Logo;
