import styled, { css } from 'styled-components';

export const FormLoader = styled.div`
  position: relative;
  width: 100%;
  ${(props) =>
    props.$loading &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999999;
        background: rgba(255, 255, 255, 0.85) url(/images/loader.gif) no-repeat center;
      }
    `}

  form.error {
    .input-1 .MuiInputBase-root {
      border-color: #eb5757;
      border-right: 0;
    }
    .input-2 .MuiInputBase-root {
      border-color: #eb5757;
      border-left: 0;
    }
    .MuiInputBase-root {
      border-color: #eb5757;
    }
  }

  @media (max-width: 959px) {
    form.error {
      .input-1 .MuiInputBase-root {
        border-right: 1px solid #eb5757;
      }
      .input-2 .MuiInputBase-root {
        border-left: 1px solid #eb5757;
      }
    }
  }
`;
