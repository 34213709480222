import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Scrollbar } from 'react-scrollbars-custom';
import Pager from './PagerMUI';
import DownloadLinks from './DownloadLinks';

import { Button } from 'libs/ui';
import { Icon } from 'libs/ui';
import { TableLoader, TableFooter, TableFooterLinks } from './styled';

class TableMaterialUI extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      scrollXend: false,
      width: window.innerWidth,
    };

    this.tableRef = React.createRef();
    this.fields = {};
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    //console.log('hhh' + this.props.maxFilters,this.tableRef.current.clientHeight);
    this.setState({ width: window.innerWidth });
  };

  onRowClick = (__event, idx) => {
    //console.log(event.event.target)
    if (this.props.rowClick) {
      this.props.rowClick(this.props.items[idx]);
      return;
    }

    if (this.props.selector) {
      const { selected, selectOne } = this.props;
      selectOne(idx, !selected[idx]);
    }
  };

  /**
   * sort handler
   *
   * @param {*} property
   */
  sortHandler = (property) => (event) => {
    this.props.onSortChange(event, property);
  };

  /**
   * generate table header
   *
   * @returns table header
   * @memberof TableMaterialUI
   */
  getColumns = () => {
    const { fields, rowActions, selector, selectedAll, selectAll, order, orderBy } = this.props;

    const useActions = rowActions.length > 0;
    let out = [];

    // selector
    if (selector) {
      out.push(
        <TableCell key="selectall" padding="checkbox" style={{ width: 50 }}>
          <Checkbox
            title="Выделить все"
            size="small"
            //style={{ padding: 0 }}
            checked={selectedAll}
            //indeterminate={numSelected > 0 && numSelected < rowCount}
            onChange={(event) => {
              return selectAll(event.target.checked);
            }}
            value="1"
          />
        </TableCell>
      );
    }

    // other
    fields.forEach((item) => {
      const other = {};

      if (item.width) {
        other.style = { width: item.width };
      } else {
        if (this.state.width < 1200) {
          other.style = { width: '6rem' };
        }
      }
      out.push(
        <TableCell key={item.name} className="left" sortDirection={orderBy === item.name ? order : false} {...other}>
          <TableSortLabel active={orderBy === item.name} direction={orderBy === item.name ? order : 'asc'} onClick={this.sortHandler(item.name)}>
            {item.label}
          </TableSortLabel>
        </TableCell>
      );
    });

    // actions
    if (useActions) {
      out.push(<TableCell key="action" style={{ width: 100 }} className="actions"></TableCell>);
    }

    return out;
  };

  /**
   * get content for table field
   *
   * @memberof TableMaterialUI
   */
  getItemContent = (field, item) => {
    let val = item[field.name];
    let dataKey = field.name;
    if (field['func'] !== undefined) {
      dataKey = 'func';
    }

    //console.log(field, item)

    switch (dataKey) {
      case 'func':
        val = field.func(item);
        break;
      default:
        if (field.values) {
          if (val === 'false') {
            val = '0';
          }
          if (val === 'true') {
            val = '1';
          }
          val = field.values[val];
          if (dataKey === this.props.activeField) {
            if (val !== 'Да') {
              val = '';
            } else {
              val = (
                <Button size="sm" className="table-btn table-btn--success" color="light">
                  <Icon kvsize="16px" size="16px" type="check" />
                </Button>
              );
            }
          }
          //console.log('123213', field, val)
        } else {
          if (field.type === 'date') {
            val = this.props.formatDate(val, field.withTime);
          }
        }
        break;
    }
    if (!val) {
      val = <>&nbsp;</>;
    }
    return val;
  };

  /**
   * render item actions
   *
   * @memberof TableMaterialUI
   */
  getActions = (item) => {
    const { idField, nameField } = this.props;

    const actionParams = {
      id: item[idField],
      name: item[nameField],
      title: '',
      item: item,
    };

    let id = 0;
    let key;
    const out = this.props.rowActions.map((action) => {
      id++;
      key = item[idField] + '_a' + id;
      let actionParamsReal = { ...actionParams };

      if (action.func === 'link') {
        return (
          <Button key={key} to={action.link.replace(':id', item[idField])} title={action.title}>
            <Icon kvsize="16px" size="16px" type={action.icon} />
          </Button>
        );
      }
      let func = action.func;
      if (func === 'delete') {
        func = this.props.deleteAction;
      }
      actionParamsReal.title = action.title;
      return (
        <Button
          $onlyicon
          $color="link"
          $nopad
          color="default"
          size="small"
          key={key}
          actionParams={actionParamsReal}
          action={func}
          title={action.title}
          icon="cancel"
        ></Button>
      );
    });
    return out;
  };

  /**
   * generate table items
   *
   * @memberof TableMaterialUI
   */
  getItems = () => {
    const { fields, items, selector, selected, selectOne, rowActions } = this.props;
    const useActions = rowActions.length > 0;

    return items.map((item, idx) => {
      const out = [];
      const isItemSelected = selected[idx];

      // common
      if (selector) {
        out.push(
          <TableCell padding="checkbox" key="selector">
            <Checkbox
              title="Выделить"
              size="small"
              //style={{ padding: 0 }}
              checked={isItemSelected ? true : false}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(event) => {
                return selectOne(idx, event.target.checked);
              }}
              value="1"
            />
          </TableCell>
        );
      }

      // fields
      fields.forEach((field, idx1) => {
        out.push(<TableCell key={idx1}>{this.getItemContent(field, item)}</TableCell>);
      });

      // actions
      if (useActions) {
        out.push(
          <TableCell className="row-actions" key="actions">
            {this.getActions(item)}
          </TableCell>
        );
      }

      return (
        <TableRow key={idx} hover onClick={(event) => this.onRowClick(event, idx)} role="checkbox" selected={isItemSelected}>
          {out}
        </TableRow>
      );
    });
  };

  onScroll = (scrollValues) => {
    const end = scrollValues.scrollLeft + scrollValues.clientWidth + 1 >= scrollValues.scrollWidth;
    if (end !== this.state.scrollXend) {
      this.setState({ scrollXend: end });
    }
  };

  /**
   * render
   *
   * @returns
   * @memberof TableMaterialUI
   */
  render() {
    const { items, loading, download } = this.props;

    if (!items || items.length === 0) {
      return null;
    }

    const { scrollXend } = this.state;

    const columnsElements = this.getColumns();
    const itemsElements = this.getItems();
    const cl = scrollXend ? 'scrollXend' : '';
    //console.log('scrollXend', scrollXend);

    return (
      <TableContainer className={cl} style={{ position: 'relative', flexGrow: 1 }} ref={this.tableRef}>
        {loading && <TableLoader fixed />}
        <Scrollbar
          translateContentSizeYToHolder={true}
          noScrollY={true}
          onScroll={this.onScroll}
          trackXProps={{
            renderer: (props) => {
              const { elementRef, ...restProps } = props;
              return (
                <div className="ScrollbarsCustom-TrackX__pre">
                  <div {...restProps} ref={elementRef} className="ScrollbarsCustom-Track ScrollbarsCustom-TrackX"></div>
                </div>
              );
            },
          }}
        >
          <Table size="medium">
            <TableHead>
              <TableRow>{columnsElements}</TableRow>
            </TableHead>
            <TableBody>{itemsElements}</TableBody>
          </Table>
        </Scrollbar>
        <TableFooter>
          {download ? (
            <TableFooterLinks>
              <DownloadLinks download={download} />
            </TableFooterLinks>
          ) : (
            <div></div>
          )}
          {this.props.customPager ? (
            <>{this.props.customPager(this.props)}</>
          ) : (
            <Pager
              rowsPerPageOptions={this.props.pages}
              count={this.props.count}
              limit={this.props.limit}
              page={this.props.page}
              onChangePage={this.props.handleChangePage}
              onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            />
          )}
        </TableFooter>
      </TableContainer>
    );
  }
}

TableMaterialUI.propTypes = {
  onSortChange: PropTypes.func,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  items: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  rowActions: PropTypes.array,
  selector: PropTypes.bool,
  hideHeader: PropTypes.bool,
  formatDate: PropTypes.func.isRequired,
  wide: PropTypes.bool,
  rowClick: PropTypes.func,
  limit: PropTypes.number,
  selectOne: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  idField: PropTypes.string,
  nameField: PropTypes.string,
  customPager: PropTypes.func,
  deleteAction: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  download: PropTypes.func,
  pages: PropTypes.array,
  page: PropTypes.number,
  count: PropTypes.number,
  selected: PropTypes.object,
  selectedAll: PropTypes.bool,
  activeField: PropTypes.string,
  elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

TableMaterialUI.defaultProps = {
  orderBy: '',
  order: 'asc',
  deleteAction: null,
  hideHeader: false,
  selector: false,
  selectedAll: false,
  idField: 'id',
  nameField: 'name',
  activeField: 'flag_enable',
  limit: 25,
  page: 0,
  offset: 0,
  pagination: false,
  wide: false,
  loading: false,
  pages: [5, 10, 25, 50, 100],
  customPager: null,
};

export default TableMaterialUI;
