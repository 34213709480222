import PropTypes from 'prop-types';

import { StyledTitle } from './title.styled';

export const Title = (props) => {
  const { children, ...rest } = props;

  return <StyledTitle {...rest}>{children}</StyledTitle>;
};

Title.defaultProps = {
  light: true,
  big: false,
  $leftAlign: false,
  noMargin: false,
};

Title.propTypes = {
  children: PropTypes.node,
  noMargin: PropTypes.bool,
  $leftAlign: PropTypes.bool,
  light: PropTypes.bool,
  big: PropTypes.bool,
};

export default Title;
