import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import * as common from 'services/common';

const StyledNotification = styled.div`
  margin: 0 0 20px;
  color: #dd5656;
  ${(props) =>
    props.color === 'inline' &&
    css`
      margin: 0;
      padding: 7px 0 0 17px;
      color: #dd5656;
      font-size: 0.75rem;
      line-height: 1.25;
    `}
  ${(props) =>
    props.small &&
    css`
      position: absolute;
      top: 102%;
      padding: 0.5rem 0rem 0; //1
      left: 0rem;
      white-space: nowrap;
      font-size: 0.75rem;
      line-height: 1;
    `}
`;

export const Notification = (props) => {
  const { message, code, color, children, small } = props;
  let msg = message ? message : common.getError(code);

  if (!msg) {
    return null;
  }

  return (
    <StyledNotification className="form-notification" color={color} small={small}>
      {msg}
      {children && (
        <>
          <br />
          {children}
        </>
      )}
    </StyledNotification>
  );
};

Notification.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  code: PropTypes.string,
  small: PropTypes.bool,
  color: PropTypes.string,
};

Notification.defaultProps = {
  small: false,
  color: 'danger',
};

export default Notification;
