import PropTypes from 'prop-types';
import { StyledTabPanel, Box, Content } from './tab-panel.styled';

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  if (value !== index) {
    return null;
  }

  return (
    <StyledTabPanel role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box>
        <Content>{children}</Content>
      </Box>
    </StyledTabPanel>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
