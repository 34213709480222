import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledFormFooter = styled.div`
  margin: ${(props) => (props.$nomargin ? '1rem 0 0' : '3rem 0 0')};
  display: flex;
  justify-content: ${(props) => props.$align};
  align-items: center;
  & > * {
    ${(props) =>
      props.$align === 'flex-start' &&
      css`
        margin-right: 1.5rem;
      `};
  }
`;

export const FormFooter = (props) => {
  const { children, ...rest } = props;
  return <StyledFormFooter {...rest}>{children}</StyledFormFooter>;
};
FormFooter.defaultProps = {
  $align: 'space-between',
};

FormFooter.propTypes = {
  $align: PropTypes.string,
  children: PropTypes.node,
};

export default FormFooter;
