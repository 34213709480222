import styled from 'styled-components';

export const Place = styled.div`
  max-width: 66px;
  cursor: pointer;
  & img {
    max-width: 100%;
    height: auto;
  }
  margin: 0 7.5rem 0 0;

  @media (max-width: 959px) {
    margin: 0 1rem 0 0;
  }
`;
