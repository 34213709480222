import React from 'react';
import PropTypes from 'prop-types';

import * as common from 'services/common';
import { Confirm } from 'libs/ui';
import TableTop from './TableTop';
import TableView from './TableMaterialUI';
import { TableLoader, NotFound, NotFoundTitle } from './styled';

export class Table extends React.Component {
  constructor(props) {
    super(props);

    const current = {};
    props.filters.forEach((el) => {
      current[el.name] = el.initial ? (el.initial === '-1' ? '' : el.initial) : '';
    });

    this.state = {
      loading: false,
      refreshed: false,
      items: [], // items on current page
      count: 0, // all items cnt
      page: 0, // current page
      limit: props.limit, // max items on page
      flt: { ...props.defaultFlt, ...current },
      orderBy: props.orderBy,
      order: props.order,

      error: '',
      showConfirm: false,
      confirmAction: null,
      confirmText: '',
      selected: {},
      selectedAll: props.selectedAll,
    };

    if (props.commands) {
      props.commands({ refresh: this.refresh, getRefreshParams: this.getRefreshParams });
    }
  }

  componentDidMount() {
    this.refresh();
  }

  /**
   * test for reload needness
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    const pagerParamsChanged = this.state.page !== prevState.page || this.state.limit !== prevState.limit;
    const fltChanged = JSON.stringify(this.state.flt) !== JSON.stringify(prevState.flt);
    const orderChanged = this.state.orderBy !== prevState.orderBy || this.state.order !== prevState.order;
    const extChanged = this.props.limit !== prevProps.limit;

    if (pagerParamsChanged || fltChanged || orderChanged || extChanged) {
      //console.log('table componentDidUpdate', pagerParamsChanged, fltChanged, orderChanged);
      //console.log('flt current old', this.state.flt, prevState.flt);
      this.refresh();
    }
  }

  download = (format) => {
    if (!this.props.download) {
      return;
    }
    this.props.download(this.getRefreshParams(), format);
  };

  getRefreshParams = () => {
    const { page, limit, orderBy, order } = this.state;
    let page1 = page;

    const flt = { ...this.props.defaultFlt, ...this.state.flt };
    if (!common.equalObjects(this.flt, flt)) {
      page1 = 0;
      this.setState({ page: 0 });
    }

    this.flt = flt;

    const params = { offset: page1 * limit, limit: limit, ...flt };
    params.orderby = orderBy ? orderBy + ' ' + order : '';
    return params;
  };

  /**
   * reload items from api
   */
  refresh = () => {
    const params = this.getRefreshParams();

    // request api
    this.setState({ loading: true });
    this.props
      .refresh(params)
      .then((data) => {
        common.fakeWait(30);
        this.setState({ refreshed: true, items: data.items ? data.items : data.results, count: data.count, loading: false });
      })
      .catch((error) => {
        //console.log('123', error)
        common.notify('Ошибка API', JSON.stringify(error));
        this.setState({ refreshed: true, items: [], count: 0, loading: false });
      });
  };

  /**
   * format date
   *
   * @param {*} value
   * @param {*} withTime
   */
  formatDate = (value, withTime = false) => {
    if (!value) {
      return value;
    }
    let format = this.props.dateFormat; //'D MMMM YYYY';
    if (withTime) {
      format += ' ' + this.props.timeFormat; //HH:mm:ss';
    }
    return common.formatDate(value, format);
  };

  selectAll = (selectedAll) => {
    const selected = {};
    if (selectedAll) {
      this.state.items.forEach((__el, idx) => {
        selected[idx] = true;
      });
    }

    this.setState({ selectedAll: selectedAll, selected: selected }, () => {
      this.refreshSelected();
    });
  };

  selectOne = (idx, active) => {
    const selected = Object.assign({}, this.state.selected);
    if (active) {
      selected[idx] = true;
    } else {
      delete selected[idx];
    }
    this.setState({ selected: selected }, () => {
      this.refreshSelected();
    });
  };

  refreshSelected = () => {
    if (this.props.selectedChanges) {
      const idField = this.props.idField;

      let lastItem = 0;
      const selected = Object.keys(this.state.selected).map((item, idx) => {
        if (!idx) {
          this.firstSelected = item;
        }
        lastItem = item;
        //console.log(item, idField);
        return this.state.items[item][idField];
      });
      this.lastSelected = lastItem;
      //console.log(this.firstSelected, this.lastSelected)
      this.props.selectedChanges(selected);
    }
  };

  // row = {id, name}
  deleteAction = (row) => {
    //console.log('123123', row)
    if (!this.props.deleteAction) {
      return;
    }

    const title = row.title ? row.title : 'Удалить';
    const name = row.name ? row.name : '';
    //console.log('123123', title );

    this.setState({
      error: '',
      showConfirm: true,
      confirmText: title + ' ' + name,
      confirmAction: () => {
        this.props.deleteAction(
          row.id,
          () => {
            this.setState({ forceRefresh: true }, () => {
              this.refresh();
              this.closeConfirm();
            });
          },
          (error) => {
            const out = typeof error === 'object' ? JSON.stringify(error) : error;
            //console.log('error', error);
            this.setState({ error: out });
          }
        );
      },
    });
  };

  closeConfirm = () => {
    this.setState({ showConfirm: false });
  };

  /**
   * reset page and selection
   */
  resetParams = () => {
    this.refreshSelected();
    return { selected: {}, selectedAll: false };
  };

  /**
   * on filter change
   *
   * @param {*} flt
   */
  filterChange = (flt) => {
    //console.log('filter change', flt)
    if (this.props.prepareFlt) {
      flt = this.props.prepareFlt(flt);
    }
    const out = this.resetParams();
    this.setState({ ...out, flt: flt });
  };

  /**
   * change page
   *
   * @param {*} event
   * @param {*} page
   */
  handleChangePage = (__ignored, page) => {
    this.setState({ page: page });
  };

  /**
   * change page size
   *
   * @param {*} event
   */
  handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value);
    const out = this.resetParams();
    this.setState({ page: 0, limit: newLimit, ...out });
  };

  /**
   * change sort and it order
   *
   * @param {*} event
   * @param {*} field
   */
  handleSort = (__ignored, field) => {
    //console.log('sort', field);
    let { order, orderBy } = this.state;
    if (orderBy === field) {
      order = order === 'asc' ? 'desc' : 'asc';
    } else {
      order = 'asc';
    }
    this.setState({ orderBy: field, order: order });
  };

  getTableHeight = (height) => {
    this.height = height;
  };

  render() {
    const { showConfirm, confirmAction, confirmText, selected, items, count, error, page, limit, orderBy, order, loading, refreshed } = this.state;
    const { actions, filters, title, preTitle, selectedChanges, filtersLine, maxFilters, pageSizes, ...props } = this.props;

    const tableProps = { ...props };
    delete tableProps.limit;
    delete tableProps.orderBy;
    delete tableProps.order;
    delete tableProps.refresh;
    delete tableProps.deleteAction;
    delete tableProps.type;
    delete tableProps.selectedAll;

    //const showTable = ((typeof items === 'object') && items.length > 0) || loading;
    const empty = typeof items === 'object' && items.length === 0 && refreshed;
    const showTable = !empty; // && !loading;
    const showFiltersInfo = filters.length > 0;

    if (this.props.download) {
      tableProps.download = this.download;
    }

    const showLoader = !empty && loading;
    //console.log('🚀 ~ render ~ showLoader:', showLoader, empty, loading);

    //console.log('table render', 'loading: ' + loading)
    //const loaderAttr = {style: {height: this.height + 'px'}};
    //console.log('showTable', showTable)
    return (
      <>
        <TableTop
          filterChange={this.filterChange}
          filters={filters}
          actions={actions}
          title={title}
          preTitle={preTitle}
          filtersLine={filtersLine}
          maxFilters={maxFilters}
        />

        {showLoader && <TableLoader />}

        {showTable && (
          <TableView
            getHeight={this.getTableHeight}
            //maxFilters={maxFilters}
            loading={loading}
            items={items}
            count={count}
            page={page}
            limit={limit}
            order={order}
            orderBy={orderBy}
            onSortChange={this.handleSort}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            selectedChanges={selectedChanges}
            selected={selected}
            selectedAll={this.state.selectedAll}
            selectAll={this.selectAll}
            selectOne={this.selectOne}
            formatDate={this.formatDate}
            deleteAction={this.deleteAction}
            //pageSizes={pageSizes}
            pages={pageSizes}
            {...tableProps}
          />
        )}
        {!loading && empty && (
          <NotFound>
            <NotFoundTitle>Записи не найдены</NotFoundTitle>
            {showFiltersInfo && <p>Измените условия поиска</p>}
          </NotFound>
        )}

        {showConfirm && (
          <Confirm
            error={error}
            open={showConfirm}
            closeModal={this.closeConfirm}
            title="Подтвердите действие"
            info={confirmText}
            action={confirmAction}
          />
        )}
      </>
    );
  }
}

Table.propTypes = {
  orderBy: PropTypes.string,
  order: PropTypes.string,
  forceRefresh: PropTypes.bool,
  defaultFlt: PropTypes.object,
  limit: PropTypes.number,
  useNField: PropTypes.bool,
  selectedAll: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  actions: PropTypes.array,
  rowActions: PropTypes.array,
  filters: PropTypes.array,
  title: PropTypes.string,
  preTitle: PropTypes.string,
  selector: PropTypes.bool,
  filtersLine: PropTypes.bool,
  rowClick: PropTypes.func,
  wide: PropTypes.bool,
  commands: PropTypes.func,
  prepareFlt: PropTypes.func,
  customPager: PropTypes.func,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  idField: PropTypes.string,
  deleteAction: PropTypes.func,
  maxFilters: PropTypes.number,
  pageSizes: PropTypes.array,
  searchFields: PropTypes.arrayOf(PropTypes.string),
  selectedChanges: PropTypes.func,
  download: PropTypes.func,
};

Table.defaultProps = {
  orderBy: '',
  order: 'asc',
  rowActions: [],
  limit: config.defaultPageSize,
  prepareFlt: null,
  filters: [],
  defaultFlt: {},
  actions: [],
  resetSelected: false,
  deleteAction: null,
  useNField: false,
  selector: false,
  selectedAll: false,
  selectedChanges: null,
  preTitle: 'Добавить',
  title: '',
  idField: 'id',
  nameField: 'name',
  activeField: 'flag_enable',
  dateFormat: 'DD.MM.YYYY', //'D MMMM YYYY',
  timeFormat: 'HH:mm:ss',
  pagination: 'default',
  rowClick: null,
  wide: false,
  filtersLine: false,
  customPager: null,
  pageSizes: config.pageSizes,
  searchFields: ['search', 'request'],
};

export default Table;
