import { useState } from 'react';
import PropTypes from 'prop-types';

import { Content, Flex, Left, Right, Title } from './multi-list.styled';
import { List } from './list';

export const MultiList = (props) => {
  const { minHeight, items1, items2, title, listTitle, onChange } = props;
  const [list1, setList1] = useState(items1);
  const [list2, setList2] = useState(items2);

  const leftClick = (item) => {
    const list1new = list1.slice(0);
    const list2new = list2.slice(0);

    // find index in left and remove
    const idx1 = list1new.findIndex((el) => el.id === item.id);
    list1new.splice(idx1, 1);

    // add to right
    list2new.push(item);

    // save
    setList1(list1new);
    setList2(list2new);

    // notify
    onChange(list1new, list2new);
  };

  const rightClick = (item) => {
    const list1new = list1.slice(0);
    const list2new = list2.slice(0);

    // find index in left and remove
    const idx1 = list2new.findIndex((el) => el.id === item.id);
    list2new.splice(idx1, 1);

    // add to right
    list1new.push(item);

    // save
    setList1(list1new);
    setList2(list2new);

    // notify
    onChange(list1new, list2new);
  };

  return (
    <Content>
      <Title>{title}</Title>
      <Flex minHeight={minHeight}>
        <Left>
          <List title={'Доступные ' + listTitle} items={list1} onClick={leftClick} />
        </Left>
        <Right>
          <List title={'Выбранные ' + listTitle} items={list2} onClick={rightClick} />
        </Right>
      </Flex>
    </Content>
  );
};

MultiList.propTypes = {
  onChange: PropTypes.func,
  minHeight: PropTypes.any,
  title: PropTypes.string,
  listTitle: PropTypes.string,  
  items1: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  items2: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default MultiList;
