import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { config } from 'app/config';
import { Menu, MenuItem } from './MainMenu.styled';

export const MainMenu = (props) => {
  const location = useLocation();
  if (
    !props.readyForBackend ||
    !props.userLogined /*|| !(props.user.flag_enable === '1' && props.user.flag_valid === '1' && props.user.flag_password === '1')*/
  ) {
    return null;
  }

  const menu = props.root ? config.adminMenu : config.clientMenu;
  const items = menu.map((item, index) => {

    // custom active
    const other = {};
    if (item.otherItems) {
      other.className = ({ isActive }) => {
        //console.log('isactive', isActive)
        if (isActive) {
          return 'active';
        }
        let out = false;
        item.otherItems.forEach((el) => {
          if (!out && location.pathname.indexOf(el) !== -1) {
            out = true;
          }
        });
        
        return out ? 'active' : '';
      };
    }
    return (
      <MenuItem key={index}>
        <NavLink {...other} to={item.link}>
          {item.title}
        </NavLink>
      </MenuItem>
    );
  });

  return <Menu>{items}</Menu>;
};

MainMenu.propTypes = {
  readyForBackend: PropTypes.bool,
  dispatch: PropTypes.func,
  userLogined: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  root: PropTypes.bool,
};

const mapStateToProps = function (store) {
  return {
    //userChecked: store.user.userChecked,
    user: store.user.user,
    readyForBackend: store.user.readyForBackend,
    userLogined: store.user.userLogined,
    root: store.user.root,
  };
};

export default connect(mapStateToProps)(MainMenu);
