const commonReducer = function (state, action) {
  const storeName = 'global_counters';

  if (!state) {
    let counters = localStorage.getItem(storeName);
    if (!counters) {
      counters = {};
    } else {
      counters = JSON.parse(counters);
    }

    Object.keys(counters).forEach((key) => {
      const item = counters[key];
      counters[key].autostart = item.counter && item.counting;
    });
    state = { breadcrumbs: [], loading: true, counters: counters, tmpEmail: '', tmpPhone: '' };
  }

  let counters;
  let breadcrumbs;

  switch (action.type) {
    case 'BREADCRUMBS_SET':
      //console.log(action.values);
      return Object.assign({}, state, { breadcrumbs: action.values });
    case 'BREADCRUMBS_PUSH':
      breadcrumbs = state.breadcrumbs.concat();
      breadcrumbs.push(action.value);
      return Object.assign({}, state, { breadcrumbs: breadcrumbs });
    case 'BREADCRUMBS_POP':
      breadcrumbs = state.breadcrumbs.concat();
      breadcrumbs.pop();
      return Object.assign({}, state, { breadcrumbs: breadcrumbs });
    case 'BREADCRUMBS_CHANGE':
      //console.log(action.values);
      breadcrumbs = state.breadcrumbs.concat();
      breadcrumbs[action.id] = Object.assign({}, breadcrumbs[action.id], action.value);
      return Object.assign({}, state, { breadcrumbs: breadcrumbs });
    case 'SET_LOADING':
      return Object.assign({}, state, { loading: action.loading });
    case 'SET_TMP_EMAIL':
      return Object.assign({}, state, { tmpEmail: action.email });
    case 'SET_TMP_PHONE':
      return Object.assign({}, state, { tmpPhone: action.phone });
    case 'SET_COUNTER':
      counters = Object.assign({}, state.counters);
      counters[action.var] = { counter: action.counter, counting: action.counting, autostart: false, var: action.var };
      localStorage.setItem(storeName, JSON.stringify(counters));
      return Object.assign({}, state, { counters: counters });
    default:
      return state;
  }
};

export default commonReducer;
