import PropTypes from 'prop-types';

import { Title } from 'libs/ui';

export const Success = (props) => {
  const { title, children, icon, backlink, backlinkTitle } = props;
  const fn = icon ? '/images/' + icon : '';

  return (
    <PageCenter>
      <Title big={true}>{title}</Title>
      {children && <Text>{children}</Text>}
      {fn && (
        <Image>
          <img src={fn} alt="success" />
        </Image>
      )}
      {backlink && (
        <Buttons>
          <StyledButton color="primary" to={backlink}>
            {backlinkTitle}
          </StyledButton>
        </Buttons>
      )}
    </PageCenter>
  );
};

Success.propTypes = {
  title: PropTypes.string,
  alert: PropTypes.bool,
  backlink: PropTypes.string,
  backlinkTitle: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
};

Success.defaultProps = {
  alert: true,
};

export default Success;
