const defaultState = { types: [] };

const contragentReducer = function (state = defaultState, action) {
  switch (action.type) {
    case 'SET_CONTRAGENT_TYPES':
      return Object.assign({}, state, { types: action.values });
    default:
      return state;
  }
};

export default contragentReducer;
