const formReducer = function (state = { actions: null, changes: {}, fields: [] }, action) {
  let changes, field;

  switch (action.type) {
    case 'SET_FORM':
      return Object.assign({}, state, { actions: action.actions });
    case 'SET_CHANGES':
      changes = Object.assign({}, state.changes);
      field = action.field;
      if (!changes[field]) {
        changes[field] = 0;
      }
      changes[field]++;
      return Object.assign({}, state, { changes: changes });
    /*
        case 'SET_FIELD':
            const fields = state.fields.concat();
            fields.push(action.field);
            return Object.assign({}, state, {fields: fields});
            //return state;
        */
    default:
      return state;
  }
};

export default formReducer;
