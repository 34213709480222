import bstrApi from 'api/bstr';
import moment from 'moment';
moment.locale('ru');

import docService from './docService';
import * as common from './common';
import { config } from 'app/config';
const { consts } = config;
const workDateFormat = config.localeConfig.workDateFormat;
const viewDateFormat = config.localeConfig.viewDateFormat;

/*
enum OperationTypesEnum {
    JOURNEY = 'JOURNEY'
}
enum ReportFormatEnum {
    csv = 'csv',
    xlsx = 'xlsx'
}
interface ReportParams {
    client_id: string;
    operation_type: OperationTypesEnum;
    date_from?: string;
    date_to?: string;
    format?: ReportFormatEnum
}
*/

class ClientService {
  constructor() {
    this.state = {};
  }

  /**
   * get client and related info by client id
   *
   * @id - client id
   */
  getItem = async (id, onlyClient = false, suppressErrors = false) => {
    //console.log('🚀 ~ getItem= ~ suppressErrors', suppressErrors);
    console.log('client::getItem');

    // client
    let out = {};
    await bstrApi.getClient(id).then(
      (result) => {
        out = result;
      },
      suppressErrors
        ? () => {
            return {};
          }
        : this.onError
    );

    if (onlyClient) {
      return out;
    }

    // req documents
    out.docsTypes = await docService.getDocumentsTypes(out.org_type);
    out.extraDocs = await docService.getDocumentsTypes(out.org_type, 'EXTRA', 'contract,profile'); //act,spec

    // contract
    if (out.main_contract_id) {
      await bstrApi.getContract(out.main_contract_id).then((contract) => {
        out.contract = contract;
      }, self.onError);
    } else {
      out.contract = { status: '' };
    }

    // account
    await bstrApi.getAccount(out.active_account_id).then((account) => {
      out.accountObj = account;
      out.accountObj.id = out.active_account_id;
    }, this.onError);

    return out;
  };

  getContracts = async () => {
    return await bstrApi.getContracts();
  };

  getContract = async (id) => {
    return await bstrApi.getContract(id);
  };

  onError(serverErrors) {
    throw serverErrors;
    //const errors = common.getServerErrors(serverErrors);
    //common.notify('contractGet', errors);
  }

  getClientOperations = (params, client_id) => {
    params.client = client_id;
    return this.getOperations(params);
  };

  /**
   * get operations for client
   * @param {*} params
   */
  getOperations = (params) => {
    const out = {
      ...{
        orderby: '-id',
        request: '',

        created: { from: undefined, to: undefined },
        client_type: '',
        operation_type: '',
        client_name: '',

        contract_number: '',
        payment_mean: '',
        license_plate: '',
        vehicle_class: '',

        location: '',
        status: '',
        lane: '',
        sum: '',

        client: '',
        tariff_zone: '',
        name: '',
        car: '',
        journey_type: '',
      },
      ...JSON.parse(JSON.stringify(params)),
    };

    out.created.from = out.created.from ? moment(out.created.from).format(workDateFormat) : undefined;
    out.created.to = out.created.to ? moment(out.created.to).format(workDateFormat) : undefined;
    if (out.lane === '-1') {
      out.lane = '';
    }
    //console.log('1', out)

    return bstrApi
      .operationsList(
        out.offset,
        out.limit,

        out.created.from,
        out.created.to,
        common.convertOrder(out.orderby),
        out.client_type,
        out.operation_type,
        out.journey_type,
        out.request,

        out.status,
        out.client_name,
        out.client,

        out.contract_number,
        out.payment_mean,
        out.license_plate,
        out.tariff_zone,
        out.vehicle_class,
        out.location,
        out.lane,
        out.sum,
        out.name,
        out.car
      )
      .then((data) => data);
  };

  /**
   * get operation for client
   * @param {*} params
   */
  getOperation = (id) => {
    return bstrApi.operationView(id).then((data) => data);
  };

  /**
   * get bic numbers from api
   *
   * @memberof ClientService
   */
  getBic() {
    return (q) => {
      return new Promise((resolve, reject) => {
        bstrApi.getBanks(q, 0, consts.BIClimit).then(
          (data) => {
            if (data) {
              const items = data.map((el) => {
                return {
                  id: el.bic,
                  text: el.bic,
                  region: el.name,
                };
              });
              //console.log('items', items)
              resolve({ items: items });
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    };
  }

  /**
   * Validate and save client
   *
   * @param {*} data - form fields
   * @param {*} docs - docs need for client type
   * @param {*} client - current client
   * @returns errors in promise
   * @memberof ClientService
   */
  async updateClient(data, docs, client) {
    let errors = {};
    const out = { ...data };

    // delete file fields
    docs.forEach((el) => {
      if (out[el.file_tag_id] !== undefined) {
        delete out[el.file_tag_id];
      }
    });

    out.case = client.org_type;
    out.flag_notify_sms = client.flag_notify_sms;
    out.flag_notify_push = client.flag_notify_push;
    out.flag_notify_email = client.flag_notify_email;
    if (out.match) {
      out.address = out.registration_address;
      out.index = out.registration_index;
    }
    delete out.match;
    if (out.matchCorrespondention) {
      out.correspondence_address = out.registration_address;
      out.correspondence_index = out.registration_index;
    }
    delete out.matchCorrespondention;
    this.prepareClient(out);

    await bstrApi.putClient(client.id, out).catch((result) => {
      console.log('🚀 ~ file: clientService.js ~ line 238 ~ ClientService ~ updateClient ~ result', result);
      errors = common.getServerErrors(result);
    });

    //console.log('end errors', errors)
    return errors;
  }

  /**
   * Validate and save client
   *
   * @param {*} data - form fields
   * @param {*} client - current client
   * @returns errors in promise
   * @memberof ClientService
   */
  async updateUserClient(data, client) {
    let errors = {};
    const userFields = ['phone', 'email', 'password'];
    const out = {
      user: {},
      client: {},
    };

    Object.keys(data).forEach((el) => {
      const target = userFields.indexOf(el) !== -1 ? 'user' : 'client';
      out[target][el] = data[el];
    });

    this.prepareClient(out.client);

    await bstrApi.instantUpdateClient(client.id, out).catch((result) => {
      errors = common.getServerErrors(result);
    });

    //console.log('end errors', errors)
    return errors;
  }

  /**
   * prepare client data before save to server
   *
   * @param {*} data
   * @memberof ClientService
   */
  prepareClient(data) {
    const dateFields = ['issue_date', 'birth_date'];
    const clearFields = ['passport', 'issuer_code'];

    dateFields.forEach((fn) => {
      if (data[fn] !== undefined) {
        //console.log('date', fn, data[fn])
        let d1 = moment(data[fn], viewDateFormat, true);
        if (!d1.isValid()) {
          d1 = moment(data[fn], workDateFormat, true);
        }
        data[fn] = d1.format(workDateFormat);
      }
    });

    clearFields.forEach((fn) => {
      if (data[fn] !== undefined) {
        data[fn] = data[fn].replaceAll(' ', '').replaceAll('-', '');
      }
    });
  }

  /**
   * reject client from admin
   *
   * @param {*} id
   * @param {*} note
   * @returns
   * @memberof ClientService
   */
  async rejectClient(id, note) {
    let errors = {};
    await bstrApi.rejectClient(id, { message: note }).catch((result) => {
      errors = common.getServerErrors(result);
    });
    return errors;
  }

  /**
   * confirm client from admin
   *
   * @param {*} id
   * @returns
   * @memberof ClientService
   */
  async confirmClient(id) {
    let errors = {};
    await bstrApi.confirmClient(id).catch((result) => {
      errors = common.getServerErrors(result);
    });
    return errors;
  }

  /**
   * get labels
   * @param {*} params
   */
  getLabels = (params, clientId = undefined) => {
    const out = {
      ...{
        offset: 0,
        enabled_date: { from: undefined, to: undefined },
        pan: undefined,
        client_name: undefined,
        contract_number: undefined,
        license_plate: undefined,
        vehicle: undefined,
        tariff_group: undefined,
        contractId: undefined,
        clientId: undefined,
        orderby: 'pan asc',
        status: undefined,
      },
      ...JSON.parse(JSON.stringify(params)),
    };

    if (clientId) {
      out.clientId = clientId;
    }

    out.enabled_date.from = out.enabled_date.from ? moment(out.enabled_date.from).format(workDateFormat) : undefined;
    out.enabled_date.to = out.enabled_date.to ? moment(out.enabled_date.to).format(workDateFormat) : undefined;

    return bstrApi
      .labelsList(
        out.limit,
        out.offset,
        out.enabled_date.from,
        out.enabled_date.to,
        out.pan,
        out.client_name,
        out.contract_number,
        out.license_plate,
        out.vehicle,
        out.tariff_group,
        common.convertOrder(out.orderby),
        out.contractId,
        out.clientId,
        out.status
      )
      .then((data) => data);
  };

  /**
   * get labels
   * @param {*} params
   */
  getLabelsRequests = (params) => {
    const out = {
      ...{
        offset: 0,
        clientId: '',
        orderby: '-created',
        search: '',
      },
      ...params,
    };

    //console.log('123',out)

    // offset: number, limit: number, clientId: Id, orderby?: string, status?: LabelStatus, search?
    return bstrApi.reservlabelsList(out.limit, out.offset, out.search, common.convertOrder(out.orderby), out.clientId).then((data) => data);
  };

  /**
   * import labels from csv
   * @param {*} fileContent string in base64 format
   */
  importLabels(fileContent) {
    //console.log('123',fileContent)
    return bstrApi.labelscreateobjects({ file: fileContent });
  }

  getLabel(id) {
    return bstrApi.labelinfo(id);
  }

  getLabelHistory(params) {
    const out = {
      ...{
        id: '',
        limit: 10,
        offset: 0,
        orderby: '-created_date',
      },
      ...params,
    };

    return bstrApi.labelHistory(out.id, out.limit, out.offset, out.orderby).then((data) => data);
  }

  updateLabel(id, fields) {
    return bstrApi.labelupdate(id, fields);
  }

  replenishmentBalance(accountId, value) {
    return bstrApi.replenishmentAccount(accountId, { amount: value });
  }

  correctBalance(accountId, value, note) {
    return bstrApi.correctingBalance(accountId, {
      amount: value,
      admin_note: note,
    });
  }

  getClients = (params) => {
    const out = {
      ...{
        limit: 10,
        offset: 0,
        request: '',
        org_type: '',
        status_id: '',
        orderby: 'client asc',
      },
      ...params,
    };

    return bstrApi.getClients(out.offset, out.limit, out.request, out.org_type, out.status_id, common.convertOrder(out.orderby)).then((data) => {
      return data;
    });
  };

  /**
   * find clients
   *
   * @memberof ClientService
   */
  findClients = () => {
    return (q) => {
      return new Promise((resolve) => {
        this.getClients({
          limit: consts.BIClimit,
          request: q,
          orderby: 'client asc',
        }).then((data) => {
          if (data && data.results) {
            //const fields = ['client', 'inn', 'contract_number'];
            const items = data.results.map((el) => {
              // test for fields contains value
              let text = el.client;
              if (!text) {
                text = 'Договор ' + el.contract_number;
              }
              /*
                            fields.forEach(fn => {
                                if (el[fn].indexOf(q) !== -1 ) {
                                    if (text) {
                                        text += ' ';
                                    }
                                    text += el[fn];
                                }
                            });
                            */

              return {
                id: text,
                text: text,
                region: el.inn,
                idReal: el.id,
              };
            });
            //console.log('clients', items)
            resolve({ items: items });
          }
        });
      });
    };
  };

  /**
   * find clients
   *
   * @memberof ClientService
   */
  findLabels = (type, client_id, returnPan = false) => {
    return (q, name) => {
      const qReady = q.replaceAll(' ', '');
      return new Promise((resolve) => {
        switch (name) {
          default:
            bstrApi
              .labelsList(
                consts.BIClimit,
                0,
                undefined,
                undefined,
                qReady,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                'pan',
                undefined,
                client_id,
                type
              )
              .then((data) => {
                if (data && data.results) {
                  const items = data.results.map((el) => {
                    const val = common.formatLabel(el.pan);
                    const idReal = returnPan ? el.pan : el.id;
                    return {
                      id: val,
                      text: val,
                      region: '',
                      idReal: idReal,
                    };
                  });
                  //console.log('items', results)
                  resolve({ items: items });
                }
              });
            break;
        }
      });
    };
  };

  findNewLabels = () => {
    return this.findLabels(consts.tag.NEW);
  };

  findAllLabels = (client_id, returnPan = false) => {
    return this.findLabels(undefined, client_id, returnPan);
  };

  reservLabels = async (data) => {
    return await bstrApi.cretereservlabel(data);
  };

  reservLabelsClient = async (data) => {
    return await bstrApi.createreservlabel(data);
  };

  getLabelsReserv = async (id) => {
    return await bstrApi.inforeservlabels(id);
  };

  deleteLabelsReserv = async (id) => {
    return await bstrApi.deletereservlabels(id);
  };

  updateLabelsReserv = async (id, data) => {
    return await bstrApi.updatereservlabels(id, data);
  };

  /**
   * update contract
   * @param {*} id
   * @param {*} data {status: '', tariff_id: 10}
   */
  updateContract = async (id, data) => {
    return await bstrApi.updateTariff(id, data);
  };

  /**
   * create new contract
   * @param {*} id
   */
  createContract = async (id) => {
    return await bstrApi.createContract(id);
  };

  topup = async (clientId, amount) => {
    let errors = {};

    // get accountId by clientId
    const client = await this.getItem(clientId).catch((result) => {
      errors = result;
    });

    if (!common.isEmptyObject(errors)) {
      return { errors };
    }

    await this.replenishmentBalance(client.active_account_id, amount).catch((result) => {
      errors = result;
    });

    if (!common.isEmptyObject(errors)) {
      return { errors };
    }

    return { errors, client };
  };

  updateOperation(id, fields) {
    return bstrApi.operationUpdate(id, fields);
  }

  rollbackOperation(id, fields) {
    return bstrApi.operationRollback(id, fields);
  }

  correctOperation(id, fields) {
    //admin_note
    //amount
    //license_plate
    //vehicle_class
    return bstrApi.operationCorrected(id, fields);
  }

  // vehicles
  validateLicensePlate = async (value) => {
    return await bstrApi.checkLicensePlate(value);
  };

  // activity
  getClientActivities = (params) => {
    const out = {
      ...{
        limit: 10,
        offset: 0,
        client_id: '',
        orderby: 'id asc',
      },
      ...params,
    };

    return bstrApi.getClientActivity(out.client_id, out.limit, out.offset, out.ordering, common.convertOrder(out.orderby)).then((data) => {
      return data;
    });
  };

  downloadTable = async (params, format = 'xlsx', type = consts.reportType.SIMPLE) => {
    let errors = {};
    //console.log('🚀 ~ downloadTable ~ data', params);
    const out = {
      ...{
        //date_from: '',
        //date_to: '',
        orderby: '',
      },
      ...params,
    };
    delete out.limit;
    delete out.offset;
    if (out.orderby) {
      out.orderby = common.convertOrder(out.orderby);
    } else {
      delete out.orderby;
    }
    if (type === consts.reportType.DEFAULT) {
      delete out.orderby;
    }

    const {
      date_from,
      date_to,
      orderby,
      client_type,
      operation_type,
      request,
      status,
      client_name,
      client,
      contract_number,
      payment_mean,
      license_plate,
      tariff_zone,
      vehicle_class,
      location,
      lane,
      sum,
      name,
      car,
      status_id,
      pan,
      vehicle,
      tariff_group,
      contract_id,
      org_type,
      client_id,
    } = out;

    const data = await bstrApi
      .generateReports(
        { report_type: type, format: format },
        date_from,
        date_to,
        orderby,
        client_type,
        operation_type,
        request,
        status,
        client_name,
        client,
        contract_number,
        payment_mean,
        license_plate,
        tariff_zone,
        vehicle_class,
        location,
        lane,
        sum,
        name,
        car,
        org_type,
        status_id,
        pan,
        vehicle,
        tariff_group,
        contract_id,
        client_id
      )
      .catch((result) => {
        errors = result;
      });

    if (common.isEmptyObject(errors)) {
      common.downloadFile(data.link, true);
      //this.setState({ error: '' });
      return;
    }

    return errors;
  };

  updateCar(id, fields) {
    const out = {
      license_plate: fields.license_plate,
      name: fields.name,
      brand_car: fields.brand_car,
      model_car: fields.model_car,
      tariff_group: fields.tariff_group,
    };

    return bstrApi.updateVehicles(id, out);
  }
}

export default new ClientService();
