// default
export const httpErrors = {
  597: { ru: 'Неверный старый пароль' },
  598: { ru: 'При импорте возникли ошибки' },
  599: { ru: 'Неизвестная ошибка сервера' },
  400: { ru: 'Ошибка данных' },
  401: { ru: 'Неправильный логин или пароль' },
  403: { ru: 'Нет прав для выполнения операции' },
  423: { ru: 'Данный файл уже импортировался' },
  500: { ru: 'Ошибка сервера' },
  600: { ru: 'Недостаточно средств на счету' },
};

export const errors = {
  0: { ru: 'Неизвестная ошибка', en: 'Unknown error' },
  1: {
    ru: 'Для продолжения нужно согласие с условиями',
    en: 'Needness of the acceptance',
  },
  2: { ru: 'Укажите номер телефона', en: 'Empty phone' },
  3: { ru: 'Укажите адрес эл. почты', en: 'Empty email' },
  4: {
    ru: 'Что-то пошло не так, адрес e-mail не подтвержден, попробуйте позже',
    en: 'Email confirmation error',
  },
  5: {
    ru: 'Неверный код подтверждения',
    en: 'Confirmation code is not correct',
  },
  6: {
    ru: 'Срок действия этой ссылки истёк',
    en: 'Confirmation code is dead',
  },

  7: {
    ru: 'Код города/оператора должен начинаться с цифры 3, 4, 5, 6, 8, 9',
    en: 'The city/operator code must start with a digit 3, 4, 5, 6, 8, 9',
  },
  8: {
    ru: 'Телефонный номер должен состоять из 10 цифр',
    en: 'The phone number must consist of 10 digits',
  },
  9: { ru: 'Укажите ваше ФИО', en: 'Empty name' },
  10: {
    ru: 'Недостаточно информации. Введите фамилию, имя и отчество через пробел (Например: Иванов Иван Алексеевич)',
    en: 'Insufficient information. Enter the last name, first name and patronymic separated by a space (for Example: Ivanov Ivan Alekseevich',
  },
  11: {
    ru: 'Допустимо использовать только буквы русского, латинского алфавита и дефис',
    en: 'It is permissible to use only the Cyrillic, the Latin alphabet and the hyphen',
  },

  12: { ru: 'Укажите дату рождения', en: 'Empty birth date' },
  13: {
    ru: 'Вам должно быть от 18 до 100 лет',
    en: 'You must be between 18 and 100 years old',
  },
  14: {
    ru: 'Дата должна быть не ранее {last100years}',
    en: 'The date must not be earlier than {last100years}',
  },
  15: { ru: 'Укажите корректную дату', en: 'Enter the correct date' },

  16: { ru: 'Введите 6 цифр', en: 'Enter 6 digits' },
  17: { ru: 'Введите 10 цифр', en: 'Enter 10 digits' },
  18: { ru: 'Введите 20 цифр', en: 'Enter 20 digits' },
  19: { ru: 'Введите 13 цифр', en: 'Enter 13 digits' },
  20: { ru: 'Введите 9 цифр', en: 'Enter 9 digits' },

  21: {
    ru: 'введите Бик существующего банка',
    en: 'Enter the BIC of an existing Bank',
  },
  22: {
    ru: 'БИК должен начинаться с цифр "04"',
    en: 'The BIC must start with the digits "04"',
  },

  23: { ru: 'Введите 12 цифр', en: 'Enter 12 digits' },
  24: { ru: 'Введите наименование', en: 'Empty name' },
  25: { ru: '', en: '' },
  26: {
    ru: 'Выбранная дата не может быть раньше сегодняшней ',
    en: 'Selected date cannot be earlier than today',
  },
  27: {
    ru: 'Дата активации при изменении активного тарифа не может быть текущей ',
    en: 'Activating date cannot be earlier than tomorrow',
  },
  28: { ru: 'Неверный формат ГРЗ', en: 'Bad license plate format' },
  29: { ru: 'ГРЗ привязан', en: 'Bad already connected' },

  101: { ru: 'Ошибка сервера', en: 'Internal error' },
  202: { ru: 'Введен неверный логин или пароль', en: 'Bad user' },
  205: { ru: 'Клиент не найден', en: 'Client not found' },
  230: { ru: 'Введите корректный адрес эл. почты', en: 'Invalid email' },
  231: { ru: 'Неверный телефон', en: 'Invalid phone' },
  232: { ru: 'Поле заполнено некорректно', en: 'Invalid value' },
  233: { ru: 'Неверный тип', en: 'Invalid type' },
  234: { ru: 'E-mail уже существует', en: 'Email already exixts' },
  235: { ru: 'Телефон уже существует', en: 'Phone already exixts' },
  238: {
    ru: 'Пароль должен содержать не менее 6 символов',
    en: 'Invalid text for password',
  },
  247: { ru: 'Неверный PAN или PIN', en: 'Invalid PAN or PIN' },
  260: { ru: 'Пароли не совпадают', en: "Passwords aren't match" },
  2000: {
    ru: 'Данный ИНН уже в системе',
    en: 'Object with this INN is already exist',
  },
  2001: { ru: 'Объект не найден', en: 'Object is not found' },
  2002: { ru: 'Телефон не подтвержден', en: 'Session is not found' },
  2003: { ru: 'Такого аккаунта нет', en: 'User is not found' },
  2004: { ru: 'Роль не найдена', en: 'Role is not found' },
  2005: { ru: 'Клиент не найден', en: 'Client is not found' },
  2006: { ru: 'Сервис не найден', en: 'Service is not found' },
  2007: { ru: 'Объект не уникальный', en: 'Object is not uniq' },
  2008: {
    ru: 'Введён неверный код',
    en: 'Confirmation code is not exist',
  },
  2009: { ru: 'Введён неверный код', en: 'Confirmation code is dead' },

  300: {
    ru: 'Размер поля не должен превышать 256 символов',
    en: 'Maximum 256 symbols',
  },
};

export const consts = {
  BIClimit: 6,
  reportType: {
    SIMPLE: 'SIMPLE',
    JOURNEY: 'JOURNEY',
    DEFAULT: 'DEFAULT',
    SINGLE_CLIENT: 'SINGLE_CLIENT',
    SIMPLE_CLIENTS: 'SIMPLE_CLIENTS',
    SIMPLE_LABELS: 'SIMPLE_LABELS',
  },
  orgTypes: {
    INDIVIDUAL: 'client_individual',
    SP: 'client_sp',
    COMPANY: 'client_company',
  },
  role: {
    ROOT: 'merchant',
  },
  client: {
    NEW: 'NEW',
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED',
  },
  tag: {
    NEW: 'NEW',
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED',
    BLOCKED: 'BLOCKED',
    DELETED: 'DELETED',
    RESERVATION: 'RESERVATION',
  },
  tagHistoryType: {
    IMPORT: 'IMPORT',
    RESERVE: 'RESERVE',
    RESET_RESERVE: 'RESET_RESERVE',
    ISSUE: 'ISSUE',
    CHANGE_STATUS: 'CHANGE_STATUS',
    CHANGE_COLOR: 'CHANGE_COLOR',
  },
  tagColor: {
    GREY: 'grey',
    WHITE: 'white',
    BLACK: 'black',
  },
  tagReserv: {
    WAITING_SPECIFICATION: 'WAITING_SPECIFICATION',
    WAITING_ACT: 'WAITING_ACT',
    ACTIVE: 'ACTIVE',
  },
  operation: {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETE: 'COMPLETE',
    SUSPENDED: 'SUSPENDED',
    ERROR: 'ERROR',
    CANCELLED: 'CANCELLED',
  },
  opTypes: {
    JOURNEY: 'JOURNEY',
    //PASSAGE: 'PASSAGE',
    BANK_TOPUP: 'BANK_TOPUP',
    TOPUP: 'TOPUP',
    RETURN: 'RETURN',
    CORRECTION: 'CORRECTION',
    BALANCE_CORRECTION: 'BALANCE_CORRECTION',
    ISSUE_A_LABEL: 'ISSUE_A_LABEL',
  },
  journeyTypes: {
    JOURNEY: 'JOURNEY',
    CASH_JOURNEY: 'CASH_JOURNEY',
    BANKCARD_JOURNEY: 'BANKCARD_JOURNEY',
    DEBT_JOURNEY: 'DEBT_JOURNEY',
    FREE_JOURNEY: 'FREE_JOURNEY',
    NOTPAID_JOURNEY: 'NOTPAID_JOURNEY',
    RECEIPT_JOURNEY: 'RECEIPT_JOURNEY',
  },
  contract: {
    NEW: 'NEW',
    WAITING_REVIEW: 'WAITING_REVIEW',
    WAITING_USER: 'WAITING_USER',
    ACTIVE: 'ACTIVE',
    CONTROL: 'CONTROL',
    SUSPENDED: 'SUSPENDED',
    BLOCKED: 'BLOCKED',
    TERMINATING: 'TERMINATING',
    TERMINATED: 'TERMINATED',
  },
  docTypes: {
    REQUIRED: 'REQUIRED',
    OPTIONAL: 'OPTIONAL',
    PROFILE: 'PROFILE',
    EXTRA: 'EXTRA',
  },
  docTemplates: {
    CONTRACT: 'TEMPLATE.CONTRACT',
    INVOICE: 'TEMPLATE.INVOICE',
  },
  payment: {
    //NEW: 'NEW',
    PROCESSED: 'PROCESSED',
    NO_PROCESSED: 'NO_PROCESSED',
    ENROLLED: 'ENROLLED',
    NOENROLLED: 'NOENROLLED',
    MANUAL: 'MANUAL',
    ARCHIVE: 'ARCHIVE',
  },
  tariffStatuses: {
    //NEW: 'NEW',
    ACTIVE: 'ACTIVE',
    ARCHIVAL: 'ARCHIVAL',
  },
  tariffTypes: {
    FREE: 'FREE',
    REGULAR: 'REGULAR',
    SUBSCRIPTION: 'SUBSCRIPTION',
    TARIFF_NK: 'TARIFF_NK',
    DEFAULT_RFID: 'DEFAULT_RFID',
  },
  tariffDiscounts: {
    //FREE: 'FREE',
    UNLIMITED: 'UNLIMITED',
    DAY: 'DAY',
    MONTH: 'MONTH',
    YEAR: 'YEAR',
  },
};

export const adminMenu = [
  {
    title: 'Пользователи',
    link: '/clients',
    icon: 'supervisor_account',
    otherItems: ['/clientadd', '/client/'],
  }, //'directions_bus'
  { title: 'Операции', link: '/operations', icon: 'art_track' },
  {
    title: 'Метки',
    link: '/tags',
    icon: 'label',
    otherItems: ['/tag/', '/taghistory/'],
  },
  { title: 'Отчёты', link: '/reports', icon: 'art_track' },
  {
    title: 'Управление',
    link: '/tariffs',
    icon: 'art_track',
    otherItems: ['/tariff/', '/tariffadd', '/limits', '/contractmasks', '/setup', '/shifts', '/shift/', '/roles', '/setupreports'],
  },
  { title: 'Банк', link: '/bank', icon: 'art_track' },
  { title: 'Пополнение', link: '/topup', icon: 'art_track' },

  /*
      {title: 'Сети ТСП', link: '/tspnets', icon: 'domain'},
      {title: 'Пользователи', link: '/users', icon: 'supervisor_account'},
      {title: 'Финансы', link: '/finans', icon: 'label'},
      {title: 'Купчино', link: '/travels', icon: 'commute'}
      */
  //{title: '(!) Генератор', link: '/generator', icon: ''}
];

export const clientMenu = [
  { title: 'Главная', link: '/main', icon: '' },
  { title: 'Тарифы', link: '/lk/tariffs', icon: '' },
  //{ title: "Пополнить", link: "/lk/topup", icon: "" },
  { title: 'Обратная связь', link: '/support', icon: '' },
];

let statuses = consts.contract;
consts.contractLabels = {
  [statuses.NEW]: 'Новый',
  [statuses.WAITING_REVIEW]: 'Ожидает активации',
  [statuses.WAITING_USER]: 'Ожидает пользователя',
  [statuses.ACTIVE]: 'Активен',
  [statuses.CONTROL]: 'Под контролем',
  [statuses.BLOCKED]: 'Заблокирован',
  [statuses.SUSPENDED]: 'Приостановлен',
  [statuses.TERMINATING]: 'В процессе расторжения',
  [statuses.TERMINATED]: 'Расторгнут',
};

statuses = consts.client;
consts.clientLabels = {
  [statuses.NEW]: 'Ожидает активации',
  [statuses.ACTIVE]: 'Активен',
  [statuses.SUSPENDED]: 'Заблокирован',
};

statuses = consts.tag;
consts.tagLabels = {
  [statuses.NEW]: 'Новая',
  [statuses.ACTIVE]: 'Активна',
  [statuses.SUSPENDED]: 'Приостановлена',
  [statuses.DELETED]: 'Списана',
  [statuses.BLOCKED]: 'Заблокирована',
  [statuses.RESERVATION]: 'Зарезервирована',
};
consts.tagActionsLabels = {
  [statuses.NEW]: 'Сделать новой',
  [statuses.ACTIVE]: 'Восстановить',
  [statuses.SUSPENDED]: 'Приостановить',
  [statuses.DELETED]: 'Списать',
  [statuses.BLOCKED]: 'Заблокировать',
};
statuses = consts.tagHistoryType;
consts.tagHistoryTypeLabels = {
  [statuses.IMPORT]: 'Импорт метки',
  [statuses.RESERVE]: 'Зарезервирована для пользователя',
  [statuses.RESET_RESERVE]: 'Отмена резерва для пользователя',
  [statuses.ISSUE]: 'Выдана пользователю',
  [statuses.CHANGE_STATUS]: 'Смена статуса на',
  [statuses.CHANGE_STATUS + 'v2']: 'поменял статус на',
  [statuses.CHANGE_COLOR]: 'Перемещена в',
  [statuses.CHANGE_COLOR + 'suffix']: 'список',
  [statuses.CHANGE_COLOR + 'v2']: 'переместил в',
};
statuses = consts.tagColor;
consts.tagColorLabels = {
  [statuses.GREY]: 'Серый',
  [statuses.WHITE]: 'Белый',
  [statuses.BLACK]: 'Черный',
};

consts.tagActionsLabelsCustom = {
  [statuses.UNBLOCK]: 'Разблокировать',
};

statuses = consts.tagReserv;
consts.tagReservLabels = {
  [statuses.WAITING_SPECIFICATION]: 'Ожидает загрузки спецификации',
  [statuses.WAITING_ACT]: 'Ожидает загрузки акта',
  [statuses.ACTIVE]: 'Активен',
};
consts.tagReservCodes = {
  [statuses.WAITING_SPECIFICATION]: 'TEMPLATE.SPEC',
  [statuses.WAITING_ACT]: 'TEMPLATE.ACT',
};

statuses = consts.operation;
consts.operationLabels = {
  [statuses.SUSPENDED]: 'Приостановлена',
  [statuses.IN_PROGRESS]: 'В процессе',
  [statuses.COMPLETE]: 'Завершена',
  [statuses.SUSPENDED]: 'Приостановлена',
  [statuses.ERROR]: 'Ошибка',
  [statuses.CANCELLED]: 'Отменена',
};

statuses = consts.orgTypes;
consts.orgTypesLabels = {
  [statuses.INDIVIDUAL]: 'ФЛ',
  [statuses.SP]: 'ИП',
  [statuses.COMPANY]: 'ЮЛ',
};

statuses = consts.opTypes;
consts.opTypesLabels = {
  [statuses.JOURNEY]: 'Проезд',
  [statuses.BANK_TOPUP]: 'Пополнение Банк',
  [statuses.TOPUP]: 'Пополнение',
  [statuses.RETURN]: 'Возврат',
  [statuses.CORRECTION]: 'Корректировка',
  [statuses.BALANCE_CORRECTION]: 'Корректировка баланса',
  [statuses.ISSUE_A_LABEL]: 'Выдача метки',
};

statuses = consts.payment;
consts.paymentLabels = {
  //[statuses.NEW]: 'Новый',
  [statuses.PROCESSED]: 'Обработан',
  [statuses.NO_PROCESSED]: 'Не обработан',
  [statuses.ENROLLED]: 'Зачислен',
  [statuses.NOENROLLED]: 'Не зачислен',
  [statuses.MANUAL]: 'Зачислен вручную',
  [statuses.ARCHIVE]: 'Архивирован',
};

statuses = consts.tariffStatuses;
consts.tariffStatusesLabels = {
  //[statuses.NEW]: 'Новый',
  [statuses.ACTIVE]: 'Активен',
  [statuses.ARCHIVAL]: 'Архивирован',
};
statuses = consts.tariffTypes;
consts.tariffTypesLabels = {
  [statuses.REGULAR]: 'Регулярный',
  [statuses.SUBSCRIPTION]: 'Абонемент',
  [statuses.TARIFF_NK]: 'Тариф НК',
  [statuses.DEFAULT_RFID]: 'Базовый RFID',
};

statuses = consts.tariffDiscounts;
consts.tariffDiscountsLabels = {
  //[statuses.FREE]: 'Бесплатный проезд',
  [statuses.UNLIMITED]: 'Неограничен',
  [statuses.DAY]: 'День',
  [statuses.MONTH]: 'Месяц',
  [statuses.YEAR]: 'Год',
};
/*
[statuses.UNLIMITED]: 'Бесплатная каждая Х поездка',
[statuses.DAY]: 'Скидка за поездки в произвольный период дней',
[statuses.MONTH]: 'Бесплатная каждая Х поездка за календарный месяц',
[statuses.YEAR]: 'Бесплатная каждая Х поездка за календарный год',
*/

statuses = consts.journeyTypes;
consts.journeyTypeLabels = {
  [statuses.JOURNEY]: 'Поездка',
  [statuses.CASH_JOURNEY]: 'За наличные',
  [statuses.BANKCARD_JOURNEY]: 'По карте',
  [statuses.DEBT_JOURNEY]: 'В долг',
  [statuses.FREE_JOURNEY]: 'Бесплатная',
  [statuses.NOTPAID_JOURNEY]: 'Неоплаченная',
  [statuses.RECEIPT_JOURNEY]: 'По чеку',
};
