import styled from 'styled-components';

export const Content = styled.div``;

export const Title = styled.div`
  font-weight: bold;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 0 0;
  button {
    margin: 0 0 5px 0;
  }
`;
