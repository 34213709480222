import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field } from 'react-final-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';

import { FormGroup } from './styled';
import { CheckIcon } from 'libs/icons/icons';
import { Confirm } from 'libs/ui';

moment.locale('ru');

export const getLabel = (label, error = false, cl = '') => {
  if (!label) {
    return null;
  }
  return (
    <FormHelperText className={cl} error={error}>
      {label}
    </FormHelperText>
  );
};

export const getError = (meta, className = '', customerror) => {
  const showError =
    (((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (meta.touched || meta.dirtySinceLastSubmit || meta.dirty)) ||
    customerror !== '';
  const label = showError ? getLabel(customerror || meta.error || meta.submitError, showError, className) : null;
  return { showError: showError, label: label };
};

export const prepareAttr = (items, excl) => {
  const attr = { ...items };
  if (!excl) {
    excl = ['reactChange', 'reactFocus', 'customerror', 'hideCheck'];
  }
  if (excl.indexOf('hideCheck') === -1) {
    //excl.push('hideCheck');
  }
  excl.forEach((el) => {
    if (attr[el] !== undefined) {
      delete attr[el];
    }
  });

  delete attr.inputAdapter;

  return attr;
};

export const prepareProps = (props, meta, val, endAdornment, hideCheck /*, onFocus*/) => {
  // phone fix
  if (val === '+7 (') {
    val = '';
  }

  delete props.inputAdapter;

  // adorment
  if (!endAdornment && !hideCheck && !meta.validating && meta.valid && meta.touched && val) {
    // console.log('show check')
    props.endAdornment = (
      <InputAdornment position="end">
        <CheckIcon />
      </InputAdornment>
    );
  } else {
    if (endAdornment) {
      props.endAdornment = endAdornment;
    }
  }
  return props;
};

class InputClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
      confirmAction: null,
      confirmText: '',
      suggestValue: props.suggestValue ? props.suggestValue : '',
      focused: false,
      opened: false,
      focusedInput: null,
    };

    // console.log('constr inputclass')

    this.commonChange = this.commonChange.bind(this);
    this.setSuggestValue = this.setSuggestValue.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
  }

  /**
   * executes after each input change
   * @param {*} value
   * @param {*} defaultOnChange
   */
  commonChange(value, defaultOnChange) {
    if (this.props.confirm) {
      this.setState({
        showConfirm: true,
        confirmText: this.props.confirmText ? this.props.confirmText : 'Подтвердите действие',
        confirmAction: () => {
          if (this.props.reactChange) {
            this.props.reactChange(value, this.props.name);
          }
          defaultOnChange(value);
          this.closeConfirm();
        },
      });
      return;
    }

    if (this.props.reactChange) {
      this.props.reactChange(value, this.props.name);
    }
    defaultOnChange(value);
  }

  identity(value) {
    if (value === undefined) {
      value = '';
    }
    return value;
  }

  setSuggestValue(value, clear = false) {
    if (clear) {
      value = '';
    }
    this.setState({ suggestValue: value });
  }

  closeConfirm() {
    this.setState({ showConfirm: false });
  }

  getInput() {
    const { name, title, /*values,*/ ...rest } = this.props;
    const attr = prepareAttr(rest, ['confirm', 'confirmText', 'inline', 'noMargin']);
    // console.log('getInput')

    return (
      <Field
        {...attr}
        name={name}
        component={(...params) => {
          //console.log('hh', params.hideCheck)
          //rest.obj = this;
          params.push(this);
          //console.log('rest', rest);
          return this.props.inputAdapter(...params);
        }}
        label={title}
        parse={this.identity}
        format={this.identity}
      />
    );
  }

  render() {
    const { showConfirm, confirmAction, confirmText } = this.state;
    const { cl, inline, description, disabled, fullWidth, noMargin } = this.props;
    // console.log('render')
    const input = this.getInput();
    const className = (cl || '') + (disabled ? ' field-disabled' : '') + (inline ? ' field-inline' : '');
    return (
      <FormGroup noMargin={noMargin} className={className} fullWidth={fullWidth}>
        {!inline && showConfirm && (
          <Confirm error="" opened={showConfirm} action={confirmAction} text={confirmText} onClose={this.closeConfirm} />
        )}
        {input}
        {!inline && <div className="description">{description}</div>}
      </FormGroup>
    );
  }
}

InputClass.propTypes = {
  reactChange: PropTypes.func,
  reactFocus: PropTypes.func,
  noMargin: PropTypes.bool,
  confirm: PropTypes.bool,
  confirmText: PropTypes.string,
  disabled: PropTypes.bool,
  cl: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.array,
  fullWidth: PropTypes.bool,
  inline: PropTypes.bool,
  customerror: PropTypes.string,
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  hideCheck: PropTypes.bool,
  endAdornment: PropTypes.node,
  suggestValue: PropTypes.string,
  inputAdapter: PropTypes.func.isRequired,
};

InputClass.defaultProps = {
  variant: '',
  hideCheck: false,
  noMargin: false,
  disabled: false,
  confirm: false,
  reactChange: null,
  fullWidth: true,
  inline: false,
  customerror: '',
};

export default InputClass;
