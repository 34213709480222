import React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';

import { Notification } from 'libs/ui';
import { FormLoader } from './final-form.styled';

export class FinalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(data) {
    let realData = {};

    // if exists full initial values
    Object.keys(this.props.initialValues).forEach((fn) => {
      const val = this.props.initialValues[fn];
      if (data[fn] === undefined) {
        realData[fn] = val;
      } else {
        realData[fn] = data[fn];
      }
    });

    // if not
    Object.keys(data).forEach((fn) => {
      const val = data[fn];
      if (realData[fn] === undefined) {
        realData[fn] = val;
      }
    });

    return this.props.onSubmit(realData);
  }

  validate(data) {
    //console.log('ff validate', data);
    if (this.props.onChange) {
      this.props.onChange(data);
    }
    if (this.props.validate) {
      return this.props.validate(data);
    }
    return {};
  }

  render() {
    const { loading, initialValues, debug, setForm, customId, getActions, footer, ...rest } = this.props;
    //console.log('ff initial', initialValues);
    rest.mutators = {
      ...arrayMutators,
    };

    return (
      <FormLoader $loading={loading ? true : undefined}>
        <Form
          {...rest}
          /*subscription={{dirty: true, dirtySinceLastSubmit: true, touched: true, pristine: true, modified: true, modifiedSinceLastSubmit: true}}*/ initialValues={
            initialValues
          }
          //initialValuesEqual={() => true}
          keepDirtyOnReinitialize={true}
          onSubmit={this.onSubmit}
          validate={this.validate}
          render={({ handleSubmit, submitError, values, form, ...restForm }) => {
            //console.log(restForm)

            if (setForm) {
              setForm(form);
            }

            if (getActions) {
              getActions({ submit: handleSubmit, mutators: form.mutators });
            }

            const cl = 'form data-form' + (submitError ? ' error' : '');
            //console.log('ff inner', restForm);

            return (
              <form autoComplete={rest.autoComplete} id={customId} className={cl} onSubmit={handleSubmit}>
                {this.props.children}
                {footer && footer(restForm)}
                {submitError && !restForm.dirtySinceLastSubmit && <Notification small message={submitError} />}
                {/*<Button type="submit" disabled={submitting}>Сохранить</Button>*/}
                {debug && <pre>{JSON.stringify(values, 0, 2)}</pre>}
              </form>
            );
          }}
        />
      </FormLoader>
    );
  }
}

FinalForm.propTypes = {
  children: PropTypes.node,
  debug: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  customId: PropTypes.string,
  initialValues: PropTypes.object,
  validate: PropTypes.func,
  setForm: PropTypes.func,
  getActions: PropTypes.func,
  onChange: PropTypes.func,
  footer: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

FinalForm.defaultProps = {
  loading: false,
  customId: '',
  initialValues: {},
  debug: false,
};

export default FinalForm;
