import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import InputClass, { getError, prepareProps, prepareAttr } from '../input-class';

/**
 * input adapter
 */
const inputAdapter = ({ input: { name, value, onFocus, onChange, ...restInput }, meta, endAdornment, ...rest }, __other, obj) => {
  const error = getError(meta, '', rest.customerror);
  const attr = prepareAttr(rest);

  prepareProps(restInput, meta, value, endAdornment, rest.hideCheck, onFocus);
  //restInput.onChange = onChange;

  return (
    <>
      <TextField
        {...attr}
        name={name}
        error={error.showError}
        InputProps={restInput}
        onFocus={(event) => {
          if (rest.reactFocus) {
            rest.reactFocus(event);
          }
        }}
        onChange={(event) => obj.commonChange(event.currentTarget.value, onChange)}
        value={value}
      />
      {error.label}
    </>
  );
};

export const TextInput = (props) => {
  return <InputClass inputAdapter={inputAdapter} {...props} />;
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideCheck: PropTypes.bool,
  className: PropTypes.string,
  reactChange: PropTypes.func,
  reactFocus: PropTypes.func,
  tabIndex: PropTypes.number,
  autoComplete: PropTypes.any,
};

TextInput.defaultProps = {
  variant: 'filled',
  type: 'text',
  disabled: false,
  fullWidth: true,
  hideCheck: false,
};

export default TextInput;
