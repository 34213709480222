import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import MaskedInputMUI from './masked-input';
import InputClass, { getError, prepareProps, prepareAttr } from '../input-class';

export class AutocompleteInput extends React.Component {
  state = {
    value: '',
    inputValue: '',
    options: [],
  };

  getOptionLabel = (option) => {
    return typeof option === 'object' ? option.text : option;
  };

  getOptionSelected = (option, value) => {
    //console.log('sel', option, value)
    return typeof option === 'object' ? option.id === value : option === value;
  };

  onChange = (__event, newValue, __reason) => {
    const val = newValue && newValue['id'] ? newValue.id : newValue;
    this.setState({ value: val });
    if (this.props.onChange) {
      this.props.onChange(val, newValue);
    }
  };

  onInputChange = (__event, newInputValue) => {
    //console.log('input:' + newInputValue)
    this.setState({ inputValue: newInputValue });
  };

  renderInput = (params) => {
    const { mask, name, error, label } = this.props;
    const { inputProps, ...attr } = params;
    const { value, onChange, ...other } = inputProps;

    if (mask) {
      return (
        <MaskedInputMUI
          {...attr}
          name={name}
          error={error}
          inputProps={other}
          onChange={onChange}
          value={value}
          label={label}
          mask={mask}
          variant="filled"
        />
      );
    }
    return <TextField {...params} label={label} variant="filled" />;
  };

  renderOption = (option) => {
    const { inputValue } = this.state;
    const matches = match(option.text, inputValue);
    const parts = parse(option.text, matches);
    return (
      <Grid container alignItems="center">
        <Grid item xs>
          {parts.map((part, index) => (
            <span key={index} /*style={{ fontWeight: part.highlight ? 700 : 400 }}*/>{part.text}</span>
          ))}

          <Typography color="textSecondary">{option.region}</Typography>
        </Grid>
      </Grid>
    );
  };

  /**
   * input adapter
   */
  inputAdapter({ input, meta, fetcher, items, endAdornment, defaultItems: __no, emptyMsg: __no1, ...rest }) {
    console.log('inputAdapter Autocomplete');
    const { name, value, onFocus, onChange: __no2, ...restInput } = input;
    const error = getError(meta, '', rest.customerror);
    const attr = prepareAttr(rest);
    prepareProps(restInput, meta, value, endAdornment, rest.hideCheck, onFocus);
    const itemsReal = items ? items : [];

    return (
      <>
        <Autocomplete
          {...attr}
          //debug={true}
          autoComplete
          required={true}
          disablePortal={true}
          loadingText="Загрузка..."
          noOptionsText="Ничего не найдено"
          popupIcon={null}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          getOptionLabel={this.getOptionLabel}
          getOptionSelected={this.getOptionSelected}
          renderInput={this.renderInput}
          renderOption={this.renderOption}
          id={name}
          name={name}
          input={name}
          label={rest.label}
          value={value}
          options={itemsReal}
          //disabled={disabled}
          error={error.showError}
        />

        <Autocomplete fetcher={fetcher} />
        {error.label}
      </>
    );
  }

  render() {
    return <InputClass inputAdapter={this.inputAdapter} {...this.props} />;
  }
}

AutocompleteInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fetcher: PropTypes.func.isRequired,
  minLength: PropTypes.number.isRequired,

  options: PropTypes.array,
  value: PropTypes.any,
  mask: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  fetchTimeout: PropTypes.number,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideCheck: PropTypes.bool,
};

AutocompleteInput.defaultProps = {
  minLength: 2,
  fetchTimeout: 350,
  disabled: false,
  variant: 'filled',
  type: 'text',
  fullWidth: true,
  hideCheck: false,
};

export default AutocompleteInput;
