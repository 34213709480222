import PropTypes from 'prop-types';

import { Button } from 'libs/ui';
import { Content, Items, Title } from './list.styled';

export const List = (props) => {
  const { onClick, items, title } = props;

  const getItems = () => {
    return items.map((el, idx) => (
      <Button size={'small'} variant={'outlined'} key={idx} onClick={() => onClick(el)}>
        {el.name}
      </Button>
    ));
  };

  const itemsEl = getItems();

  return (
    <Content>
      <Title>{title}</Title>
      <Items>{itemsEl}</Items>
    </Content>
  );
};

List.propTypes = {
  onClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default List;
