import { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Loader } from 'libs/ui';
import LayoutPublic from 'libs/layout/LayoutPublic';
import LayoutBackend from 'libs/layout/LayoutBackend';
import {
  Bank,
  ChangePassword,
  Client,
  ClientAdd,
  Clients,
  ClientsDetail,
  ConfirmEmail,
  ContractMasks,
  ErrorPage,
  Limits,
  Login,
  MainPage,
  NotFound,
  Operations,
  PersonalOperation,
  PersonalTag,
  PersonalTagsRequest,
  PersonalTariffs,
  Profile,
  Registration,
  Reports,
  RestorePass,
  Setup,
  ShiftDetail,
  Shifts,
  Support,
  Tags,
  TagsDetail,
  TagsDetailHistory,
  TagsOrder,
  TariffDetail,
  TariffEdit,
  Tariffs,
  TariffsAdd,
  Testing,
  Topup,
  VersionError,
  Zones,
  SetupReports,
  Roles,
} from './router-pages';

/**
 * login procedure
 * ---
 * 1. if set redux state readyForBackend (userReducer) redirect to admin or client path from config
 * 2. readyForBackend = true if user root or user is client and docs filled and user contract status = ACTIVE
 */

const AppRouter = (props) => {
  const { readyForBackend, root } = props;

  //console.log('render routes');
  return (
    <Suspense fallback={<Loader fixed />}>
      <BrowserRouter>
        <Routes>
          {/*public routes*/}
          <Route path="/versionerror" element={<VersionError />} exact />
          <Route path="/testing" element={<Testing />} exact />
          <Route path="/error" render={(props) => <ErrorPage {...props} />} exact />

          {/* public */}
          <Route
            element={
              <LayoutPublic toLoginedPath={'/'} readyForBackend={readyForBackend} root={root}>
                <Outlet />
              </LayoutPublic>
            }
          >
            <Route exact path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/restorepass" element={<RestorePass />} />
            <Route path="/confirmEmail" element={<ConfirmEmail />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/prefill" element={<Client />} />
          </Route>

          {/*private routes*/}
          <Route
            element={
              <LayoutBackend toLoginPath={'/login'} readyForBackend={readyForBackend} root={root}>
                <Outlet />
              </LayoutBackend>
            }
          >
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/main" element={<MainPage />} />
            <Route path="/order" element={<TagsOrder />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/support" element={<Support />} />
            <Route path="/lk/tag/:id" element={<PersonalTag />} />
            <Route path="/lk/tagsrequest/:id" element={<PersonalTagsRequest />} />
            <Route path="/lk/operation/:id" element={<PersonalOperation />} />
            <Route path="/lk/tariffs" element={<PersonalTariffs />} />

            {/* admin */}
            <Route path="/clients" element={<Clients />} />
            <Route path="/client/:id" element={<ClientsDetail />} />
            <Route path="/clientadd" /*theme={'frontend'}*/ element={<ClientAdd />} />
            <Route path="/operations/:id" element={<Operations />} />
            <Route path="/operations" element={<Operations />} />
            <Route path="/tags" element={<Tags />} />
            <Route path="/tag/:id" element={<TagsDetail />} />
            <Route path="/taghistory/:id" element={<TagsDetailHistory />} />
            <Route path="/tariffs" element={<Tariffs />} />
            <Route path="/tariffadd" element={<TariffsAdd />} />
            <Route path="/tariff/edit/:id/:version" element={<TariffEdit />} />
            <Route path="/tariff/:id" element={<TariffDetail />} />
            <Route path="/limits" element={<Limits />} />
            <Route path="/contractmasks" element={<ContractMasks />} />
            <Route path="/zones" element={<Zones />} />
            <Route path="/setup" element={<Setup />} />
            <Route path="/shifts" element={<Shifts />} />
            <Route path="/shift/:id" element={<ShiftDetail />} />
            <Route path="/bank" element={<Bank />} />
            <Route path="/topup" element={<Topup />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/setupreports" element={<SetupReports />} />

            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

AppRouter.propTypes = {
  readyForBackend: PropTypes.bool.isRequired,
  root: PropTypes.bool.isRequired,
};

AppRouter.defaultProps = {
  readyForBackend: false,
  root: false,
};

export default AppRouter;
