import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { TabPanel } from './tab-panel';
import { StyledTab, StyledTabs } from './tabs.styled';

export const Tabs = (props) => {
  const [tab, setTab] = useState(props.tab);

  useEffect(() => {
    if (tab !== props.tab) {
      setTab(props.tab);
    }
  }, [props.tab]);

  if (props.getActions) {
    props.getActions({ setTab: setTab });
  }

  const changeTab = (__ignored, value) => {
    setTab(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const tablinks = props.items.map((el, idx) => {
    const key = 'tab-' + idx;
    const aria = 'tabpanel-' + idx;
    const other = {};
    if (props.useLink) {
      other.component = RouterLink;
      other.to = '?tab=' + idx;
    }
    if (props.disabled) {
      other.disabled = true;
    }

    return <StyledTab {...other} key={idx} label={el.title} id={key} aria-controls={aria} />;
  });

  const tabs = props.items.map((el, idx) => {
    return (
      <TabPanel key={idx} value={tab} index={idx}>
        {el.content}
      </TabPanel>
    );
  });

  return (
    <>
      <StyledTabs value={tab} onChange={changeTab} aria-label={props.title}>
        {tablinks}
      </StyledTabs>
      {tabs}
    </>
  );
};

Tabs.propTypes = {
  items: PropTypes.array.isRequired,
  tab: PropTypes.number,
  title: PropTypes.string,
  onChange: PropTypes.func,
  getActions: PropTypes.func,
  disabled: PropTypes.bool,
  useLink: PropTypes.bool,
};

Tabs.defaultProps = {
  tab: 0,
  title: 'Tabs',
  disabled: false,
  useLink: false,
};

export default Tabs;
