import { lazy } from 'react';

// system
export const NotFound = lazy(() => import('app/pages-system/errors/404'));
export const ErrorPage = lazy(() => import('app/pages-system/errors/500'));
export const VersionError = lazy(() => import('app/pages-system/errors/VersionError'));
export const Testing = lazy(() => import('app/pages-system/testing/Testing'));

// public
export const Registration = lazy(() => import('app/pages/registration/Registration'));
export const Login = lazy(() => import('app/pages/login/Login'));
export const RestorePass = lazy(() => import('app/pages/restorePass/RestorePass'));
export const ConfirmEmail = lazy(() => import('app/pages/confirmEmail/ConfirmEmail'));
export const Client = lazy(() => import('app/pages/client/Client'));
export const ClientAdd = lazy(() => import('app/pages/client/ClientAdd'));

// personal
export const MainPage = lazy(() => import('app/pages-personal/personal/MainPage'));
export const TagsOrder = lazy(() => import('app/pages-personal/personal/TagsOrder'));
export const Profile = lazy(() => import('app/pages-personal/profile/Profile'));
export const ChangePassword = lazy(() => import('app/pages-personal/profile/ChangePassword'));
export const Support = lazy(() => import('app/pages-personal/profile/Support'));
export const PersonalTag = lazy(() => import('app/pages-personal/personal/PersonalTag'));
export const PersonalTagsRequest = lazy(() => import('app/pages-personal/personal/PersonalTagsRequest'));
export const PersonalOperation = lazy(() => import('app/pages-personal/personal/PersonalOperation'));
export const PersonalTariffs = lazy(() => import('app/pages-personal/personal/PersonalTariffs'));

// admin
export const Clients = lazy(() => import('app/pages-admin/clients/Clients'));
export const ClientsDetail = lazy(() => import('app/pages-admin/clientDetail/ClientsDetail'));
export const Operations = lazy(() => import('app/pages-admin/operations/Operations'));
//export const  Operation = lazy(() => import('app/pages-admin/operations/Operation'));
export const Tags = lazy(() => import('app/pages-admin/tags/Tags'));
export const TagsDetail = lazy(() => import('app/pages-admin/tags/TagsDetail'));
export const TagsDetailHistory = lazy(() => import('app/pages-admin/tags/TagsDetailHistory'));

export const Tariffs = lazy(() => import('app/pages-admin/tariffs/Tariffs'));
export const TariffsAdd = lazy(() => import('app/pages-admin/tariffs/TariffsAdd'));
export const TariffDetail = lazy(() => import('app/pages-admin/tariffs/TariffDetail'));
export const TariffEdit = lazy(() => import('app/pages-admin/tariffs/TariffEdit'));

export const Limits = lazy(() => import('app/pages-admin/limits/Limits'));
export const ContractMasks = lazy(() => import('app/pages-admin/contracts/ContractMasks'));
export const Zones = lazy(() => import('app/pages-admin/zones/Zones'));
export const Setup = lazy(() => import('app/pages-admin/setup/Setup'));
export const Shifts = lazy(() => import('app/pages-admin/shifts/Shifts'));
export const ShiftDetail = lazy(() => import('app/pages-admin/shifts/ShiftDetail'));
export const Roles = lazy(() => import('app/pages-admin/roles/roles'));
export const SetupReports = lazy(() => import('app/pages-admin/setup-reports/setup-reports'));

export const Bank = lazy(() => import('app/pages-admin/bank/Bank'));
export const Topup = lazy(() => import('app/pages-admin/topup/Topup'));

export const Reports = lazy(() => import('app/pages-admin/reports/Reports'));
