import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    text-transform: uppercase;
    color: #959ba4;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: 0.02em;
    text-decoration: none;
    padding: 0.7rem 0.9375rem;
    transition: 0.3s all ease-out;
    border-radius: 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      text-decoration: none;
    }
    &.active {
      color: #000;
    }
  }
`;
export const MenuItem = styled.li`
  margin: 0 0.1875rem;
`;
