import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { ArrowDownSmallIcon } from 'libs/icons/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2),
    },
}));
const useStylesButton = makeStyles(() => ({
    root: {
        minWidth: '48px',
        width: '48px',
        height: '48px',
        padding: 0,
        borderRadius: '50%',
    },
}));

const TablePaginationActions = (props) => {
    const { count, page, rowsPerPage, onPageChange } = props;
 
    const changer = onPageChange;// ? onChangePage : props.onPageChange;
    //console.log('changer', changer)

    const pagesCnt = Math.max(0, Math.ceil(count / rowsPerPage));

    const handleFirstPageButtonClick = (event) => {
        changer(event, 0);
    };

    const handleBackButtonClick = (event) => {
        changer(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        changer(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        changer(event, pagesCnt - 1);
    };

    const handlePageButtonClick = (event, i) => {
        changer(event, i - 1);
    };

    const size = undefined;
    const classes = useStyles();
    const classesButton = useStylesButton();
    const useLastFirst = true;
    const maxLinks = 6;
    const items = [];
    let from = page + 1 - maxLinks / 2;
    if (from < 1) {
        from = 1;
    }
    let to = page + 1 + maxLinks / 2;
    if (to > pagesCnt) {
        to = pagesCnt;
    }
    if (to > maxLinks) {
        to = maxLinks;
    }
    //console.log('🚀 ', from, to, count, page, rowsPerPage);
    for (let i = from; i <= to; i++) {
        items.push(
            <Button
                className={classesButton.root}
                key={i}
                onClick={(event) => {
                    handlePageButtonClick(event, i)
                }}
                disabled={page === i - 1}
                aria-label={'page ' + i}
            >
                {i}
            </Button>
        );
    }

    return (
        <div className={classes.root}>
            {useLastFirst && (
                <IconButton
                    size={size}
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <FirstPageIcon />
                </IconButton>
            )}
            <IconButton
                size={size}
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>

            {items}

            <IconButton
                size={size}
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            {useLastFirst && (
                <IconButton
                    size={size}
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <LastPageIcon />
                </IconButton>
            )}
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const PagerWithNumbers = (props) => {
    return (
        <TablePagination
            component="div"
            rowsPerPageOptions={props.rowsPerPageOptions}
            count={props.count}
            rowsPerPage={props.limit}
            page={props.page}
            onPageChange={props.onChangePage}
            onRowsPerPageChange={props.onChangeRowsPerPage}
            SelectProps={{ IconComponent: ArrowDownSmallIcon }}
            ActionsComponent={TablePaginationActions}
        />
    );
}
PagerWithNumbers.propTypes = {
    rowsPerPageOptions: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
};

export default PagerWithNumbers;
