import styled, { css } from 'styled-components';

export const StyledTitle = styled.div`
  font-weight: ${(props) => (props.light ? 'normal' : '600')};
  font-size: ${(props) => (props.big ? '2.625rem' : '2rem')};
  line-height: 1.21875;
  text-align: ${(props) => (props.$leftAlign ? 'left' : 'center')};
  color: #333333;
  margin: 0 0 ${(props) => (props.noMargin ? '0' : '3rem')};
  ${(props) =>
    props.big &&
    css`
      margin: 0 0 2.5rem;
      font-weight: bold;
    `}

  @media(max-width: 959px) {
    font-size: 1.5rem;
    margin: 0 0 ${(props) => (props.noMargin ? '0' : '2rem')};
  }
`;
