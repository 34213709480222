import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import { FieldArray } from 'react-final-form-arrays';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';

import { FormGroup } from './styled';
import { CheckIcon } from 'libs/icons/icons';
import { Confirm } from 'libs/ui';
import DropZone from './inputs/drop-zone';
import Autocomplete from './inputs/autocomplete';
import MaskedInputMUI from './inputs/masked-input';
import CheckboxGroupMUI from './inputs/checkbox-group';
import DateRangeMUI from './inputs/date-range';
import validators from 'services/validators';

const noPadErrorClassName = 'error--no-pad';

export class InputFieldFinal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
      confirmAction: null,
      confirmText: '',
      suggestValue: props.suggestValue ? props.suggestValue : '',
      focused: false,
      opened: false,
      focusedInput: null,
    };

    this.setSuggestValue = this.setSuggestValue.bind(this);
    this.TextFieldAdapterMUI = this.TextFieldAdapterMUI.bind(this);
    this.MaskFieldAdapterMUI = this.MaskFieldAdapterMUI.bind(this);
    this.SelectAdapterMUI = this.SelectAdapterMUI.bind(this);
    this.RadioGroupAdapterMUI = this.RadioGroupAdapterMUI.bind(this);
    //this.SuggestAdapterMUI = this.SuggestAdapterMUI.bind(this);
    this.SuggestAdapterMUI2 = this.SuggestAdapterMUI2.bind(this);
    this.SwitchAdapterMUI = this.SwitchAdapterMUI.bind(this);
    this.CheckboxAdapterMUI = this.CheckboxAdapterMUI.bind(this);
    this.DateFieldAdapterMUI = this.DateFieldAdapterMUI.bind(this);
    this.FileDropAdapterMUI = this.FileDropAdapterMUI.bind(this);
    this.DateRangeAdapterMUI = this.DateRangeAdapterMUI.bind(this);
    this.CheckboxGroupAdapterMUI = this.CheckboxGroupAdapterMUI.bind(this);
  }

  /**
   * return not mutable items without some
   *
   * @param {*} items
   * @returns
   * @memberof InputFieldFinal
   */
  prepareAttr(items, excl) {
    const attr = { ...items };
    //console.log(items, attr);
    if (!excl) {
      excl = ['afterChange', 'reactChange', 'reactFocus', 'customerror', 'hideCheck'];
    }
    if (excl.indexOf('hideCheck') === -1) {
      excl.push('hideCheck');
    }
    excl.forEach((el) => {
      if (attr[el] !== undefined) {
        delete attr[el];
      }
    });

    return attr;
  }

  prepareProps(props, meta, val) {
    //const out = { ...props };
    /*
        console.log('dirty', meta.dirty)
        console.log('valid', meta.valid)
        console.log('active', meta.active)
        console.log('touched', meta.touched)
        console.log('val', val)
        */
    if (val === '+7 (') {
      val = '';
    }
    if (!this.props.endAdornment && !this.props.hideCheck && !meta.validating && meta.valid && meta.touched && val) {
      props.endAdornment = (
        <InputAdornment position="end">
          <CheckIcon />
        </InputAdornment>
      );
    }
    return props;
  }

  /**
   * executes after each input change
   * @param {*} value
   * @param {*} defaultOnChange
   */
  commonChange = (value, defaultOnChange, filter, valueObj = {}) => {
    //console.log(value)

    if (this.props.confirm) {
      this.setState({
        showConfirm: true,
        confirmText: this.props.confirmText ? this.props.confirmText : 'Подтвердите действие',
        confirmAction: () => {
          if (this.props.reactChange) {
            this.props.reactChange(value, this.props.name);
          }
          defaultOnChange(value);
          this.closeConfirm();
        },
      });
      return;
    }

    if (filter) {
      if (!filter(value)) {
        return false;
      }
    }

    if (this.props.reactChange) {
      this.props.reactChange(value, this.props.name, valueObj);
    }
    //console.log('form change', value)
    defaultOnChange(value);
  };

  identity(value) {
    //console.log('identity ' + value)
    if (value === undefined) {
      value = '';
    }
    return value;
  }

  getError(meta, className = '') {
    const error = (meta.submitError && !meta.dirtySinceLastSubmit) || meta.error;
    const changed = meta.dirty || meta.dirtySinceLastSubmit || meta.submitFailed; // || add;
    //(meta.dirty && meta.valid);
    const showError = (typeof error === 'string' && error.length > 0 && changed) || this.props.customerror !== '';
    //console.log('🚀 ~ ' + field, meta, showError)
    /*
        console.log("🚀", field,  meta.dirty , meta.valid, add);
        if (field.indexOf('model_car') !== -1) {
            console.log("🚀 error" + ' ' + error);
            console.log("changed" + ' ' + changed);
            console.log("showerror" + ' ' + showError);
        }
        */
    const label = showError ? this.getLabel(this.props.customerror || meta.error || meta.submitError, showError, className) : null;
    return { showError: showError, label: label };
  }

  setSuggestValue(value, clear = false) {
    if (clear) {
      value = '';
    }
    this.setState({ suggestValue: value });
  }

  SelectAdapterMUI({ input: { onChange, onBlur, value, name }, meta, fullWidth, hiddenLabel, ...rest }) {
    const items = rest.values.map((item) => (
      <MenuItem key={item.value} value={item.value}>
        {item.title}
      </MenuItem>
    ));

    const error = this.getError(meta); //, '', custom);
    //console.log("🚀 ~", meta.dirty, meta.touched)

    const attr = this.prepareAttr(rest, ['reactChange', 'reactFocus', 'customerror']);
    const cl = 'select' + (this.state.opened ? ' opened' : '');
    const menuProps = {
      //variant: 'menu',
    };
    if (attr.margin === 'dense') {
      menuProps.MenuListProps = { dense: true };
    }
    attr.MenuProps = menuProps;
    return (
      <>
        <FormControl variant="filled" fullWidth={fullWidth} hiddenLabel={hiddenLabel}>
          {rest.label && <InputLabel id={name}>{rest.label}</InputLabel>}
          <Select
            {...attr}
            onOpen={() => {
              this.setState({ opened: true });
            }}
            onClose={() => {
              this.setState({ opened: false });
            }}
            onBlur={(event) => onBlur(event)}
            error={error.showError}
            className={cl}
            //autoWidth={true}
            elevation={0}
            labelId={name}
            type="select"
            value={value}
            name={name}
            onChange={(event) => this.commonChange(event.target.value, onChange)}
          >
            {items}
          </Select>
        </FormControl>
        {error.label}
      </>
    );
  }

  FileDropAdapterMUI({ input: { onChange, value, name }, meta, acceptedFiles, disabled, success, small, ...rest }) {
    const error = this.getError(meta);
    //console.log(rest.previewTitle)
    //const attr = this.prepareAttr(rest, ['reactChange', 'reactFocus', 'customerror']);

    return (
      <>
        <DropZone
          small={small}
          success={success}
          disabled={disabled}
          acceptedFiles={acceptedFiles}
          icon={rest.icon}
          previewTitle={rest.previewTitle}
          title={rest.label}
          value={value}
          name={name}
          onChange={(val) => this.commonChange(val, onChange)}
        />
        {error.label}
      </>
    );
  }

  DateRangeAdapterMUI({ input: { onChange, value, name, ...restInput }, meta, ...rest }) {
    const error = this.getError(meta);
    const attr = this.prepareAttr(rest);
    //console.log(value);
    return (
      <>
        <DateRangeMUI {...attr} InputProps={restInput} value={value} name={name} onChange={(val) => this.commonChange(val, onChange)} />
        {error.label}
      </>
    );
  }

  TextFieldAdapterMUI({ input: { name, value, onFocus, onChange, ...restInput }, meta, endAdornment, ...rest }) {
    const error = this.getError(meta, '', name);

    const attr = this.prepareAttr(rest);
    const inputProps = {};
    let filter = undefined;
    //console.log('123123',attr, rest)
    restInput.onFocus = (e) => {
      onFocus(e);
      if (this.props.reactFocus) {
        this.props.reactFocus(e);
      }
      //console.log('focused', e.target);
    };
    if (attr.step) {
      const withoutSign = rest.withoutSign;
      inputProps.step = attr.step;
      inputProps.type = 'text';
      filter = (val) => validators.numberTest(val, !withoutSign);
    }

    this.prepareProps(restInput, meta, value);
    if (endAdornment) {
      restInput.endAdornment = endAdornment;
    }
    if (rest.pattern) {
      restInput.pattern = rest.pattern;
      delete rest.pattern;
    }

    //console.log('111111',attr);
    return (
      <>
        <TextField
          {...attr}
          name={name}
          error={error.showError}
          inputProps={inputProps}
          InputProps={restInput}
          //onFocus={() => { console.log('focused')}}
          onChange={(event) => this.commonChange(event.currentTarget.value, onChange, filter)}
          value={value}
          //errorText={meta.touched ? meta.error : ''}
        />
        {error.label}
      </>
    );
  }

  MaskFieldAdapterMUI({ input: { name, value, onChange, ...restInput }, meta, ...rest }) {
    const error = this.getError(meta);
    const attr = this.prepareAttr(rest);
    //console.log(error, meta)

    this.prepareProps(restInput, meta, value);

    return (
      <>
        <MaskedInputMUI
          {...attr}
          name={name}
          error={error.showError}
          InputProps={restInput}
          onChange={(event) => this.commonChange(event.currentTarget.value, onChange)}
          value={value}
          //value={value}
          //name={name}
          //inputComponent={MaskedInputMUI}
        />
        {error.label}
      </>
    );
  }

  RadioGroupAdapterMUI({ input: { onChange, value, name }, meta, disabled, inline, ...rest }) {
    //console.log(name, meta, rest)
    const key = name;
    const cl = inline ? 'inline' : 'not-inline';
    const items = rest.values.map((item, idx) => {
      return (
        <FormControlLabel
          className={cl}
          key={key + idx}
          control={<Radio />}
          value={item.value.toString()}
          label={item.title}
          labelPlacement="end"
          disabled={disabled}
        />
      );
    });
    const error = this.getError(meta, noPadErrorClassName);
    const other = {};
    if (inline) {
      other.row = true;
    }
    return (
      <>
        <RadioGroup
          {...other}
          aria-label={name}
          name={name}
          value={value}
          //onChange={onChange}
          onChange={(event) => this.commonChange(event.currentTarget.value, onChange)}
        >
          {items}
        </RadioGroup>
        {error.label}
      </>
    );
  }

  CheckboxGroupAdapterMUI({ input: { __onChange, __value, name }, meta, disabled, inline, ...rest }) {
    //console.log(name, meta, rest)
    //const key = name;
    const cl = inline ? 'inline' : 'not-inline';
    /*
        const items = rest.values.map((item, idx) => {
            return (
                <FormControlLabel
                    className={cl}
                    key={key + idx}
                    control={<Checkbox />}
                    value={item.value.toString()}
                    label={item.title}
                    labelPlacement="end"
                    disabled={disabled}
                />
            );
        });*/
    const error = this.getError(meta, noPadErrorClassName);
    const other = {};

    return (
      <>
        <FieldArray
          {...other}
          cl={cl}
          aria-label={name}
          name={name}
          component={CheckboxGroupMUI}
          options={rest.values}
          //value={value}
          disabled={disabled}
          //onChange={onChange}
          /*
                    onChange={(event) => {
                        console.log(
                            '🚀 ~ event.currentTarget.value',
                            event.currentTarget.value
                        );
                        return this.commonChange(
                            event.currentTarget.value,
                            onChange
                        );
                    }}
                    */
        />

        {error.label}
      </>
    );
  }

  /*
    SuggestAdapterMUI({ input: { onChange, value, name }, meta, defaultItems, emptyMsg, fetcher, ...rest }) {
        const emptyValue = (value === '');
        const error = this.getError(meta);
        const attr = this.prepareAttr(rest);

        return (
            <>
                <SuggestInput
                    {...attr}
                    error={error.showError}
                    onChange={(value, fn, id) => {
                        onChange(id);
                    }}
                    emptyValue={emptyValue}
                    suggestValue={this.state.suggestValue}
                    setSuggestValue={this.setSuggestValue}
                    placeholder={rest.label}
                    name={name}
                    id={name}
                    fetcher={fetcher}
                    default={defaultItems}
                    emptyMsg={emptyMsg}
                />
                {error.label}
            </>
        );
    }
    */

  SuggestAdapterMUI2({ input: { onChange, value, name }, meta, defaultItems: __defaultItems, emptyMsg: __emptyMsg, fetcher, items, ...rest }) {
    const error = this.getError(meta);
    const attr = this.prepareAttr(rest);
    const itemsReal = items ? items : [];
    //console.log('def', value, attr)
    //console.log('def1111', attr)
    return (
      <>
        <Autocomplete
          {...attr}
          error={error.showError}
          name={name}
          input={name}
          id={name}
          label={rest.label}
          required={true}
          options={itemsReal}
          value={value}
          //defaultValue={value}
          //inputValue={value}
          onChange={(value, obj /*, id, props*/) => {
            //console.log(value);
            //onChange(value);
            this.commonChange(value, onChange, '', obj);
          }}
          fetcher={fetcher}
        />
        {error.label}
      </>
    );
  }

  SwitchAdapterMUI({ input, meta, ...rest }) {
    const error = this.getError(meta, noPadErrorClassName);
    const attr = this.prepareAttr(rest);
    //console.log('🚀 ~ SwitchAdapterMUI ~ attr', attr);
    //console.log(input)
    delete attr.fullWidth;

    return (
      <>
        <FormControlLabel
          control={
            <Switch
              {...attr}
              label={attr.title}
              name={input.name}
              id={input.name}
              checked={!!input.value}
              onChange={(event) => this.commonChange(event.currentTarget.checked, input.onChange)}
              value={input.name}
            />
          }
        />
        {error.label}
      </>
    );
  }

  CheckboxAdapterMUI({ input, meta, ...rest }) {
    const error = this.getError(meta, noPadErrorClassName);
    const attr = this.prepareAttr(rest, ['reactChange', 'reactFocus', 'customerror', 'fullWidth']);

    return (
      <>
        <FormControlLabel
          label={attr.label}
          control={
            <Checkbox
              {...attr}
              name={input.name}
              id={input.name}
              checked={!!input.checked}
              onChange={(event) => this.commonChange(event.currentTarget.checked, input.onChange)}
              value={input.name}
            />
          }
        />
        {error.label}
      </>
    );
  }

  DateFieldAdapterMUI({ input: { name, value, onChange }, meta, ...rest }) {
    const error = this.getError(meta);
    const attr = this.prepareAttr(rest);

    //console.log(attr);
    const val = !value ? null : value;
    return (
      <>
        <KeyboardDatePicker
          {...attr}
          autoOk={true}
          label={attr.label}
          value={val}
          name={name}
          onChange={(date) => this.commonChange(date, onChange)}
          //autoOk
          //openTo="year"
          maxDate={new Date(2999, 1, 1, 0, 0, 0, 0)}
          //invalidDateMessage={null}//error.label}
          invalidLabel=""
          KeyboardButtonProps={{ size: 'small', edge: 'end' }}
          variant="inline"
          inputVariant="filled"
          format="DD.MM.YYYY"
          //views={['year', 'month', 'date']}
        />
        {error.label}
      </>
    );
  }

  getInput() {
    const { name, title, values, confirmTitle: __no, ...rest } = this.props;
    const attr = this.prepareAttr(rest, ['confirm', 'confirmText', 'inline', 'noMargin']);

    let component;

    switch (attr.type) {
      default:
        component = this.TextFieldAdapterMUI;
        attr.variant = 'filled';
        break;
      case 'date':
        component = this.DateFieldAdapterMUI;
        break;
      case 'daterange':
        component = this.DateRangeAdapterMUI;
        attr.variant = 'filled';
        break;
      case 'masked':
        component = this.MaskFieldAdapterMUI;
        attr.variant = 'filled';
        //attr.margin = 'dense';
        //attr.variant = 'outlined';
        break;
      case 'select':
      case 'selectmui':
        component = this.SelectAdapterMUI;
        attr.values = values;
        break;
      case 'radiogroup':
        component = this.RadioGroupAdapterMUI;
        attr.values = values;
        break;
      case 'switch':
        component = this.SwitchAdapterMUI;
        attr.values = values;
        break;
      /*
            case 'suggest':
                //attr.margin = 'dense';
                attr.variant = 'filled';
                component = this.SuggestAdapterMUI;
                break;
                */
      case 'suggestmui':
        attr.variant = 'filled';
        component = this.SuggestAdapterMUI2;
        break;
      case 'filedrop':
        component = this.FileDropAdapterMUI;
        break;
      case 'checkbox':
        component = this.CheckboxAdapterMUI;
        break;
      case 'checkboxgroup':
        component = this.CheckboxGroupAdapterMUI;
        attr.values = values;
        attr.inline = this.props.inline;
        break;
    }

    //console.log(rest)
    return (
      <Field
        {...attr}
        name={name}
        component={component}
        label={title}
        //parse={null}
        parse={this.identity}
        format={this.identity}
      />
    );
  }

  getLabel(label, error = false, cl = '') {
    if (!label) {
      return null;
    }
    return (
      <FormHelperText className={cl} error={error}>
        {label}
      </FormHelperText>
    );
  }

  closeConfirm = () => {
    this.setState({ showConfirm: false });
  };

  render() {
    const { showConfirm, confirmAction, confirmText } = this.state;
    const { cl, inline, description, disabled, fullWidth, noMargin, confirmTitle } = this.props;
    const input = this.getInput();
    let clResult = cl ? cl : '';
    if (inline) {
      clResult += ' field-inline';
    }
    if (disabled) {
      clResult += ' field-disabled';
    }

    return (
      <FormGroup noMargin={noMargin} className={clResult} fullWidth={fullWidth}>
        {!inline && showConfirm && (
          <Confirm error="" open={showConfirm} closeModal={this.closeConfirm} title={confirmTitle} info={confirmText} action={confirmAction} />
        )}
        {input}
        {!inline && <div className="description">{description}</div>}
      </FormGroup>
    );
  }
}

InputFieldFinal.propTypes = {
  type: PropTypes.string.isRequired,
  reactChange: PropTypes.func,
  reactFocus: PropTypes.func,
  noMargin: PropTypes.bool,
  confirm: PropTypes.bool,
  confirmText: PropTypes.string,
  disabled: PropTypes.bool,
  cl: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.array,
  fullWidth: PropTypes.bool,
  inline: PropTypes.bool,
  customerror: PropTypes.string,
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  hideCheck: PropTypes.bool,
  endAdornment: PropTypes.node,
  suggestValue: PropTypes.string,
  confirmTitle: PropTypes.string,
};

InputFieldFinal.defaultProps = {
  confirmTitle: 'Подтвердите действие',
  type: 'text',
  hideCheck: false,
  noMargin: false,
  disabled: false,
  confirm: false,
  reactChange: null,
  fullWidth: true,
  inline: false,
  customerror: '',
};

export default InputFieldFinal;
