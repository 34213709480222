/* tslint:disable */
/* eslint-disable */
/**
 * BSTR REST API
 * API for BSTR service
 *
 * The version of the OpenAPI document: 1.0.3
 * Contact: mdiakonov@myboostr.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Account,
    AddBarCode,
    AddJourneyTransaction,
    Auth,
    AuthInfo,
    Bic,
    Body,
    Client,
    ClientCompany,
    ClientIndividual,
    ClientSP,
    ClientType,
    ClientWithUser,
    CloseShift,
    ContractItem,
    ContractStatus,
    ContractUpdate,
    ControllerLabel,
    CreateJourney,
    CreateReport,
    CreateRole,
    CreateTariff,
    Document,
    DocumentCategory,
    DocumentCode,
    FileCreate,
    FileExtraCreate,
    FileGenerate,
    GenerateDocument,
    GenerateReport,
    InlineObject,
    InlineObject1,
    InlineObject10,
    InlineObject11,
    InlineObject12,
    InlineObject13,
    InlineObject14,
    InlineObject15,
    InlineObject16,
    InlineObject2,
    InlineObject3,
    InlineObject4,
    InlineObject5,
    InlineObject6,
    InlineObject7,
    InlineObject8,
    InlineObject9,
    InlineResponse200,
    InlineResponse2001,
    InlineResponse20010,
    InlineResponse20011,
    InlineResponse2002,
    InlineResponse2003,
    InlineResponse2004,
    InlineResponse2005,
    InlineResponse2006,
    InlineResponse2007,
    InlineResponse2008,
    InlineResponse2009,
    InlineResponse201,
    InlineResponse2011,
    InlineResponse2012,
    InlineResponse2013,
    InlineResponse400,
    InlineResponse401,
    InlineResponse409,
    InteroperabilityResponse,
    JourneyType,
    Label,
    LabelStatus,
    MenuItem,
    Merchant,
    MerchantListItem,
    Message,
    MoneyAmount,
    MoneyAmountWithNote,
    OpenShift,
    Operation,
    OperationStatus,
    OperationType,
    OrgType,
    ParkingExit,
    ParkingExitResponse,
    ParkingInfo,
    ParkingOperations,
    ParkingReplenishment,
    PermissionItem,
    ReceiveTransactionList,
    ReportGenerate,
    ReportItem,
    ReportListItem,
    Reservlabel,
    RoleItem,
    RoleListItem,
    ServerError,
    ShiftDayItem,
    ShiftItem,
    ShiftListItem,
    Tariff,
    TariffStatus,
    Tokens,
    UpdateRole,
    UpdateTariff,
    UserBasic,
    UserBasicWithClientType,
    UserCreate,
    UserCustomRole,
    UserInfo,
    UserLogin,
    UserSendValidationCode,
    UserValidateContact,
    VehicleItem,
} from '../models';

export interface DefaultApiAddBarcodeRequest {
    addBarCode?: AddBarCode;
}

export interface DefaultApiAddTransactionRequest {
    addJourneyTransaction?: AddJourneyTransaction;
}

export interface DefaultApiBankfileRequest {
    inlineObject9?: InlineObject9;
}

export interface DefaultApiBankpaymentsConfirmRequest {
    inlineObject11?: InlineObject11;
}

export interface DefaultApiBankpaymentsinfoRequest {
    id: string;
}

export interface DefaultApiBankpaymentslistRequest {
    limit: number;
    offset: number;
    dateFrom?: string;
    dateTo?: string;
    hashId?: string;
    amount?: string;
    num?: string;
    payersName?: string;
    reason?: string;
    bankBik?: string;
    bank?: string;
    status?: string;
    ordering?: string;
}

export interface DefaultApiBankpaymentsupdateRequest {
    id: string;
    inlineObject10?: InlineObject10;
}

export interface DefaultApiChangeUserPasswordRequest {
    inlineObject16?: InlineObject16;
}

export interface DefaultApiCheckBarcodeRequest {
    code: string;
}

export interface DefaultApiCheckLicensePlateRequest {
    licensePlate: string;
}

export interface DefaultApiCloseShiftRequest {
    closeShift?: CloseShift;
}

export interface DefaultApiConfirmClientRequest {
    clientId: string;
}

export interface DefaultApiCorrectingBalanceRequest {
    accountId: string;
    moneyAmountWithNote?: MoneyAmountWithNote;
}

export interface DefaultApiCreateContractRequest {
    clientId: string;
}

export interface DefaultApiCreateReportRequest {
    createReport?: CreateReport;
}

export interface DefaultApiCreateRoleRequest {
    createRole?: CreateRole;
}

export interface DefaultApiCreateTariffRequest {
    createTariff: CreateTariff;
}

export interface DefaultApiCreatereservlabelRequest {
    inlineObject5?: InlineObject5;
}

export interface DefaultApiCretereservlabelRequest {
    inlineObject4?: InlineObject4;
}

export interface DefaultApiDeleteReportRequest {
    reportId: string;
}

export interface DefaultApiDeleteRoleRequest {
    roleId: string;
}

export interface DefaultApiDeletereservlabelsRequest {
    id: string;
}

export interface DefaultApiFeedbackClientRequest {
    body?: Body;
}

export interface DefaultApiGenerateDocumentsRequest {
    fileGenerate?: FileGenerate;
}

export interface DefaultApiGenerateReportsRequest {
    reportGenerate?: ReportGenerate;
    dateFrom?: string;
    dateTo?: string;
    ordering?: string;
    clientType?: ClientType;
    operationType?: OperationType;
    request?: string;
    status?: string;
    clientName?: string;
    client?: number;
    contractNumber?: string;
    paymentMean?: string;
    licensePlate?: string;
    tariffZone?: string;
    vehicleClass?: string;
    location?: string;
    lane?: string;
    sum?: string;
    name?: string;
    car?: string;
    clienttype?: ClientType;
    statusId?: ContractStatus;
    pan?: string;
    vehicle?: string;
    tariffGroup?: string;
    contractId?: string;
    clientId?: string;
}

export interface DefaultApiGetRequest {
    offset: number;
    limit: number;
    ordering?: GetOrderingEnum;
    operatorId?: string;
    laneId?: number;
    externalId?: string;
    status?: GetStatusEnum;
}

export interface DefaultApiGetAccountRequest {
    accountId: string;
}

export interface DefaultApiGetBanksRequest {
    request: string;
    offset: number;
    limit: number;
}

export interface DefaultApiGetChangeLabelsRequest {
    version?: number;
}

export interface DefaultApiGetClientRequest {
    clientId: string;
}

export interface DefaultApiGetClientActivityRequest {
    clientId: string;
    limit: number;
    offset: number;
    ordering?: string;
}

export interface DefaultApiGetClientsRequest {
    offset: number;
    limit: number;
    request?: string;
    clienttype?: ClientType;
    statusId?: ContractStatus;
    ordering?: string;
}

export interface DefaultApiGetContractRequest {
    contractId: string;
}

export interface DefaultApiGetContractsRequest {
    clientId?: string;
    status?: GetContractsStatusEnum;
}

export interface DefaultApiGetDocumentRequest {
    fileName: string;
    clientId: string;
}

export interface DefaultApiGetDocumentsRequest {
    orgType?: OrgType;
    category?: DocumentCategory;
    code?: DocumentCode;
}

export interface DefaultApiGetMerchantRequest {
    merchantKey: string;
}

export interface DefaultApiGetParkingTokenRequest {
    auth?: Auth;
}

export interface DefaultApiGetReportRequest {
    reportId: string;
}

export interface DefaultApiGetRoleRequest {
    roleId: string;
}

export interface DefaultApiGetShiftRequest {
    id: number;
}

export interface DefaultApiGetShiftDaysRequest {
    offset: number;
    limit: number;
    id?: number;
    day?: Date;
}

export interface DefaultApiGetTariffByIdRequest {
    tariffId: number;
    version?: number;
}

export interface DefaultApiGetTariffsRequest {
    offset: number;
    limit: number;
    dateFrom?: string;
    dateTo?: string;
    ordering?: string;
    search?: string;
    tariffStatus?: TariffStatus;
    name?: string;
    tariffType?: GetTariffsTariffTypeEnum;
}

export interface DefaultApiGetTokenRequest {
    auth?: Auth;
}

export interface DefaultApiGetUserRequest {
    userId: string;
}

export interface DefaultApiGetUsersRequest {
    merchantKey: string;
    offset: number;
    limit: number;
    orgType?: OrgType;
}

export interface DefaultApiInfoForParkomatRequest {
    search: string;
}

export interface DefaultApiInforeservlabelsRequest {
    id: string;
}

export interface DefaultApiInstantCreateClientRequest {
    clientWithUser?: ClientWithUser;
}

export interface DefaultApiInstantUpdateClientRequest {
    clientId: string;
    clientWithUser?: ClientWithUser;
}

export interface DefaultApiIssueALabelRequest {
    inlineObject3?: InlineObject3;
}

export interface DefaultApiIssueLabelsRequest {
    id: string;
}

export interface DefaultApiLabelHistoryRequest {
    id: string;
    limit: number;
    offset: number;
    ordering?: string;
}

export interface DefaultApiLabelcreateRequest {
    inlineObject?: InlineObject;
}

export interface DefaultApiLabelhistoryRequest {
    limit: number;
    offset: number;
    pan: string;
    ordering?: string;
}

export interface DefaultApiLabelinfoRequest {
    id: string;
}

export interface DefaultApiLabelsListRequest {
    limit: number;
    offset: number;
    dateFrom?: string;
    dateTo?: string;
    pan?: string;
    clientName?: string;
    contractNumber?: string;
    licensePlate?: string;
    vehicle?: string;
    tariffGroup?: string;
    ordering?: string;
    contractId?: string;
    clientId?: string;
    status?: LabelStatus;
}

export interface DefaultApiLabelscreateobjectsRequest {
    inlineObject2?: InlineObject2;
}

export interface DefaultApiLabelupdateRequest {
    id: string;
    inlineObject1?: InlineObject1;
}

export interface DefaultApiLoginUserRequest {
    userLogin?: UserLogin;
}

export interface DefaultApiMarchantsSaveDetailsRequest {
    merchantKey: string;
    merchant?: Merchant;
}

export interface DefaultApiOpenShiftRequest {
    openShift?: OpenShift;
}

export interface DefaultApiOperationCorrectedRequest {
    operationId: string;
    inlineObject14?: InlineObject14;
}

export interface DefaultApiOperationJourneyRequest {
    createJourney?: CreateJourney;
}

export interface DefaultApiOperationRollbackRequest {
    operationId: string;
    inlineObject13?: InlineObject13;
}

export interface DefaultApiOperationUpdateRequest {
    operationId: string;
    inlineObject12?: InlineObject12;
}

export interface DefaultApiOperationViewRequest {
    operationId: string;
}

export interface DefaultApiOperationsListRequest {
    offset: number;
    limit: number;
    dateFrom?: string;
    dateTo?: string;
    ordering?: string;
    clientType?: ClientType;
    operationType?: OperationType;
    journeyType?: JourneyType;
    request?: string;
    status?: OperationStatus;
    clientName?: string;
    client?: number;
    contractNumber?: string;
    paymentMean?: string;
    licensePlate?: string;
    tariffZone?: string;
    vehicleClass?: string;
    location?: string;
    lane?: string;
    sum?: string;
    name?: string;
    car?: string;
    shiftId?: number;
}

export interface DefaultApiParkingExitRequest {
    parkingExit?: ParkingExit;
}

export interface DefaultApiParkingOperationsRequest {
    parkingOperations?: ParkingOperations;
}

export interface DefaultApiPostDocumentsRequest {
    fileCreate?: FileCreate;
}

export interface DefaultApiPostDocumentsExtraRequest {
    fileExtraCreate?: FileExtraCreate;
}

export interface DefaultApiPutClientRequest {
    clientId: string;
    clientIndividualClientSPClientCompany?: ClientIndividual | ClientSP | ClientCompany;
}

export interface DefaultApiPutUserRequest {
    userId: string;
    userBasic?: UserBasic;
}

export interface DefaultApiReceiveTransactionListRequest {
    receiveTransactionList?: ReceiveTransactionList;
}

export interface DefaultApiRegisterCustomUserRequest {
    userCustomRole: UserCustomRole;
}

export interface DefaultApiRegisterUserRequest {
    userCreate: UserCreate;
}

export interface DefaultApiRegisterUserInstantRequest {
    userBasicWithClientType: UserBasicWithClientType;
}

export interface DefaultApiRejectClientRequest {
    clientId: string;
    message?: Message;
}

export interface DefaultApiReplenishmentAccountRequest {
    accountId: string;
    moneyAmount?: MoneyAmount;
}

export interface DefaultApiReplenishmentFromParkingRequest {
    parkingReplenishment?: ParkingReplenishment;
}

export interface DefaultApiReservlabelsListRequest {
    limit: number;
    offset: number;
    search?: string;
    ordering?: string;
    clientId?: string;
}

export interface DefaultApiSendValidationCodeRequest {
    userSendValidationCode?: UserSendValidationCode;
}

export interface DefaultApiSetLabelsToReservlabelRequest {
    id: string;
    inlineObject7?: InlineObject7;
}

export interface DefaultApiSetUserPasswordRequest {
    inlineObject15?: InlineObject15;
}

export interface DefaultApiSetlLabelCostRequest {
    moneyAmount?: MoneyAmount;
}

export interface DefaultApiUpdateRoleRequest {
    roleId: string;
    updateRole?: UpdateRole;
}

export interface DefaultApiUpdateTariffRequest {
    contractId: string;
    contractUpdate?: ContractUpdate;
}

export interface DefaultApiUpdateTariffByIdRequest {
    updateTariff: UpdateTariff;
    tariffId: number;
}

export interface DefaultApiUpdateTariffStatusRequest {
    tariffStatus: TariffStatus;
    tariffId: number;
}

export interface DefaultApiUpdateVehiclesRequest {
    id: string;
    inlineObject8?: InlineObject8;
}

export interface DefaultApiUpdatereservlabelsRequest {
    id: string;
    inlineObject6?: InlineObject6;
}

export interface DefaultApiValidateUserContactRequest {
    userValidateContact?: UserValidateContact;
}

export interface DefaultApiVehicleItemRequest {
    id: string;
}

export interface DefaultApiVehiclesListRequest {
    limit: number;
    offset: number;
}

/**
 * DefaultApi - interface
 * 
 * @export
 * @interface DefaultApiInterface
 */
export interface DefaultApiInterface {
    /**
     * Загрузка штрих-кода в базу
     * @param {AddBarCode} [addBarCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    addBarcodeRaw(requestParameters: DefaultApiAddBarcodeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Загрузка штрих-кода в базу
     */
    addBarcode(addBarCode?: AddBarCode): Promise<void>;

    /**
     * Загрузка операции проезда полосы
     * @param {AddJourneyTransaction} [addJourneyTransaction] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    addTransactionRaw(requestParameters: DefaultApiAddTransactionRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Загрузка операции проезда полосы
     */
    addTransaction(addJourneyTransaction?: AddJourneyTransaction): Promise<void>;

    /**
     * файл для парсера банковских операций  возвращает объект с данными банковских платежей
     * @summary отправляет файл с банковскими операциями
     * @param {InlineObject9} [inlineObject9] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    bankfileRaw(requestParameters: DefaultApiBankfileRequest): Promise<runtime.ApiResponse<InlineResponse2007>>;

    /**
     * файл для парсера банковских операций  возвращает объект с данными банковских платежей
     * отправляет файл с банковскими операциями
     */
    bankfile(inlineObject9?: InlineObject9): Promise<InlineResponse2007>;

    /**
     * Проводит все обработанные платежи
     * @summary Зачисление банковских платежей
     * @param {InlineObject11} [inlineObject11] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    bankpaymentsConfirmRaw(requestParameters: DefaultApiBankpaymentsConfirmRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Проводит все обработанные платежи
     * Зачисление банковских платежей
     */
    bankpaymentsConfirm(inlineObject11?: InlineObject11): Promise<void>;

    /**
     * Получает объект банковской операции.
     * @summary получение объекта банковской операции переданому id
     * @param {string} id ID объекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    bankpaymentsinfoRaw(requestParameters: DefaultApiBankpaymentsinfoRequest): Promise<runtime.ApiResponse<InlineResponse2008>>;

    /**
     * Получает объект банковской операции.
     * получение объекта банковской операции переданому id
     */
    bankpaymentsinfo(id: string): Promise<InlineResponse2008>;

    /**
     * список временных файлов банковских операций
     * @summary список банковских операций
     * @param {number} limit Количество возвращаемых элементов
     * @param {number} offset Количество пропускаемых элементов
     * @param {string} [dateFrom] Дата начала периода
     * @param {string} [dateTo] Дата окончания периода
     * @param {string} [hashId] базе возвращает список банковских операций файла
     * @param {string} [amount] Суммма платежа
     * @param {string} [num] Номер платежа
     * @param {string} [payersName] Плательщик
     * @param {string} [reason] Основание платежа
     * @param {string} [bankBik] Бик плательщика
     * @param {string} [bank] Банк плательщика
     * @param {string} [status] Статус платежа
     * @param {string} [ordering] сортировака по полям status, created, date, payers_name, amount, num, reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    bankpaymentslistRaw(requestParameters: DefaultApiBankpaymentslistRequest): Promise<runtime.ApiResponse<Array<InlineResponse2006>>>;

    /**
     * список временных файлов банковских операций
     * список банковских операций
     */
    bankpaymentslist(limit: number, offset: number, dateFrom?: string, dateTo?: string, hashId?: string, amount?: string, num?: string, payersName?: string, reason?: string, bankBik?: string, bank?: string, status?: string, ordering?: string): Promise<Array<InlineResponse2006>>;

    /**
     * Обновляет статус объекта банковского платежа, привязывает к договору
     * @summary изменение банковского платежа
     * @param {string} id ID платежа
     * @param {InlineObject10} [inlineObject10] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    bankpaymentsupdateRaw(requestParameters: DefaultApiBankpaymentsupdateRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Обновляет статус объекта банковского платежа, привязывает к договору
     * изменение банковского платежа
     */
    bankpaymentsupdate(id: string, inlineObject10?: InlineObject10): Promise<void>;

    /**
     * Изменить пароль со старого на новый текущему пользователю
     * @summary Изменить пароль со старого на новый
     * @param {InlineObject16} [inlineObject16] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    changeUserPasswordRaw(requestParameters: DefaultApiChangeUserPasswordRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Изменить пароль со старого на новый текущему пользователю
     * Изменить пароль со старого на новый
     */
    changeUserPassword(inlineObject16?: InlineObject16): Promise<void>;

    /**
     * Проверка штрих-кода
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    checkBarcodeRaw(requestParameters: DefaultApiCheckBarcodeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Проверка штрих-кода
     */
    checkBarcode(code: string): Promise<void>;

    /**
     * Проверяет уникальность ГРЗ. В случае существания, возвращает 409 и массив с информацией.
     * @summary Проверяет уникальность ГРЗ
     * @param {string} licensePlate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    checkLicensePlateRaw(requestParameters: DefaultApiCheckLicensePlateRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Проверяет уникальность ГРЗ. В случае существания, возвращает 409 и массив с информацией.
     * Проверяет уникальность ГРЗ
     */
    checkLicensePlate(licensePlate: string): Promise<void>;

    /**
     * Закрытие смены
     * @param {CloseShift} [closeShift] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    closeShiftRaw(requestParameters: DefaultApiCloseShiftRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Закрытие смены
     */
    closeShift(closeShift?: CloseShift): Promise<void>;

    /**
     * Входным параметром передается ID клиента.
     * @summary Подтверждение клиента
     * @param {string} clientId ID клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    confirmClientRaw(requestParameters: DefaultApiConfirmClientRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Входным параметром передается ID клиента.
     * Подтверждение клиента
     */
    confirmClient(clientId: string): Promise<void>;

    /**
     * Метод корректирует баланс на произвольную сумму
     * @summary Корректировка баланса
     * @param {string} accountId ID счёта
     * @param {MoneyAmountWithNote} [moneyAmountWithNote] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    correctingBalanceRaw(requestParameters: DefaultApiCorrectingBalanceRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Метод корректирует баланс на произвольную сумму
     * Корректировка баланса
     */
    correctingBalance(accountId: string, moneyAmountWithNote?: MoneyAmountWithNote): Promise<void>;

    /**
     * Договор создается только при отсутствии активного договора клиента и сразу со статусом ACTIVE
     * @summary Создание нового договора
     * @param {string} clientId Идентификатор клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createContractRaw(requestParameters: DefaultApiCreateContractRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Договор создается только при отсутствии активного договора клиента и сразу со статусом ACTIVE
     * Создание нового договора
     */
    createContract(clientId: string): Promise<void>;

    /**
     * Создание отчета с помощью конструктора
     * @summary Создание отчета
     * @param {CreateReport} [createReport] Параметры создания роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createReportRaw(requestParameters: DefaultApiCreateReportRequest): Promise<runtime.ApiResponse<Array<ReportItem>>>;

    /**
     * Создание отчета с помощью конструктора
     * Создание отчета
     */
    createReport(createReport?: CreateReport): Promise<Array<ReportItem>>;

    /**
     * Создание роли
     * @summary Создание роли
     * @param {CreateRole} [createRole] Параметры создания роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createRoleRaw(requestParameters: DefaultApiCreateRoleRequest): Promise<runtime.ApiResponse<Array<RoleItem>>>;

    /**
     * Создание роли
     * Создание роли
     */
    createRole(createRole?: CreateRole): Promise<Array<RoleItem>>;

    /**
     * Создание нового тарифа
     * @summary Создание нового тарифа
     * @param {CreateTariff} createTariff 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createTariffRaw(requestParameters: DefaultApiCreateTariffRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Создание нового тарифа
     * Создание нового тарифа
     */
    createTariff(createTariff: CreateTariff): Promise<void>;

    /**
     * Создаёт заявку на выдачу меток в ЛК клиента. Метод доступен только клиенту
     * @summary метод создания заявки на выдачу меток в ЛК клиента
     * @param {InlineObject5} [inlineObject5] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createreservlabelRaw(requestParameters: DefaultApiCreatereservlabelRequest): Promise<runtime.ApiResponse<InlineResponse2012>>;

    /**
     * Создаёт заявку на выдачу меток в ЛК клиента. Метод доступен только клиенту
     * метод создания заявки на выдачу меток в ЛК клиента
     */
    createreservlabel(inlineObject5?: InlineObject5): Promise<InlineResponse2012>;

    /**
     * создаёт заявку на выдачу меток
     * @summary метод создания заявки на выдачу меток
     * @param {InlineObject4} [inlineObject4] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    cretereservlabelRaw(requestParameters: DefaultApiCretereservlabelRequest): Promise<runtime.ApiResponse<InlineResponse2012>>;

    /**
     * создаёт заявку на выдачу меток
     * метод создания заявки на выдачу меток
     */
    cretereservlabel(inlineObject4?: InlineObject4): Promise<InlineResponse2012>;

    /**
     * Удаление отчета
     * @summary Удаление отчета
     * @param {string} reportId ID отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteReportRaw(requestParameters: DefaultApiDeleteReportRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Удаление отчета
     * Удаление отчета
     */
    deleteReport(reportId: string): Promise<void>;

    /**
     * Удаление роли
     * @summary Удаление роли
     * @param {string} roleId ID роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteRoleRaw(requestParameters: DefaultApiDeleteRoleRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Удаление роли
     * Удаление роли
     */
    deleteRole(roleId: string): Promise<void>;

    /**
     * Удаляет объект резерва меток
     * @summary резерв меток
     * @param {string} id айди объекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deletereservlabelsRaw(requestParameters: DefaultApiDeletereservlabelsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Удаляет объект резерва меток
     * резерв меток
     */
    deletereservlabels(id: string): Promise<void>;

    /**
     * Отправляет сообщение администратору
     * @summary Обратная связь
     * @param {Body} [body] Текст сообщения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    feedbackClientRaw(requestParameters: DefaultApiFeedbackClientRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Отправляет сообщение администратору
     * Обратная связь
     */
    feedbackClient(body?: Body): Promise<void>;

    /**
     * Метод возвращает ссылку на сгенерированный pdf файл
     * @summary Генерация документа из шаблона
     * @param {FileGenerate} [fileGenerate] Данные для генерации документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    generateDocumentsRaw(requestParameters: DefaultApiGenerateDocumentsRequest): Promise<runtime.ApiResponse<GenerateDocument>>;

    /**
     * Метод возвращает ссылку на сгенерированный pdf файл
     * Генерация документа из шаблона
     */
    generateDocuments(fileGenerate?: FileGenerate): Promise<GenerateDocument>;

    /**
     * Метод возвращает ссылку на сгенерированный файл
     * @summary Генерация отчета
     * @param {ReportGenerate} [reportGenerate] Данные для генерации документа
     * @param {string} [dateFrom] Дата начала периода
     * @param {string} [dateTo] Дата окончания периода
     * @param {string} [ordering] Поля для сортировки, через запятую
     * @param {ClientType} [clientType] Тип клиента
     * @param {OperationType} [operationType] Тип операции
     * @param {string} [request] Опциональная строка поиска
     * @param {string} [status] Статус операции (или метки для списка меток)
     * @param {string} [clientName] Имя клиента (название организации)
     * @param {number} [client] Идентификатор клиента
     * @param {string} [contractNumber] Номер договора
     * @param {string} [paymentMean] Номер средства оплаты, можно передать 9 цифр
     * @param {string} [licensePlate] Номер ГРЗ
     * @param {string} [tariffZone] Тарифная зона
     * @param {string} [vehicleClass] Класс машины
     * @param {string} [location] Место совершения операции
     * @param {string} [lane] Полоса проезда
     * @param {string} [sum] Сумма операции
     * @param {string} [name] Наименование метки (машины)
     * @param {string} [car] Марка и модель машины
     * @param {ClientType} [clienttype] Тип клиента (для получения списка клиентов)
     * @param {ContractStatus} [statusId] Статус договора
     * @param {string} [pan] номер метки (средства оплаты)
     * @param {string} [vehicle] название, марка, тег машины
     * @param {string} [tariffGroup] номер тарифа транспортного средства
     * @param {string} [contractId] фильтр по контракту клиента
     * @param {string} [clientId] фильтр по клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    generateReportsRaw(requestParameters: DefaultApiGenerateReportsRequest): Promise<runtime.ApiResponse<GenerateReport>>;

    /**
     * Метод возвращает ссылку на сгенерированный файл
     * Генерация отчета
     */
    generateReports(reportGenerate?: ReportGenerate, dateFrom?: string, dateTo?: string, ordering?: string, clientType?: ClientType, operationType?: OperationType, request?: string, status?: string, clientName?: string, client?: number, contractNumber?: string, paymentMean?: string, licensePlate?: string, tariffZone?: string, vehicleClass?: string, location?: string, lane?: string, sum?: string, name?: string, car?: string, clienttype?: ClientType, statusId?: ContractStatus, pan?: string, vehicle?: string, tariffGroup?: string, contractId?: string, clientId?: string): Promise<GenerateReport>;

    /**
     * Список смен
     * @param {number} offset Количество пропускаемых элементов
     * @param {number} limit Количество возвращаемых элементов
     * @param {'datetime_start' | 'datetime_end'} [ordering] Поля для сортировки, через запятую
     * @param {string} [operatorId] ID кассира
     * @param {number} [laneId] Id полосы
     * @param {string} [externalId] Внешний id смены
     * @param {'OPEN' | 'CLOSED' | 'ADMIN_CLOSED'} [status] Статус смены
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getRaw(requestParameters: DefaultApiGetRequest): Promise<runtime.ApiResponse<ShiftListItem>>;

    /**
     * Список смен
     */
    get(offset: number, limit: number, ordering?: GetOrderingEnum, operatorId?: string, laneId?: number, externalId?: string, status?: GetStatusEnum): Promise<ShiftListItem>;

    /**
     * Метод возвращает баланс счёта по его идентификатору
     * @summary Получение баланса счёта
     * @param {string} accountId ID счёта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getAccountRaw(requestParameters: DefaultApiGetAccountRequest): Promise<runtime.ApiResponse<Account>>;

    /**
     * Метод возвращает баланс счёта по его идентификатору
     * Получение баланса счёта
     */
    getAccount(accountId: string): Promise<Account>;

    /**
     * Метод возвращает список БИК с фильтрацией по БИК.
     * @summary Получение списка БИК
     * @param {string} request БИК
     * @param {number} offset Количество пропускаемых элементов
     * @param {number} limit Количество возвращаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBanksRaw(requestParameters: DefaultApiGetBanksRequest): Promise<runtime.ApiResponse<Array<Bic>>>;

    /**
     * Метод возвращает список БИК с фильтрацией по БИК.
     * Получение списка БИК
     */
    getBanks(request: string, offset: number, limit: number): Promise<Array<Bic>>;

    /**
     * Получение списка измененных меток для сервера парковки
     * @param {number} [version] Текущая версия меток на сервере. Если не передано, считается последняя отправленная
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getChangeLabelsRaw(requestParameters: DefaultApiGetChangeLabelsRequest): Promise<runtime.ApiResponse<InlineResponse20011>>;

    /**
     * Получение списка измененных меток для сервера парковки
     */
    getChangeLabels(version?: number): Promise<InlineResponse20011>;

    /**
     * Метод возвращает объект клиента по его идентификатору
     * @summary Получение клиента
     * @param {string} clientId ID клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getClientRaw(requestParameters: DefaultApiGetClientRequest): Promise<runtime.ApiResponse<Client>>;

    /**
     * Метод возвращает объект клиента по его идентификатору
     * Получение клиента
     */
    getClient(clientId: string): Promise<Client>;

    /**
     * Метод возвращает список активностей клиента по его идентификатору
     * @summary Получение активности клиента
     * @param {string} clientId ID клиента
     * @param {number} limit Количество возвращаемых элементов
     * @param {number} offset Количество пропускаемых элементов
     * @param {string} [ordering] сортировка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getClientActivityRaw(requestParameters: DefaultApiGetClientActivityRequest): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Метод возвращает список активностей клиента по его идентификатору
     * Получение активности клиента
     */
    getClientActivity(clientId: string, limit: number, offset: number, ordering?: string): Promise<InlineResponse2001>;

    /**
     * Метод возвращает список клиентов с возможностью фильтрации.
     * @summary Получение списка клиентов
     * @param {number} offset Количество пропускаемых элементов
     * @param {number} limit Количество возвращаемых элементов
     * @param {string} [request] Опциональная строка поиска
     * @param {ClientType} [clienttype] Тип клиента
     * @param {ContractStatus} [statusId] Статус договора
     * @param {string} [ordering] Сортировка по всем возвращаемым полям
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getClientsRaw(requestParameters: DefaultApiGetClientsRequest): Promise<runtime.ApiResponse<InlineResponse200>>;

    /**
     * Метод возвращает список клиентов с возможностью фильтрации.
     * Получение списка клиентов
     */
    getClients(offset: number, limit: number, request?: string, clienttype?: ClientType, statusId?: ContractStatus, ordering?: string): Promise<InlineResponse200>;

    /**
     * Метод возвращает объект договора по его идентификатору
     * @summary Получение договора
     * @param {string} contractId ID договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getContractRaw(requestParameters: DefaultApiGetContractRequest): Promise<runtime.ApiResponse<ContractItem>>;

    /**
     * Метод возвращает объект договора по его идентификатору
     * Получение договора
     */
    getContract(contractId: string): Promise<ContractItem>;

    /**
     * ТСП доступны договоры все клиентов, клиенту только его договоры
     * @summary Получение списка договоров
     * @param {string} [clientId] Фильтр по договорам клиента
     * @param {'NEW' | 'WAITING_REVIEW' | 'WAITING_USER' | 'ACTIVE' | 'SUSPENDED' | 'TERMINATING' | 'TERMINATED'} [status] Фильтр по статусу
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getContractsRaw(requestParameters: DefaultApiGetContractsRequest): Promise<runtime.ApiResponse<Array<ContractItem>>>;

    /**
     * ТСП доступны договоры все клиентов, клиенту только его договоры
     * Получение списка договоров
     */
    getContracts(clientId?: string, status?: GetContractsStatusEnum): Promise<Array<ContractItem>>;

    /**
     * Метод возвращает файл документа в бинарном виде.
     * @summary Получение файла документа
     * @param {string} fileName Имя документа
     * @param {string} clientId ID клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getDocumentRaw(requestParameters: DefaultApiGetDocumentRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Метод возвращает файл документа в бинарном виде.
     * Получение файла документа
     */
    getDocument(fileName: string, clientId: string): Promise<any>;

    /**
     * Метод возвращает список документов требуемых для клиента указанного типа. По умолчанию вернутся документы с категориями REQUIRED и OPTIONAL. Для получения шаблонов документов, нужно передать category - EXTRA
     * @summary Получение списка документов
     * @param {OrgType} [orgType] Тип клиента
     * @param {DocumentCategory} [category] Категория документа
     * @param {DocumentCode} [code] Коды шаблонов документов через запятую. Нужны только для категории EXTRA
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getDocumentsRaw(requestParameters: DefaultApiGetDocumentsRequest): Promise<runtime.ApiResponse<Array<Document>>>;

    /**
     * Метод возвращает список документов требуемых для клиента указанного типа. По умолчанию вернутся документы с категориями REQUIRED и OPTIONAL. Для получения шаблонов документов, нужно передать category - EXTRA
     * Получение списка документов
     */
    getDocuments(orgType?: OrgType, category?: DocumentCategory, code?: DocumentCode): Promise<Array<Document>>;

    /**
     * Метод возвращает список возможных пунктов меню для добавления в роль
     * @summary Получение списка пунктов меню
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMenuRaw(): Promise<runtime.ApiResponse<Array<MenuItem>>>;

    /**
     * Метод возвращает список возможных пунктов меню для добавления в роль
     * Получение списка пунктов меню
     */
    getMenu(): Promise<Array<MenuItem>>;

    /**
     * Метод возвращает объект ТСП с настройками по его ключу. Администратору ТСП доступен только его ТСП.
     * @summary Получение настроек ТСП
     * @param {string} merchantKey Уникальный ключ указывающий на организацию использующую сервис
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMerchantRaw(requestParameters: DefaultApiGetMerchantRequest): Promise<runtime.ApiResponse<Array<Merchant>>>;

    /**
     * Метод возвращает объект ТСП с настройками по его ключу. Администратору ТСП доступен только его ТСП.
     * Получение настроек ТСП
     */
    getMerchant(merchantKey: string): Promise<Array<Merchant>>;

    /**
     * Получение токена со сроком жизни неделя
     * @param {Auth} [auth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getParkingTokenRaw(requestParameters: DefaultApiGetParkingTokenRequest): Promise<runtime.ApiResponse<Tokens>>;

    /**
     * Получение токена со сроком жизни неделя
     */
    getParkingToken(auth?: Auth): Promise<Tokens>;

    /**
     * Метод возвращает список возможных прав для добавления в роль
     * @summary Получение списка доступных прав
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getPermissionsRaw(): Promise<runtime.ApiResponse<Array<PermissionItem>>>;

    /**
     * Метод возвращает список возможных прав для добавления в роль
     * Получение списка доступных прав
     */
    getPermissions(): Promise<Array<PermissionItem>>;

    /**
     * Метод возвращает пользовательский отчет
     * @summary Получение пользовательског отчета с конфигурацией
     * @param {string} reportId ID отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getReportRaw(requestParameters: DefaultApiGetReportRequest): Promise<runtime.ApiResponse<Array<ReportItem>>>;

    /**
     * Метод возвращает пользовательский отчет
     * Получение пользовательског отчета с конфигурацией
     */
    getReport(reportId: string): Promise<Array<ReportItem>>;

    /**
     * Метод возвращает список долступных срезов и метрик для создания отчета
     * @summary Получение списка срезов и метрик для отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getReportMetricsRaw(): Promise<runtime.ApiResponse<Array<RoleListItem>>>;

    /**
     * Метод возвращает список долступных срезов и метрик для создания отчета
     * Получение списка срезов и метрик для отчета
     */
    getReportMetrics(): Promise<Array<RoleListItem>>;

    /**
     * Метод возвращает список созданных пользовательских отчетов
     * @summary Получение списка пользовательских отчетов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getReportsRaw(): Promise<runtime.ApiResponse<Array<ReportListItem>>>;

    /**
     * Метод возвращает список созданных пользовательских отчетов
     * Получение списка пользовательских отчетов
     */
    getReports(): Promise<Array<ReportListItem>>;

    /**
     * Метод возвращает пользовательскую роль с настройками
     * @summary Получение пользовательской роли с настройками
     * @param {string} roleId ID роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getRoleRaw(requestParameters: DefaultApiGetRoleRequest): Promise<runtime.ApiResponse<RoleItem>>;

    /**
     * Метод возвращает пользовательскую роль с настройками
     * Получение пользовательской роли с настройками
     */
    getRole(roleId: string): Promise<RoleItem>;

    /**
     * Метод возвращает список созданных пользовательских ролей
     * @summary Получение списка пользовательских ролей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getRolesRaw(): Promise<runtime.ApiResponse<Array<RoleListItem>>>;

    /**
     * Метод возвращает список созданных пользовательских ролей
     * Получение списка пользовательских ролей
     */
    getRoles(): Promise<Array<RoleListItem>>;

    /**
     * Список смен
     * @param {number} id ID объекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getShiftRaw(requestParameters: DefaultApiGetShiftRequest): Promise<runtime.ApiResponse<ShiftItem>>;

    /**
     * Список смен
     */
    getShift(id: number): Promise<ShiftItem>;

    /**
     * Список смен по дням
     * @param {number} offset Количество пропускаемых элементов
     * @param {number} limit Количество возвращаемых элементов
     * @param {number} [id] ID дня
     * @param {Date} [day] Дата
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getShiftDaysRaw(requestParameters: DefaultApiGetShiftDaysRequest): Promise<runtime.ApiResponse<Array<ShiftDayItem>>>;

    /**
     * Список смен по дням
     */
    getShiftDays(offset: number, limit: number, id?: number, day?: Date): Promise<Array<ShiftDayItem>>;

    /**
     * Получение тарифа пор его id. ля разных типов тарифов набор полей будет отличаться.
     * @summary Получение тарифа пор его id
     * @param {number} tariffId 
     * @param {number} [version] Номер версии. По умолчанию передаются цены активной версии
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTariffByIdRaw(requestParameters: DefaultApiGetTariffByIdRequest): Promise<runtime.ApiResponse<Tariff>>;

    /**
     * Получение тарифа пор его id. ля разных типов тарифов набор полей будет отличаться.
     * Получение тарифа пор его id
     */
    getTariffById(tariffId: number, version?: number): Promise<Tariff>;

    /**
     * Получение списка тарифов
     * @summary Получение списка тарифов
     * @param {number} offset Количество пропускаемых элементов
     * @param {number} limit Количество возвращаемых элементов
     * @param {string} [dateFrom] Дата начала периода
     * @param {string} [dateTo] Дата окончания периода
     * @param {string} [ordering] Поля для сортировки, через запятую
     * @param {string} [search] По названию тарифа, статусу тарифа, типу тарифа
     * @param {TariffStatus} [tariffStatus] Статус тарифа
     * @param {string} [name] Название тарифа
     * @param {'TARIFF_NK' | 'FREE' | 'DEFAULT_RFID' | 'REGULAR' | 'PARKING_SUBSCRIPTION'} [tariffType] Тип тарифа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTariffsRaw(requestParameters: DefaultApiGetTariffsRequest): Promise<runtime.ApiResponse<InlineResponse20010>>;

    /**
     * Получение списка тарифов
     * Получение списка тарифов
     */
    getTariffs(offset: number, limit: number, dateFrom?: string, dateTo?: string, ordering?: string, search?: string, tariffStatus?: TariffStatus, name?: string, tariffType?: GetTariffsTariffTypeEnum): Promise<InlineResponse20010>;

    /**
     * Получение токена со сроком жизни неделя
     * @param {Auth} [auth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTokenRaw(requestParameters: DefaultApiGetTokenRequest): Promise<runtime.ApiResponse<Tokens>>;

    /**
     * Получение токена со сроком жизни неделя
     */
    getToken(auth?: Auth): Promise<Tokens>;

    /**
     * Метод возвращает объект пользователя по его идентификатору и ключу организации
     * @summary Получение пользователя
     * @param {string} userId ID пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getUserRaw(requestParameters: DefaultApiGetUserRequest): Promise<runtime.ApiResponse<UserInfo>>;

    /**
     * Метод возвращает объект пользователя по его идентификатору и ключу организации
     * Получение пользователя
     */
    getUser(userId: string): Promise<UserInfo>;

    /**
     * Метод возвращает список пользователя с возможностью ограничения по типу клиента и ключу организации.
     * @summary Получение списка пользователей
     * @param {string} merchantKey Уникальный ключ указывающий на организацию использующую сервис
     * @param {number} offset Количество пропускаемых элементов
     * @param {number} limit Количество возвращаемых элементов
     * @param {OrgType} [orgType] Тип клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getUsersRaw(requestParameters: DefaultApiGetUsersRequest): Promise<runtime.ApiResponse<Array<UserInfo>>>;

    /**
     * Метод возвращает список пользователя с возможностью ограничения по типу клиента и ключу организации.
     * Получение списка пользователей
     */
    getUsers(merchantKey: string, offset: number, limit: number, orgType?: OrgType): Promise<Array<UserInfo>>;

    /**
     * Получение сведений о клиенте по номеру метки или ГРЗ
     * @param {string} search Номер метки или ГРЗ. Сейчас ищет совпадение, возможно нужно вхождение
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    infoForParkomatRaw(requestParameters: DefaultApiInfoForParkomatRequest): Promise<runtime.ApiResponse<ParkingInfo>>;

    /**
     * Получение сведений о клиенте по номеру метки или ГРЗ
     */
    infoForParkomat(search: string): Promise<ParkingInfo>;

    /**
     * Карточка заявки
     * @summary резерв меток
     * @param {string} id айди объекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inforeservlabelsRaw(requestParameters: DefaultApiInforeservlabelsRequest): Promise<runtime.ApiResponse<Reservlabel>>;

    /**
     * Карточка заявки
     * резерв меток
     */
    inforeservlabels(id: string): Promise<Reservlabel>;

    /**
     * Доступно ролям - администратор ТСП
     * @summary Создание клиента
     * @param {ClientWithUser} [clientWithUser] Данные клиента и пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    instantCreateClientRaw(requestParameters: DefaultApiInstantCreateClientRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Доступно ролям - администратор ТСП
     * Создание клиента
     */
    instantCreateClient(clientWithUser?: ClientWithUser): Promise<void>;

    /**
     * Доступно ролям - администратор ТСП
     * @summary Изменение клиента
     * @param {string} clientId ID клиента
     * @param {ClientWithUser} [clientWithUser] Данные клиента и пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    instantUpdateClientRaw(requestParameters: DefaultApiInstantUpdateClientRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Доступно ролям - администратор ТСП
     * Изменение клиента
     */
    instantUpdateClient(clientId: string, clientWithUser?: ClientWithUser): Promise<void>;

    /**
     * Метод списывает со счета клиента стоимость метки
     * @summary Выдача метки (списание баланса)
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    issueALabelRaw(requestParameters: DefaultApiIssueALabelRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Метод списывает со счета клиента стоимость метки
     * Выдача метки (списание баланса)
     */
    issueALabel(inlineObject3?: InlineObject3): Promise<void>;

    /**
     * Выдача меток из заяки
     * @summary Активирует метки из заявки
     * @param {string} id айди объекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    issueLabelsRaw(requestParameters: DefaultApiIssueLabelsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Выдача меток из заяки
     * Активирует метки из заявки
     */
    issueLabels(id: string): Promise<void>;

    /**
     * Получает объект метки
     * @summary получение истории метки по переданому id
     * @param {string} id ID метки
     * @param {number} limit Количество возвращаемых элементов
     * @param {number} offset Количество пропускаемых элементов
     * @param {string} [ordering] сортировка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    labelHistoryRaw(requestParameters: DefaultApiLabelHistoryRequest): Promise<runtime.ApiResponse<InlineResponse2002>>;

    /**
     * Получает объект метки
     * получение истории метки по переданому id
     */
    labelHistory(id: string, limit: number, offset: number, ordering?: string): Promise<InlineResponse2002>;

    /**
     * создать метку.
     * @summary создать метку.
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    labelcreateRaw(requestParameters: DefaultApiLabelcreateRequest): Promise<runtime.ApiResponse<InlineResponse201>>;

    /**
     * создать метку.
     * создать метку.
     */
    labelcreate(inlineObject?: InlineObject): Promise<InlineResponse201>;

    /**
     * Получает историю метки.
     * @summary Получает историю метки по переданому pan
     * @param {number} limit Количество возвращаемых элементов
     * @param {number} offset Количество пропускаемых элементов
     * @param {string} pan Уникальный идентификатор метки
     * @param {string} [ordering] сортировка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    labelhistoryRaw(requestParameters: DefaultApiLabelhistoryRequest): Promise<runtime.ApiResponse<InlineResponse2003>>;

    /**
     * Получает историю метки.
     * Получает историю метки по переданому pan
     */
    labelhistory(limit: number, offset: number, pan: string, ordering?: string): Promise<InlineResponse2003>;

    /**
     * Получает объект метки
     * @summary получение объекта метки по переданому id
     * @param {string} id ID метки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    labelinfoRaw(requestParameters: DefaultApiLabelinfoRequest): Promise<runtime.ApiResponse<Label>>;

    /**
     * Получает объект метки
     * получение объекта метки по переданому id
     */
    labelinfo(id: string): Promise<Label>;

    /**
     * Возвращает список меток
     * @summary Список меток
     * @param {number} limit Количество возвращаемых элементов
     * @param {number} offset Количество пропускаемых элементов
     * @param {string} [dateFrom] Дата начала периода
     * @param {string} [dateTo] Дата окончания периода
     * @param {string} [pan] номер метки (средства оплаты)
     * @param {string} [clientName] имя клиента
     * @param {string} [contractNumber] номер контракта в база
     * @param {string} [licensePlate] номер машины
     * @param {string} [vehicle] название, марка, тег машины
     * @param {string} [tariffGroup] номер тарифа транспортного средства
     * @param {string} [ordering] сортировка таблицы по всем возвращаемым полям
     * @param {string} [contractId] фильтр по контракту клиента
     * @param {string} [clientId] фильтр по клиенту
     * @param {LabelStatus} [status] фильтр по статусу метки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    labelsListRaw(requestParameters: DefaultApiLabelsListRequest): Promise<runtime.ApiResponse<Label>>;

    /**
     * Возвращает список меток
     * Список меток
     */
    labelsList(limit: number, offset: number, dateFrom?: string, dateTo?: string, pan?: string, clientName?: string, contractNumber?: string, licensePlate?: string, vehicle?: string, tariffGroup?: string, ordering?: string, contractId?: string, clientId?: string, status?: LabelStatus): Promise<Label>;

    /**
     * Создание объектов меток из файла csv или списка с номерами меток.
     * @summary Метод сохраняет метки из файла csv
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    labelscreateobjectsRaw(requestParameters: DefaultApiLabelscreateobjectsRequest): Promise<runtime.ApiResponse<InlineResponse2011>>;

    /**
     * Создание объектов меток из файла csv или списка с номерами меток.
     * Метод сохраняет метки из файла csv
     */
    labelscreateobjects(inlineObject2?: InlineObject2): Promise<InlineResponse2011>;

    /**
     * Обновляет поля объекта метки status, category, contract, merchant
     * @summary Обновление метки
     * @param {string} id ID метки
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    labelupdateRaw(requestParameters: DefaultApiLabelupdateRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Обновляет поля объекта метки status, category, contract, merchant
     * Обновление метки
     */
    labelupdate(id: string, inlineObject1?: InlineObject1): Promise<void>;

    /**
     * Метод для входа пользователей в систему. Вход возможен с парой телефон/email и пароль в закодированном формате. Возвращает JWT токен, информацию о пользователе и устанавливает Refresh токен в Cookie
     * @summary Вход пользователя
     * @param {UserLogin} [userLogin] Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    loginUserRaw(requestParameters: DefaultApiLoginUserRequest): Promise<runtime.ApiResponse<AuthInfo>>;

    /**
     * Метод для входа пользователей в систему. Вход возможен с парой телефон/email и пароль в закодированном формате. Возвращает JWT токен, информацию о пользователе и устанавливает Refresh токен в Cookie
     * Вход пользователя
     */
    loginUser(userLogin?: UserLogin): Promise<AuthInfo>;

    /**
     * Заполняет или изменяет настройки ТСП
     * @summary Метод сохраняет настройки ТСП
     * @param {string} merchantKey Уникальный ключ указывающий на организацию использующую сервис
     * @param {Merchant} [merchant] Настройки ТСП
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    marchantsSaveDetailsRaw(requestParameters: DefaultApiMarchantsSaveDetailsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Заполняет или изменяет настройки ТСП
     * Метод сохраняет настройки ТСП
     */
    marchantsSaveDetails(merchantKey: string, merchant?: Merchant): Promise<void>;

    /**
     * Возвращает список ТСП. Доступен только оператору.
     * @summary Список администраторов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    merchantsListRaw(): Promise<runtime.ApiResponse<Array<MerchantListItem>>>;

    /**
     * Возвращает список ТСП. Доступен только оператору.
     * Список администраторов
     */
    merchantsList(): Promise<Array<MerchantListItem>>;

    /**
     * Открытие смены
     * @param {OpenShift} [openShift] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    openShiftRaw(requestParameters: DefaultApiOpenShiftRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Открытие смены
     */
    openShift(openShift?: OpenShift): Promise<void>;

    /**
     * Возврат операции
     * @summary Возврат операции
     * @param {string} operationId ID операции
     * @param {InlineObject14} [inlineObject14] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    operationCorrectedRaw(requestParameters: DefaultApiOperationCorrectedRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Возврат операции
     * Возврат операции
     */
    operationCorrected(operationId: string, inlineObject14?: InlineObject14): Promise<void>;

    /**
     * Создает операцию поездки
     * @summary Создание поездки
     * @param {CreateJourney} [createJourney] Параметры создания поездки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    operationJourneyRaw(requestParameters: DefaultApiOperationJourneyRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Создает операцию поездки
     * Создание поездки
     */
    operationJourney(createJourney?: CreateJourney): Promise<void>;

    /**
     * Возврат операции
     * @summary Возврат операции
     * @param {string} operationId ID операции
     * @param {InlineObject13} [inlineObject13] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    operationRollbackRaw(requestParameters: DefaultApiOperationRollbackRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Возврат операции
     * Возврат операции
     */
    operationRollback(operationId: string, inlineObject13?: InlineObject13): Promise<void>;

    /**
     * Изменяет флаг подозрительной операции is_suspected
     * @summary Изменение операции
     * @param {string} operationId ID операции
     * @param {InlineObject12} [inlineObject12] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    operationUpdateRaw(requestParameters: DefaultApiOperationUpdateRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Изменяет флаг подозрительной операции is_suspected
     * Изменение операции
     */
    operationUpdate(operationId: string, inlineObject12?: InlineObject12): Promise<void>;

    /**
     * Подробная карточка операции
     * @summary Информация об операции
     * @param {string} operationId ID операции
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    operationViewRaw(requestParameters: DefaultApiOperationViewRequest): Promise<runtime.ApiResponse<Operation>>;

    /**
     * Подробная карточка операции
     * Информация об операции
     */
    operationView(operationId: string): Promise<Operation>;

    /**
     * Проезды, оплаты связанные с метками
     * @summary Список операций
     * @param {number} offset Количество пропускаемых элементов
     * @param {number} limit Количество возвращаемых элементов
     * @param {string} [dateFrom] Дата начала периода
     * @param {string} [dateTo] Дата окончания периода
     * @param {string} [ordering] Поля для сортировки, через запятую
     * @param {ClientType} [clientType] Тип клиента
     * @param {OperationType} [operationType] Тип операции
     * @param {JourneyType} [journeyType] Тип проезда
     * @param {string} [request] Опциональная строка поиска
     * @param {OperationStatus} [status] Статус операции
     * @param {string} [clientName] Имя клиента (название организации)
     * @param {number} [client] Идентификатор клиента
     * @param {string} [contractNumber] Номер договора
     * @param {string} [paymentMean] Номер средства оплаты, можно передать 9 цифр
     * @param {string} [licensePlate] Номер ГРЗ
     * @param {string} [tariffZone] Тарифная зона
     * @param {string} [vehicleClass] Класс машины
     * @param {string} [location] Место совершения операции
     * @param {string} [lane] Полоса проезда
     * @param {string} [sum] Сумма операции
     * @param {string} [name] Наименование метки (машины)
     * @param {string} [car] Марка и модель машины
     * @param {number} [shiftId] Номер смены
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    operationsListRaw(requestParameters: DefaultApiOperationsListRequest): Promise<runtime.ApiResponse<InlineResponse2009>>;

    /**
     * Проезды, оплаты связанные с метками
     * Список операций
     */
    operationsList(offset: number, limit: number, dateFrom?: string, dateTo?: string, ordering?: string, clientType?: ClientType, operationType?: OperationType, journeyType?: JourneyType, request?: string, status?: OperationStatus, clientName?: string, client?: number, contractNumber?: string, paymentMean?: string, licensePlate?: string, tariffZone?: string, vehicleClass?: string, location?: string, lane?: string, sum?: string, name?: string, car?: string, shiftId?: number): Promise<InlineResponse2009>;

    /**
     * Разрешает выезд с полосы
     * @param {ParkingExit} [parkingExit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    parkingExitRaw(requestParameters: DefaultApiParkingExitRequest): Promise<runtime.ApiResponse<ParkingExitResponse>>;

    /**
     * Разрешает выезд с полосы
     */
    parkingExit(parkingExit?: ParkingExit): Promise<ParkingExitResponse>;

    /**
     * Загрузка операций парковки с сервера в БСТР
     * @param {ParkingOperations} [parkingOperations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    parkingOperationsRaw(requestParameters: DefaultApiParkingOperationsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Загрузка операций парковки с сервера в БСТР
     */
    parkingOperations(parkingOperations?: ParkingOperations): Promise<void>;

    /**
     * Метод сохраняет документ определенного типа в привязке к клиенту.
     * @summary Сохранение документа
     * @param {FileCreate} [fileCreate] Данные документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postDocumentsRaw(requestParameters: DefaultApiPostDocumentsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Метод сохраняет документ определенного типа в привязке к клиенту.
     * Сохранение документа
     */
    postDocuments(fileCreate?: FileCreate): Promise<void>;

    /**
     * Метод сохраняет или обновляет шаблон дополнительного документа для клиента определенного типа
     * @summary Сохранение шаблона документа
     * @param {FileExtraCreate} [fileExtraCreate] Данные шаблона документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postDocumentsExtraRaw(requestParameters: DefaultApiPostDocumentsExtraRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Метод сохраняет или обновляет шаблон дополнительного документа для клиента определенного типа
     * Сохранение шаблона документа
     */
    postDocumentsExtra(fileExtraCreate?: FileExtraCreate): Promise<void>;

    /**
     * Входным параметром передается ID клиента.
     * @summary Изменение клиента
     * @param {string} clientId ID клиента
     * @param {ClientIndividual | ClientSP | ClientCompany} [clientIndividualClientSPClientCompany] Данные клиента, в зависимости от типа клиента отличаются
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    putClientRaw(requestParameters: DefaultApiPutClientRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Входным параметром передается ID клиента.
     * Изменение клиента
     */
    putClient(clientId: string, clientIndividualClientSPClientCompany?: ClientIndividual | ClientSP | ClientCompany): Promise<void>;

    /**
     * Входным параметром передается ID пользователя из JWT токена. В случае изменения телефона или email необходимо высылать уникальный код подтверждения на соотвествующий контакт.
     * @summary Изменение пользователя
     * @param {string} userId ID пользователя
     * @param {UserBasic} [userBasic] Данные пользователя для обновления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    putUserRaw(requestParameters: DefaultApiPutUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Входным параметром передается ID пользователя из JWT токена. В случае изменения телефона или email необходимо высылать уникальный код подтверждения на соотвествующий контакт.
     * Изменение пользователя
     */
    putUser(userId: string, userBasic?: UserBasic): Promise<void>;

    /**
     * Получение списка меток для полосы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    receiveLabelsListRaw(): Promise<runtime.ApiResponse<Array<ControllerLabel>>>;

    /**
     * Получение списка меток для полосы
     */
    receiveLabelsList(): Promise<Array<ControllerLabel>>;

    /**
     * Загрузка операций проезда полосы
     * @param {ReceiveTransactionList} [receiveTransactionList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    receiveTransactionListRaw(requestParameters: DefaultApiReceiveTransactionListRequest): Promise<runtime.ApiResponse<InteroperabilityResponse>>;

    /**
     * Загрузка операций проезда полосы
     */
    receiveTransactionList(receiveTransactionList?: ReceiveTransactionList): Promise<InteroperabilityResponse>;

    /**
     * Метод для обновления JWT токена в случае его невалидности (вернулся код 401). Refresh токен хранится в недоступной для клиента cookie и доступен для сервера при запросах. Возвращает валидный новый JWT токен, информацию о пользователе и обновляет Refresh токен.
     * @summary Обновление JWT токена
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    refreshTokenUserRaw(): Promise<runtime.ApiResponse<AuthInfo>>;

    /**
     * Метод для обновления JWT токена в случае его невалидности (вернулся код 401). Refresh токен хранится в недоступной для клиента cookie и доступен для сервера при запросах. Возвращает валидный новый JWT токен, информацию о пользователе и обновляет Refresh токен.
     * Обновление JWT токена
     */
    refreshTokenUser(): Promise<AuthInfo>;

    /**
     * Метод создаёт пользователя в системе, привязывая к нему роль.
     * @summary Создание юзера c пользовательской ролью
     * @param {UserCustomRole} userCustomRole 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    registerCustomUserRaw(requestParameters: DefaultApiRegisterCustomUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Метод создаёт пользователя в системе, привязывая к нему роль.
     * Создание юзера c пользовательской ролью
     */
    registerCustomUser(userCustomRole: UserCustomRole): Promise<void>;

    /**
     * Метод создаёт пользователя в системе
     * @summary Создание пользователя
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    registerUserRaw(requestParameters: DefaultApiRegisterUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Метод создаёт пользователя в системе
     * Создание пользователя
     */
    registerUser(userCreate: UserCreate): Promise<void>;

    /**
     * Метод создаёт пользователя в системе сразу, без валидации контактов. Предназначено для создания клиентов через административный интерфейс ТСП
     * @summary Создание пользователя без валидации контактов
     * @param {UserBasicWithClientType} userBasicWithClientType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    registerUserInstantRaw(requestParameters: DefaultApiRegisterUserInstantRequest): Promise<runtime.ApiResponse<UserInfo>>;

    /**
     * Метод создаёт пользователя в системе сразу, без валидации контактов. Предназначено для создания клиентов через административный интерфейс ТСП
     * Создание пользователя без валидации контактов
     */
    registerUserInstant(userBasicWithClientType: UserBasicWithClientType): Promise<UserInfo>;

    /**
     * Входным параметром передается ID клиента.
     * @summary Отклонение клиента
     * @param {string} clientId ID клиента
     * @param {Message} [message] Сообщение с сервера
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    rejectClientRaw(requestParameters: DefaultApiRejectClientRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Входным параметром передается ID клиента.
     * Отклонение клиента
     */
    rejectClient(clientId: string, message?: Message): Promise<void>;

    /**
     * Метод пополняет баланс счета на указанную сумму
     * @summary Пополнение баланса счета
     * @param {string} accountId ID счёта
     * @param {MoneyAmount} [moneyAmount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    replenishmentAccountRaw(requestParameters: DefaultApiReplenishmentAccountRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Метод пополняет баланс счета на указанную сумму
     * Пополнение баланса счета
     */
    replenishmentAccount(accountId: string, moneyAmount?: MoneyAmount): Promise<void>;

    /**
     * Пополнение баланса клиента с паркомата
     * @param {ParkingReplenishment} [parkingReplenishment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    replenishmentFromParkingRaw(requestParameters: DefaultApiReplenishmentFromParkingRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Пополнение баланса клиента с паркомата
     */
    replenishmentFromParking(parkingReplenishment?: ParkingReplenishment): Promise<void>;

    /**
     * Возвращает список заявок на резерв меток
     * @summary Список меток
     * @param {number} limit Количество возвращаемых элементов
     * @param {number} offset Количество пропускаемых элементов
     * @param {string} [search] поиск по полям
     * @param {string} [ordering] сортировака по полям created, status, model_car, license_plate, name
     * @param {string} [clientId] поиск по client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    reservlabelsListRaw(requestParameters: DefaultApiReservlabelsListRequest): Promise<runtime.ApiResponse<InlineResponse2004>>;

    /**
     * Возвращает список заявок на резерв меток
     * Список меток
     */
    reservlabelsList(limit: number, offset: number, search?: string, ordering?: string, clientId?: string): Promise<InlineResponse2004>;

    /**
     * Метод для разлогинивания пользователя на стороне сервера (удаление Refresh токена).
     * @summary Отзыв JWT токена
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    revokeTokenUserRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Метод для разлогинивания пользователя на стороне сервера (удаление Refresh токена).
     * Отзыв JWT токена
     */
    revokeTokenUser(): Promise<void>;

    /**
     * Отправка кода для подтверждения контакта при регистрации или для восстановления пароля
     * @summary Отправка кода валидации для контакта пользователя
     * @param {UserSendValidationCode} [userSendValidationCode] Данные для валидации контакта пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    sendValidationCodeRaw(requestParameters: DefaultApiSendValidationCodeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Отправка кода для подтверждения контакта при регистрации или для восстановления пароля
     * Отправка кода валидации для контакта пользователя
     */
    sendValidationCode(userSendValidationCode?: UserSendValidationCode): Promise<void>;

    /**
     * Привязывает метки к заявке
     * @summary Связь меток с машинами из заявки, сформированной клиентом
     * @param {string} id айди объекта
     * @param {InlineObject7} [inlineObject7] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    setLabelsToReservlabelRaw(requestParameters: DefaultApiSetLabelsToReservlabelRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Привязывает метки к заявке
     * Связь меток с машинами из заявки, сформированной клиентом
     */
    setLabelsToReservlabel(id: string, inlineObject7?: InlineObject7): Promise<void>;

    /**
     * Изменить пароль текущему пользователю
     * @summary Изменить пароль текущему пользователю
     * @param {InlineObject15} [inlineObject15] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    setUserPasswordRaw(requestParameters: DefaultApiSetUserPasswordRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Изменить пароль текущему пользователю
     * Изменить пароль текущему пользователю
     */
    setUserPassword(inlineObject15?: InlineObject15): Promise<void>;

    /**
     * Метод устанавливает стоимость выдаваемой ТСП метки
     * @summary Установка стоимости метки
     * @param {MoneyAmount} [moneyAmount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    setlLabelCostRaw(requestParameters: DefaultApiSetlLabelCostRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Метод устанавливает стоимость выдаваемой ТСП метки
     * Установка стоимости метки
     */
    setlLabelCost(moneyAmount?: MoneyAmount): Promise<void>;

    /**
     * Изменение роли
     * @summary Изменение роли
     * @param {string} roleId ID роли
     * @param {UpdateRole} [updateRole] Параметры изменения роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateRoleRaw(requestParameters: DefaultApiUpdateRoleRequest): Promise<runtime.ApiResponse<Array<RoleItem>>>;

    /**
     * Изменение роли
     * Изменение роли
     */
    updateRole(roleId: string, updateRole?: UpdateRole): Promise<Array<RoleItem>>;

    /**
     * Изменение статуса договора и привязка его к индивидуальному тарифу
     * @summary Обновление договора
     * @param {string} contractId ID договора
     * @param {ContractUpdate} [contractUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateTariffRaw(requestParameters: DefaultApiUpdateTariffRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Изменение статуса договора и привязка его к индивидуальному тарифу
     * Обновление договора
     */
    updateTariff(contractId: string, contractUpdate?: ContractUpdate): Promise<void>;

    /**
     * Обновление тарифа по его id. ля разных типов тарифов набор полей будет отличаться.
     * @summary Редактирование тарифа
     * @param {UpdateTariff} updateTariff 
     * @param {number} tariffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateTariffByIdRaw(requestParameters: DefaultApiUpdateTariffByIdRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Обновление тарифа по его id. ля разных типов тарифов набор полей будет отличаться.
     * Редактирование тарифа
     */
    updateTariffById(updateTariff: UpdateTariff, tariffId: number): Promise<void>;

    /**
     * Ручная активация или архивирование тарифа
     * @summary Изменение статуса тарифа
     * @param {TariffStatus} tariffStatus 
     * @param {number} tariffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateTariffStatusRaw(requestParameters: DefaultApiUpdateTariffStatusRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Ручная активация или архивирование тарифа
     * Изменение статуса тарифа
     */
    updateTariffStatus(tariffStatus: TariffStatus, tariffId: number): Promise<void>;

    /**
     * Обновляет ТС
     * @summary Обновляет ТС
     * @param {string} id айди объекта
     * @param {InlineObject8} [inlineObject8] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateVehiclesRaw(requestParameters: DefaultApiUpdateVehiclesRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Обновляет ТС
     * Обновляет ТС
     */
    updateVehicles(id: string, inlineObject8?: InlineObject8): Promise<void>;

    /**
     * Обновляет Удаляет объект резерва меток
     * @summary Обновление резерв меток
     * @param {string} id айди объекта
     * @param {InlineObject6} [inlineObject6] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updatereservlabelsRaw(requestParameters: DefaultApiUpdatereservlabelsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Обновляет Удаляет объект резерва меток
     * Обновление резерв меток
     */
    updatereservlabels(id: string, inlineObject6?: InlineObject6): Promise<void>;

    /**
     * Валидация контакта при регистрации или восстановлении пароля. Возвращает JWT токен, данные пользователя и устанавливает Refresh токен в Cookie
     * @summary Валидация контакта пользователя
     * @param {UserValidateContact} [userValidateContact] Данные для валидации контакта пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    validateUserContactRaw(requestParameters: DefaultApiValidateUserContactRequest): Promise<runtime.ApiResponse<AuthInfo>>;

    /**
     * Валидация контакта при регистрации или восстановлении пароля. Возвращает JWT токен, данные пользователя и устанавливает Refresh токен в Cookie
     * Валидация контакта пользователя
     */
    validateUserContact(userValidateContact?: UserValidateContact): Promise<AuthInfo>;

    /**
     * Карточка TC
     * @summary Карточка TC
     * @param {string} id айди объекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    vehicleItemRaw(requestParameters: DefaultApiVehicleItemRequest): Promise<runtime.ApiResponse<VehicleItem>>;

    /**
     * Карточка TC
     * Карточка TC
     */
    vehicleItem(id: string): Promise<VehicleItem>;

    /**
     * Возвращает список машин. Админу доступны все ТС, клиенту только свои
     * @summary Список машин
     * @param {number} limit Количество возвращаемых элементов
     * @param {number} offset Количество пропускаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    vehiclesListRaw(requestParameters: DefaultApiVehiclesListRequest): Promise<runtime.ApiResponse<InlineResponse2005>>;

    /**
     * Возвращает список машин. Админу доступны все ТС, клиенту только свои
     * Список машин
     */
    vehiclesList(limit: number, offset: number): Promise<InlineResponse2005>;

}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI implements DefaultApiInterface {

    /**
     * Загрузка штрих-кода в базу
     */
    async addBarcodeRaw(requestParameters: DefaultApiAddBarcodeRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interoperability/barcode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.addBarCode,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Загрузка штрих-кода в базу
     */
    async addBarcode(addBarCode?: AddBarCode): Promise<void> {
        await this.addBarcodeRaw({ addBarCode: addBarCode });
    }

    /**
     * Загрузка операции проезда полосы
     */
    async addTransactionRaw(requestParameters: DefaultApiAddTransactionRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interoperability/transaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.addJourneyTransaction,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Загрузка операции проезда полосы
     */
    async addTransaction(addJourneyTransaction?: AddJourneyTransaction): Promise<void> {
        await this.addTransactionRaw({ addJourneyTransaction: addJourneyTransaction });
    }

    /**
     * файл для парсера банковских операций  возвращает объект с данными банковских платежей
     * отправляет файл с банковскими операциями
     */
    async bankfileRaw(requestParameters: DefaultApiBankfileRequest): Promise<runtime.ApiResponse<InlineResponse2007>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject9,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * файл для парсера банковских операций  возвращает объект с данными банковских платежей
     * отправляет файл с банковскими операциями
     */
    async bankfile(inlineObject9?: InlineObject9): Promise<InlineResponse2007> {
        const response = await this.bankfileRaw({ inlineObject9: inlineObject9 });
        return await response.value();
    }

    /**
     * Проводит все обработанные платежи
     * Зачисление банковских платежей
     */
    async bankpaymentsConfirmRaw(requestParameters: DefaultApiBankpaymentsConfirmRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/confirm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject11,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Проводит все обработанные платежи
     * Зачисление банковских платежей
     */
    async bankpaymentsConfirm(inlineObject11?: InlineObject11): Promise<void> {
        await this.bankpaymentsConfirmRaw({ inlineObject11: inlineObject11 });
    }

    /**
     * Получает объект банковской операции.
     * получение объекта банковской операции переданому id
     */
    async bankpaymentsinfoRaw(requestParameters: DefaultApiBankpaymentsinfoRequest): Promise<runtime.ApiResponse<InlineResponse2008>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bankpaymentsinfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получает объект банковской операции.
     * получение объекта банковской операции переданому id
     */
    async bankpaymentsinfo(id: string): Promise<InlineResponse2008> {
        const response = await this.bankpaymentsinfoRaw({ id: id });
        return await response.value();
    }

    /**
     * список временных файлов банковских операций
     * список банковских операций
     */
    async bankpaymentslistRaw(requestParameters: DefaultApiBankpaymentslistRequest): Promise<runtime.ApiResponse<Array<InlineResponse2006>>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling bankpaymentslist.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling bankpaymentslist.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.hashId !== undefined) {
            queryParameters['hash_id'] = requestParameters.hashId;
        }

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.num !== undefined) {
            queryParameters['num'] = requestParameters.num;
        }

        if (requestParameters.payersName !== undefined) {
            queryParameters['payers_name'] = requestParameters.payersName;
        }

        if (requestParameters.reason !== undefined) {
            queryParameters['reason'] = requestParameters.reason;
        }

        if (requestParameters.bankBik !== undefined) {
            queryParameters['bank_bik'] = requestParameters.bankBik;
        }

        if (requestParameters.bank !== undefined) {
            queryParameters['bank'] = requestParameters.bank;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * список временных файлов банковских операций
     * список банковских операций
     */
    async bankpaymentslist(limit: number, offset: number, dateFrom?: string, dateTo?: string, hashId?: string, amount?: string, num?: string, payersName?: string, reason?: string, bankBik?: string, bank?: string, status?: string, ordering?: string): Promise<Array<InlineResponse2006>> {
        const response = await this.bankpaymentslistRaw({ limit: limit, offset: offset, dateFrom: dateFrom, dateTo: dateTo, hashId: hashId, amount: amount, num: num, payersName: payersName, reason: reason, bankBik: bankBik, bank: bank, status: status, ordering: ordering });
        return await response.value();
    }

    /**
     * Обновляет статус объекта банковского платежа, привязывает к договору
     * изменение банковского платежа
     */
    async bankpaymentsupdateRaw(requestParameters: DefaultApiBankpaymentsupdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bankpaymentsupdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject10,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновляет статус объекта банковского платежа, привязывает к договору
     * изменение банковского платежа
     */
    async bankpaymentsupdate(id: string, inlineObject10?: InlineObject10): Promise<void> {
        await this.bankpaymentsupdateRaw({ id: id, inlineObject10: inlineObject10 });
    }

    /**
     * Изменить пароль со старого на новый текущему пользователю
     * Изменить пароль со старого на новый
     */
    async changeUserPasswordRaw(requestParameters: DefaultApiChangeUserPasswordRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject16,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Изменить пароль со старого на новый текущему пользователю
     * Изменить пароль со старого на новый
     */
    async changeUserPassword(inlineObject16?: InlineObject16): Promise<void> {
        await this.changeUserPasswordRaw({ inlineObject16: inlineObject16 });
    }

    /**
     * Проверка штрих-кода
     */
    async checkBarcodeRaw(requestParameters: DefaultApiCheckBarcodeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling checkBarcode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interoperability/barcode`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Проверка штрих-кода
     */
    async checkBarcode(code: string): Promise<void> {
        await this.checkBarcodeRaw({ code: code });
    }

    /**
     * Проверяет уникальность ГРЗ. В случае существания, возвращает 409 и массив с информацией.
     * Проверяет уникальность ГРЗ
     */
    async checkLicensePlateRaw(requestParameters: DefaultApiCheckLicensePlateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.licensePlate === null || requestParameters.licensePlate === undefined) {
            throw new runtime.RequiredError('licensePlate','Required parameter requestParameters.licensePlate was null or undefined when calling checkLicensePlate.');
        }

        const queryParameters: any = {};

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license_plate'] = requestParameters.licensePlate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/check-license-plate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Проверяет уникальность ГРЗ. В случае существания, возвращает 409 и массив с информацией.
     * Проверяет уникальность ГРЗ
     */
    async checkLicensePlate(licensePlate: string): Promise<void> {
        await this.checkLicensePlateRaw({ licensePlate: licensePlate });
    }

    /**
     * Закрытие смены
     */
    async closeShiftRaw(requestParameters: DefaultApiCloseShiftRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interoperability/shifts/close`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.closeShift,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Закрытие смены
     */
    async closeShift(closeShift?: CloseShift): Promise<void> {
        await this.closeShiftRaw({ closeShift: closeShift });
    }

    /**
     * Входным параметром передается ID клиента.
     * Подтверждение клиента
     */
    async confirmClientRaw(requestParameters: DefaultApiConfirmClientRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling confirmClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clients/{client_id}/confirm`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Входным параметром передается ID клиента.
     * Подтверждение клиента
     */
    async confirmClient(clientId: string): Promise<void> {
        await this.confirmClientRaw({ clientId: clientId });
    }

    /**
     * Метод корректирует баланс на произвольную сумму
     * Корректировка баланса
     */
    async correctingBalanceRaw(requestParameters: DefaultApiCorrectingBalanceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
            throw new runtime.RequiredError('accountId','Required parameter requestParameters.accountId was null or undefined when calling correctingBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{account_id}/correcting`.replace(`{${"account_id"}}`, encodeURIComponent(String(requestParameters.accountId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.moneyAmountWithNote,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод корректирует баланс на произвольную сумму
     * Корректировка баланса
     */
    async correctingBalance(accountId: string, moneyAmountWithNote?: MoneyAmountWithNote): Promise<void> {
        await this.correctingBalanceRaw({ accountId: accountId, moneyAmountWithNote: moneyAmountWithNote });
    }

    /**
     * Договор создается только при отсутствии активного договора клиента и сразу со статусом ACTIVE
     * Создание нового договора
     */
    async createContractRaw(requestParameters: DefaultApiCreateContractRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling createContract.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contracts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Договор создается только при отсутствии активного договора клиента и сразу со статусом ACTIVE
     * Создание нового договора
     */
    async createContract(clientId: string): Promise<void> {
        await this.createContractRaw({ clientId: clientId });
    }

    /**
     * Создание отчета с помощью конструктора
     * Создание отчета
     */
    async createReportRaw(requestParameters: DefaultApiCreateReportRequest): Promise<runtime.ApiResponse<Array<ReportItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createReport,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Создание отчета с помощью конструктора
     * Создание отчета
     */
    async createReport(createReport?: CreateReport): Promise<Array<ReportItem>> {
        const response = await this.createReportRaw({ createReport: createReport });
        return await response.value();
    }

    /**
     * Создание роли
     * Создание роли
     */
    async createRoleRaw(requestParameters: DefaultApiCreateRoleRequest): Promise<runtime.ApiResponse<Array<RoleItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createRole,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Создание роли
     * Создание роли
     */
    async createRole(createRole?: CreateRole): Promise<Array<RoleItem>> {
        const response = await this.createRoleRaw({ createRole: createRole });
        return await response.value();
    }

    /**
     * Создание нового тарифа
     * Создание нового тарифа
     */
    async createTariffRaw(requestParameters: DefaultApiCreateTariffRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createTariff === null || requestParameters.createTariff === undefined) {
            throw new runtime.RequiredError('createTariff','Required parameter requestParameters.createTariff was null or undefined when calling createTariff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tariffs/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createTariff,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Создание нового тарифа
     * Создание нового тарифа
     */
    async createTariff(createTariff: CreateTariff): Promise<void> {
        await this.createTariffRaw({ createTariff: createTariff });
    }

    /**
     * Создаёт заявку на выдачу меток в ЛК клиента. Метод доступен только клиенту
     * метод создания заявки на выдачу меток в ЛК клиента
     */
    async createreservlabelRaw(requestParameters: DefaultApiCreatereservlabelRequest): Promise<runtime.ApiResponse<InlineResponse2012>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reserv-labels/create-reserve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject5,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Создаёт заявку на выдачу меток в ЛК клиента. Метод доступен только клиенту
     * метод создания заявки на выдачу меток в ЛК клиента
     */
    async createreservlabel(inlineObject5?: InlineObject5): Promise<InlineResponse2012> {
        const response = await this.createreservlabelRaw({ inlineObject5: inlineObject5 });
        return await response.value();
    }

    /**
     * создаёт заявку на выдачу меток
     * метод создания заявки на выдачу меток
     */
    async cretereservlabelRaw(requestParameters: DefaultApiCretereservlabelRequest): Promise<runtime.ApiResponse<InlineResponse2012>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reserv-labels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject4,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * создаёт заявку на выдачу меток
     * метод создания заявки на выдачу меток
     */
    async cretereservlabel(inlineObject4?: InlineObject4): Promise<InlineResponse2012> {
        const response = await this.cretereservlabelRaw({ inlineObject4: inlineObject4 });
        return await response.value();
    }

    /**
     * Удаление отчета
     * Удаление отчета
     */
    async deleteReportRaw(requestParameters: DefaultApiDeleteReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling deleteReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/reports/{report_id}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаление отчета
     * Удаление отчета
     */
    async deleteReport(reportId: string): Promise<void> {
        await this.deleteReportRaw({ reportId: reportId });
    }

    /**
     * Удаление роли
     * Удаление роли
     */
    async deleteRoleRaw(requestParameters: DefaultApiDeleteRoleRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling deleteRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/roles/{role_id}`.replace(`{${"role_id"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаление роли
     * Удаление роли
     */
    async deleteRole(roleId: string): Promise<void> {
        await this.deleteRoleRaw({ roleId: roleId });
    }

    /**
     * Удаляет объект резерва меток
     * резерв меток
     */
    async deletereservlabelsRaw(requestParameters: DefaultApiDeletereservlabelsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletereservlabels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reserv-labels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаляет объект резерва меток
     * резерв меток
     */
    async deletereservlabels(id: string): Promise<void> {
        await this.deletereservlabelsRaw({ id: id });
    }

    /**
     * Отправляет сообщение администратору
     * Обратная связь
     */
    async feedbackClientRaw(requestParameters: DefaultApiFeedbackClientRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clients/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отправляет сообщение администратору
     * Обратная связь
     */
    async feedbackClient(body?: Body): Promise<void> {
        await this.feedbackClientRaw({ body: body });
    }

    /**
     * Метод возвращает ссылку на сгенерированный pdf файл
     * Генерация документа из шаблона
     */
    async generateDocumentsRaw(requestParameters: DefaultApiGenerateDocumentsRequest): Promise<runtime.ApiResponse<GenerateDocument>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/documents/generate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.fileGenerate,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает ссылку на сгенерированный pdf файл
     * Генерация документа из шаблона
     */
    async generateDocuments(fileGenerate?: FileGenerate): Promise<GenerateDocument> {
        const response = await this.generateDocumentsRaw({ fileGenerate: fileGenerate });
        return await response.value();
    }

    /**
     * Метод возвращает ссылку на сгенерированный файл
     * Генерация отчета
     */
    async generateReportsRaw(requestParameters: DefaultApiGenerateReportsRequest): Promise<runtime.ApiResponse<GenerateReport>> {
        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.clientType !== undefined) {
            queryParameters['client_type'] = requestParameters.clientType;
        }

        if (requestParameters.operationType !== undefined) {
            queryParameters['operation_type'] = requestParameters.operationType;
        }

        if (requestParameters.request !== undefined) {
            queryParameters['request'] = requestParameters.request;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.clientName !== undefined) {
            queryParameters['client_name'] = requestParameters.clientName;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.contractNumber !== undefined) {
            queryParameters['contract_number'] = requestParameters.contractNumber;
        }

        if (requestParameters.paymentMean !== undefined) {
            queryParameters['payment_mean'] = requestParameters.paymentMean;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license_plate'] = requestParameters.licensePlate;
        }

        if (requestParameters.tariffZone !== undefined) {
            queryParameters['tariff_zone'] = requestParameters.tariffZone;
        }

        if (requestParameters.vehicleClass !== undefined) {
            queryParameters['vehicle_class'] = requestParameters.vehicleClass;
        }

        if (requestParameters.location !== undefined) {
            queryParameters['location'] = requestParameters.location;
        }

        if (requestParameters.lane !== undefined) {
            queryParameters['lane'] = requestParameters.lane;
        }

        if (requestParameters.sum !== undefined) {
            queryParameters['sum'] = requestParameters.sum;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.car !== undefined) {
            queryParameters['car'] = requestParameters.car;
        }

        if (requestParameters.clienttype !== undefined) {
            queryParameters['clienttype'] = requestParameters.clienttype;
        }

        if (requestParameters.statusId !== undefined) {
            queryParameters['status_id'] = requestParameters.statusId;
        }

        if (requestParameters.pan !== undefined) {
            queryParameters['pan'] = requestParameters.pan;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.tariffGroup !== undefined) {
            queryParameters['tariff_group'] = requestParameters.tariffGroup;
        }

        if (requestParameters.contractId !== undefined) {
            queryParameters['contract_id'] = requestParameters.contractId;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/documents/generate-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.reportGenerate,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает ссылку на сгенерированный файл
     * Генерация отчета
     */
    async generateReports(reportGenerate?: ReportGenerate, dateFrom?: string, dateTo?: string, ordering?: string, clientType?: ClientType, operationType?: OperationType, request?: string, status?: string, clientName?: string, client?: number, contractNumber?: string, paymentMean?: string, licensePlate?: string, tariffZone?: string, vehicleClass?: string, location?: string, lane?: string, sum?: string, name?: string, car?: string, clienttype?: ClientType, statusId?: ContractStatus, pan?: string, vehicle?: string, tariffGroup?: string, contractId?: string, clientId?: string): Promise<GenerateReport> {
        const response = await this.generateReportsRaw({ reportGenerate: reportGenerate, dateFrom: dateFrom, dateTo: dateTo, ordering: ordering, clientType: clientType, operationType: operationType, request: request, status: status, clientName: clientName, client: client, contractNumber: contractNumber, paymentMean: paymentMean, licensePlate: licensePlate, tariffZone: tariffZone, vehicleClass: vehicleClass, location: location, lane: lane, sum: sum, name: name, car: car, clienttype: clienttype, statusId: statusId, pan: pan, vehicle: vehicle, tariffGroup: tariffGroup, contractId: contractId, clientId: clientId });
        return await response.value();
    }

    /**
     * Список смен
     */
    async getRaw(requestParameters: DefaultApiGetRequest): Promise<runtime.ApiResponse<ShiftListItem>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling get.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling get.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.operatorId !== undefined) {
            queryParameters['operator_id'] = requestParameters.operatorId;
        }

        if (requestParameters.laneId !== undefined) {
            queryParameters['lane_id'] = requestParameters.laneId;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Список смен
     */
    async get(offset: number, limit: number, ordering?: GetOrderingEnum, operatorId?: string, laneId?: number, externalId?: string, status?: GetStatusEnum): Promise<ShiftListItem> {
        const response = await this.getRaw({ offset: offset, limit: limit, ordering: ordering, operatorId: operatorId, laneId: laneId, externalId: externalId, status: status });
        return await response.value();
    }

    /**
     * Метод возвращает баланс счёта по его идентификатору
     * Получение баланса счёта
     */
    async getAccountRaw(requestParameters: DefaultApiGetAccountRequest): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
            throw new runtime.RequiredError('accountId','Required parameter requestParameters.accountId was null or undefined when calling getAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{account_id}`.replace(`{${"account_id"}}`, encodeURIComponent(String(requestParameters.accountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает баланс счёта по его идентификатору
     * Получение баланса счёта
     */
    async getAccount(accountId: string): Promise<Account> {
        const response = await this.getAccountRaw({ accountId: accountId });
        return await response.value();
    }

    /**
     * Метод возвращает список БИК с фильтрацией по БИК.
     * Получение списка БИК
     */
    async getBanksRaw(requestParameters: DefaultApiGetBanksRequest): Promise<runtime.ApiResponse<Array<Bic>>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling getBanks.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getBanks.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getBanks.');
        }

        const queryParameters: any = {};

        if (requestParameters.request !== undefined) {
            queryParameters['request'] = requestParameters.request;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/banks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список БИК с фильтрацией по БИК.
     * Получение списка БИК
     */
    async getBanks(request: string, offset: number, limit: number): Promise<Array<Bic>> {
        const response = await this.getBanksRaw({ request: request, offset: offset, limit: limit });
        return await response.value();
    }

    /**
     * Получение списка измененных меток для сервера парковки
     */
    async getChangeLabelsRaw(requestParameters: DefaultApiGetChangeLabelsRequest): Promise<runtime.ApiResponse<InlineResponse20011>> {
        const queryParameters: any = {};

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parking/labels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получение списка измененных меток для сервера парковки
     */
    async getChangeLabels(version?: number): Promise<InlineResponse20011> {
        const response = await this.getChangeLabelsRaw({ version: version });
        return await response.value();
    }

    /**
     * Метод возвращает объект клиента по его идентификатору
     * Получение клиента
     */
    async getClientRaw(requestParameters: DefaultApiGetClientRequest): Promise<runtime.ApiResponse<Client>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clients/{client_id}`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает объект клиента по его идентификатору
     * Получение клиента
     */
    async getClient(clientId: string): Promise<Client> {
        const response = await this.getClientRaw({ clientId: clientId });
        return await response.value();
    }

    /**
     * Метод возвращает список активностей клиента по его идентификатору
     * Получение активности клиента
     */
    async getClientActivityRaw(requestParameters: DefaultApiGetClientActivityRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getClientActivity.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getClientActivity.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getClientActivity.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clients/{client_id}/activity`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список активностей клиента по его идентификатору
     * Получение активности клиента
     */
    async getClientActivity(clientId: string, limit: number, offset: number, ordering?: string): Promise<InlineResponse2001> {
        const response = await this.getClientActivityRaw({ clientId: clientId, limit: limit, offset: offset, ordering: ordering });
        return await response.value();
    }

    /**
     * Метод возвращает список клиентов с возможностью фильтрации.
     * Получение списка клиентов
     */
    async getClientsRaw(requestParameters: DefaultApiGetClientsRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getClients.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getClients.');
        }

        const queryParameters: any = {};

        if (requestParameters.request !== undefined) {
            queryParameters['request'] = requestParameters.request;
        }

        if (requestParameters.clienttype !== undefined) {
            queryParameters['clienttype'] = requestParameters.clienttype;
        }

        if (requestParameters.statusId !== undefined) {
            queryParameters['status_id'] = requestParameters.statusId;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список клиентов с возможностью фильтрации.
     * Получение списка клиентов
     */
    async getClients(offset: number, limit: number, request?: string, clienttype?: ClientType, statusId?: ContractStatus, ordering?: string): Promise<InlineResponse200> {
        const response = await this.getClientsRaw({ offset: offset, limit: limit, request: request, clienttype: clienttype, statusId: statusId, ordering: ordering });
        return await response.value();
    }

    /**
     * Метод возвращает объект договора по его идентификатору
     * Получение договора
     */
    async getContractRaw(requestParameters: DefaultApiGetContractRequest): Promise<runtime.ApiResponse<ContractItem>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling getContract.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contracts/{contract_id}`.replace(`{${"contract_id"}}`, encodeURIComponent(String(requestParameters.contractId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает объект договора по его идентификатору
     * Получение договора
     */
    async getContract(contractId: string): Promise<ContractItem> {
        const response = await this.getContractRaw({ contractId: contractId });
        return await response.value();
    }

    /**
     * ТСП доступны договоры все клиентов, клиенту только его договоры
     * Получение списка договоров
     */
    async getContractsRaw(requestParameters: DefaultApiGetContractsRequest): Promise<runtime.ApiResponse<Array<ContractItem>>> {
        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contracts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * ТСП доступны договоры все клиентов, клиенту только его договоры
     * Получение списка договоров
     */
    async getContracts(clientId?: string, status?: GetContractsStatusEnum): Promise<Array<ContractItem>> {
        const response = await this.getContractsRaw({ clientId: clientId, status: status });
        return await response.value();
    }

    /**
     * Метод возвращает файл документа в бинарном виде.
     * Получение файла документа
     */
    async getDocumentRaw(requestParameters: DefaultApiGetDocumentRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling getDocument.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getDocument.');
        }

        const queryParameters: any = {};

        if (requestParameters.fileName !== undefined) {
            queryParameters['file_name'] = requestParameters.fileName;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents/file`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Метод возвращает файл документа в бинарном виде.
     * Получение файла документа
     */
    async getDocument(fileName: string, clientId: string): Promise<any> {
        const response = await this.getDocumentRaw({ fileName: fileName, clientId: clientId });
        return await response.value();
    }

    /**
     * Метод возвращает список документов требуемых для клиента указанного типа. По умолчанию вернутся документы с категориями REQUIRED и OPTIONAL. Для получения шаблонов документов, нужно передать category - EXTRA
     * Получение списка документов
     */
    async getDocumentsRaw(requestParameters: DefaultApiGetDocumentsRequest): Promise<runtime.ApiResponse<Array<Document>>> {
        const queryParameters: any = {};

        if (requestParameters.orgType !== undefined) {
            queryParameters['org_type'] = requestParameters.orgType;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documents/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список документов требуемых для клиента указанного типа. По умолчанию вернутся документы с категориями REQUIRED и OPTIONAL. Для получения шаблонов документов, нужно передать category - EXTRA
     * Получение списка документов
     */
    async getDocuments(orgType?: OrgType, category?: DocumentCategory, code?: DocumentCode): Promise<Array<Document>> {
        const response = await this.getDocumentsRaw({ orgType: orgType, category: category, code: code });
        return await response.value();
    }

    /**
     * Метод возвращает список возможных пунктов меню для добавления в роль
     * Получение списка пунктов меню
     */
    async getMenuRaw(): Promise<runtime.ApiResponse<Array<MenuItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/menu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список возможных пунктов меню для добавления в роль
     * Получение списка пунктов меню
     */
    async getMenu(): Promise<Array<MenuItem>> {
        const response = await this.getMenuRaw();
        return await response.value();
    }

    /**
     * Метод возвращает объект ТСП с настройками по его ключу. Администратору ТСП доступен только его ТСП.
     * Получение настроек ТСП
     */
    async getMerchantRaw(requestParameters: DefaultApiGetMerchantRequest): Promise<runtime.ApiResponse<Array<Merchant>>> {
        if (requestParameters.merchantKey === null || requestParameters.merchantKey === undefined) {
            throw new runtime.RequiredError('merchantKey','Required parameter requestParameters.merchantKey was null or undefined when calling getMerchant.');
        }

        const queryParameters: any = {};

        if (requestParameters.merchantKey !== undefined) {
            queryParameters['merchant_key'] = requestParameters.merchantKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchants/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает объект ТСП с настройками по его ключу. Администратору ТСП доступен только его ТСП.
     * Получение настроек ТСП
     */
    async getMerchant(merchantKey: string): Promise<Array<Merchant>> {
        const response = await this.getMerchantRaw({ merchantKey: merchantKey });
        return await response.value();
    }

    /**
     * Получение токена со сроком жизни неделя
     */
    async getParkingTokenRaw(requestParameters: DefaultApiGetParkingTokenRequest): Promise<runtime.ApiResponse<Tokens>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parking/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.auth,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получение токена со сроком жизни неделя
     */
    async getParkingToken(auth?: Auth): Promise<Tokens> {
        const response = await this.getParkingTokenRaw({ auth: auth });
        return await response.value();
    }

    /**
     * Метод возвращает список возможных прав для добавления в роль
     * Получение списка доступных прав
     */
    async getPermissionsRaw(): Promise<runtime.ApiResponse<Array<PermissionItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список возможных прав для добавления в роль
     * Получение списка доступных прав
     */
    async getPermissions(): Promise<Array<PermissionItem>> {
        const response = await this.getPermissionsRaw();
        return await response.value();
    }

    /**
     * Метод возвращает пользовательский отчет
     * Получение пользовательског отчета с конфигурацией
     */
    async getReportRaw(requestParameters: DefaultApiGetReportRequest): Promise<runtime.ApiResponse<Array<ReportItem>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/reports/{report_id}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает пользовательский отчет
     * Получение пользовательског отчета с конфигурацией
     */
    async getReport(reportId: string): Promise<Array<ReportItem>> {
        const response = await this.getReportRaw({ reportId: reportId });
        return await response.value();
    }

    /**
     * Метод возвращает список долступных срезов и метрик для создания отчета
     * Получение списка срезов и метрик для отчета
     */
    async getReportMetricsRaw(): Promise<runtime.ApiResponse<Array<RoleListItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/reports/metrics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список долступных срезов и метрик для создания отчета
     * Получение списка срезов и метрик для отчета
     */
    async getReportMetrics(): Promise<Array<RoleListItem>> {
        const response = await this.getReportMetricsRaw();
        return await response.value();
    }

    /**
     * Метод возвращает список созданных пользовательских отчетов
     * Получение списка пользовательских отчетов
     */
    async getReportsRaw(): Promise<runtime.ApiResponse<Array<ReportListItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список созданных пользовательских отчетов
     * Получение списка пользовательских отчетов
     */
    async getReports(): Promise<Array<ReportListItem>> {
        const response = await this.getReportsRaw();
        return await response.value();
    }

    /**
     * Метод возвращает пользовательскую роль с настройками
     * Получение пользовательской роли с настройками
     */
    async getRoleRaw(requestParameters: DefaultApiGetRoleRequest): Promise<runtime.ApiResponse<RoleItem>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/roles/{role_id}`.replace(`{${"role_id"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает пользовательскую роль с настройками
     * Получение пользовательской роли с настройками
     */
    async getRole(roleId: string): Promise<RoleItem> {
        const response = await this.getRoleRaw({ roleId: roleId });
        return await response.value();
    }

    /**
     * Метод возвращает список созданных пользовательских ролей
     * Получение списка пользовательских ролей
     */
    async getRolesRaw(): Promise<runtime.ApiResponse<Array<RoleListItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список созданных пользовательских ролей
     * Получение списка пользовательских ролей
     */
    async getRoles(): Promise<Array<RoleListItem>> {
        const response = await this.getRolesRaw();
        return await response.value();
    }

    /**
     * Список смен
     */
    async getShiftRaw(requestParameters: DefaultApiGetShiftRequest): Promise<runtime.ApiResponse<ShiftItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Список смен
     */
    async getShift(id: number): Promise<ShiftItem> {
        const response = await this.getShiftRaw({ id: id });
        return await response.value();
    }

    /**
     * Список смен по дням
     */
    async getShiftDaysRaw(requestParameters: DefaultApiGetShiftDaysRequest): Promise<runtime.ApiResponse<Array<ShiftDayItem>>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getShiftDays.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getShiftDays.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.day !== undefined) {
            queryParameters['day'] = (requestParameters.day as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift_days`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Список смен по дням
     */
    async getShiftDays(offset: number, limit: number, id?: number, day?: Date): Promise<Array<ShiftDayItem>> {
        const response = await this.getShiftDaysRaw({ offset: offset, limit: limit, id: id, day: day });
        return await response.value();
    }

    /**
     * Получение тарифа пор его id. ля разных типов тарифов набор полей будет отличаться.
     * Получение тарифа пор его id
     */
    async getTariffByIdRaw(requestParameters: DefaultApiGetTariffByIdRequest): Promise<runtime.ApiResponse<Tariff>> {
        if (requestParameters.tariffId === null || requestParameters.tariffId === undefined) {
            throw new runtime.RequiredError('tariffId','Required parameter requestParameters.tariffId was null or undefined when calling getTariffById.');
        }

        const queryParameters: any = {};

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tariffs/{tariff_id}`.replace(`{${"tariff_id"}}`, encodeURIComponent(String(requestParameters.tariffId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получение тарифа пор его id. ля разных типов тарифов набор полей будет отличаться.
     * Получение тарифа пор его id
     */
    async getTariffById(tariffId: number, version?: number): Promise<Tariff> {
        const response = await this.getTariffByIdRaw({ tariffId: tariffId, version: version });
        return await response.value();
    }

    /**
     * Получение списка тарифов
     * Получение списка тарифов
     */
    async getTariffsRaw(requestParameters: DefaultApiGetTariffsRequest): Promise<runtime.ApiResponse<InlineResponse20010>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getTariffs.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getTariffs.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.tariffStatus !== undefined) {
            queryParameters['tariff_status'] = requestParameters.tariffStatus;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.tariffType !== undefined) {
            queryParameters['tariff_type'] = requestParameters.tariffType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tariffs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получение списка тарифов
     * Получение списка тарифов
     */
    async getTariffs(offset: number, limit: number, dateFrom?: string, dateTo?: string, ordering?: string, search?: string, tariffStatus?: TariffStatus, name?: string, tariffType?: GetTariffsTariffTypeEnum): Promise<InlineResponse20010> {
        const response = await this.getTariffsRaw({ offset: offset, limit: limit, dateFrom: dateFrom, dateTo: dateTo, ordering: ordering, search: search, tariffStatus: tariffStatus, name: name, tariffType: tariffType });
        return await response.value();
    }

    /**
     * Получение токена со сроком жизни неделя
     */
    async getTokenRaw(requestParameters: DefaultApiGetTokenRequest): Promise<runtime.ApiResponse<Tokens>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interoperability/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.auth,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получение токена со сроком жизни неделя
     */
    async getToken(auth?: Auth): Promise<Tokens> {
        const response = await this.getTokenRaw({ auth: auth });
        return await response.value();
    }

    /**
     * Метод возвращает объект пользователя по его идентификатору и ключу организации
     * Получение пользователя
     */
    async getUserRaw(requestParameters: DefaultApiGetUserRequest): Promise<runtime.ApiResponse<UserInfo>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает объект пользователя по его идентификатору и ключу организации
     * Получение пользователя
     */
    async getUser(userId: string): Promise<UserInfo> {
        const response = await this.getUserRaw({ userId: userId });
        return await response.value();
    }

    /**
     * Метод возвращает список пользователя с возможностью ограничения по типу клиента и ключу организации.
     * Получение списка пользователей
     */
    async getUsersRaw(requestParameters: DefaultApiGetUsersRequest): Promise<runtime.ApiResponse<Array<UserInfo>>> {
        if (requestParameters.merchantKey === null || requestParameters.merchantKey === undefined) {
            throw new runtime.RequiredError('merchantKey','Required parameter requestParameters.merchantKey was null or undefined when calling getUsers.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getUsers.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.merchantKey !== undefined) {
            queryParameters['merchant_key'] = requestParameters.merchantKey;
        }

        if (requestParameters.orgType !== undefined) {
            queryParameters['org_type'] = requestParameters.orgType;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод возвращает список пользователя с возможностью ограничения по типу клиента и ключу организации.
     * Получение списка пользователей
     */
    async getUsers(merchantKey: string, offset: number, limit: number, orgType?: OrgType): Promise<Array<UserInfo>> {
        const response = await this.getUsersRaw({ merchantKey: merchantKey, offset: offset, limit: limit, orgType: orgType });
        return await response.value();
    }

    /**
     * Получение сведений о клиенте по номеру метки или ГРЗ
     */
    async infoForParkomatRaw(requestParameters: DefaultApiInfoForParkomatRequest): Promise<runtime.ApiResponse<ParkingInfo>> {
        if (requestParameters.search === null || requestParameters.search === undefined) {
            throw new runtime.RequiredError('search','Required parameter requestParameters.search was null or undefined when calling infoForParkomat.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parking/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получение сведений о клиенте по номеру метки или ГРЗ
     */
    async infoForParkomat(search: string): Promise<ParkingInfo> {
        const response = await this.infoForParkomatRaw({ search: search });
        return await response.value();
    }

    /**
     * Карточка заявки
     * резерв меток
     */
    async inforeservlabelsRaw(requestParameters: DefaultApiInforeservlabelsRequest): Promise<runtime.ApiResponse<Reservlabel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inforeservlabels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reserv-labels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Карточка заявки
     * резерв меток
     */
    async inforeservlabels(id: string): Promise<Reservlabel> {
        const response = await this.inforeservlabelsRaw({ id: id });
        return await response.value();
    }

    /**
     * Доступно ролям - администратор ТСП
     * Создание клиента
     */
    async instantCreateClientRaw(requestParameters: DefaultApiInstantCreateClientRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clients/create-instant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.clientWithUser,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Доступно ролям - администратор ТСП
     * Создание клиента
     */
    async instantCreateClient(clientWithUser?: ClientWithUser): Promise<void> {
        await this.instantCreateClientRaw({ clientWithUser: clientWithUser });
    }

    /**
     * Доступно ролям - администратор ТСП
     * Изменение клиента
     */
    async instantUpdateClientRaw(requestParameters: DefaultApiInstantUpdateClientRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling instantUpdateClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clients/{client_id}/update-instant`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.clientWithUser,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Доступно ролям - администратор ТСП
     * Изменение клиента
     */
    async instantUpdateClient(clientId: string, clientWithUser?: ClientWithUser): Promise<void> {
        await this.instantUpdateClientRaw({ clientId: clientId, clientWithUser: clientWithUser });
    }

    /**
     * Метод списывает со счета клиента стоимость метки
     * Выдача метки (списание баланса)
     */
    async issueALabelRaw(requestParameters: DefaultApiIssueALabelRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/labels/issue-a-label`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject3,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод списывает со счета клиента стоимость метки
     * Выдача метки (списание баланса)
     */
    async issueALabel(inlineObject3?: InlineObject3): Promise<void> {
        await this.issueALabelRaw({ inlineObject3: inlineObject3 });
    }

    /**
     * Выдача меток из заяки
     * Активирует метки из заявки
     */
    async issueLabelsRaw(requestParameters: DefaultApiIssueLabelsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling issueLabels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reserv-labels/{id}/issue`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Выдача меток из заяки
     * Активирует метки из заявки
     */
    async issueLabels(id: string): Promise<void> {
        await this.issueLabelsRaw({ id: id });
    }

    /**
     * Получает объект метки
     * получение истории метки по переданому id
     */
    async labelHistoryRaw(requestParameters: DefaultApiLabelHistoryRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling labelHistory.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling labelHistory.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling labelHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/labels/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получает объект метки
     * получение истории метки по переданому id
     */
    async labelHistory(id: string, limit: number, offset: number, ordering?: string): Promise<InlineResponse2002> {
        const response = await this.labelHistoryRaw({ id: id, limit: limit, offset: offset, ordering: ordering });
        return await response.value();
    }

    /**
     * создать метку.
     * создать метку.
     */
    async labelcreateRaw(requestParameters: DefaultApiLabelcreateRequest): Promise<runtime.ApiResponse<InlineResponse201>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/labels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * создать метку.
     * создать метку.
     */
    async labelcreate(inlineObject?: InlineObject): Promise<InlineResponse201> {
        const response = await this.labelcreateRaw({ inlineObject: inlineObject });
        return await response.value();
    }

    /**
     * Получает историю метки.
     * Получает историю метки по переданому pan
     */
    async labelhistoryRaw(requestParameters: DefaultApiLabelhistoryRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling labelhistory.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling labelhistory.');
        }

        if (requestParameters.pan === null || requestParameters.pan === undefined) {
            throw new runtime.RequiredError('pan','Required parameter requestParameters.pan was null or undefined when calling labelhistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.pan !== undefined) {
            queryParameters['pan'] = requestParameters.pan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/labels-history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получает историю метки.
     * Получает историю метки по переданому pan
     */
    async labelhistory(limit: number, offset: number, pan: string, ordering?: string): Promise<InlineResponse2003> {
        const response = await this.labelhistoryRaw({ limit: limit, offset: offset, pan: pan, ordering: ordering });
        return await response.value();
    }

    /**
     * Получает объект метки
     * получение объекта метки по переданому id
     */
    async labelinfoRaw(requestParameters: DefaultApiLabelinfoRequest): Promise<runtime.ApiResponse<Label>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling labelinfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/labels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получает объект метки
     * получение объекта метки по переданому id
     */
    async labelinfo(id: string): Promise<Label> {
        const response = await this.labelinfoRaw({ id: id });
        return await response.value();
    }

    /**
     * Возвращает список меток
     * Список меток
     */
    async labelsListRaw(requestParameters: DefaultApiLabelsListRequest): Promise<runtime.ApiResponse<Label>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling labelsList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling labelsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.pan !== undefined) {
            queryParameters['pan'] = requestParameters.pan;
        }

        if (requestParameters.clientName !== undefined) {
            queryParameters['client_name'] = requestParameters.clientName;
        }

        if (requestParameters.contractNumber !== undefined) {
            queryParameters['contract_number'] = requestParameters.contractNumber;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license_plate'] = requestParameters.licensePlate;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.tariffGroup !== undefined) {
            queryParameters['tariff_group'] = requestParameters.tariffGroup;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.contractId !== undefined) {
            queryParameters['contract_id'] = requestParameters.contractId;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/labels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Возвращает список меток
     * Список меток
     */
    async labelsList(limit: number, offset: number, dateFrom?: string, dateTo?: string, pan?: string, clientName?: string, contractNumber?: string, licensePlate?: string, vehicle?: string, tariffGroup?: string, ordering?: string, contractId?: string, clientId?: string, status?: LabelStatus): Promise<Label> {
        const response = await this.labelsListRaw({ limit: limit, offset: offset, dateFrom: dateFrom, dateTo: dateTo, pan: pan, clientName: clientName, contractNumber: contractNumber, licensePlate: licensePlate, vehicle: vehicle, tariffGroup: tariffGroup, ordering: ordering, contractId: contractId, clientId: clientId, status: status });
        return await response.value();
    }

    /**
     * Создание объектов меток из файла csv или списка с номерами меток.
     * Метод сохраняет метки из файла csv
     */
    async labelscreateobjectsRaw(requestParameters: DefaultApiLabelscreateobjectsRequest): Promise<runtime.ApiResponse<InlineResponse2011>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/labels/create-labels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject2,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Создание объектов меток из файла csv или списка с номерами меток.
     * Метод сохраняет метки из файла csv
     */
    async labelscreateobjects(inlineObject2?: InlineObject2): Promise<InlineResponse2011> {
        const response = await this.labelscreateobjectsRaw({ inlineObject2: inlineObject2 });
        return await response.value();
    }

    /**
     * Обновляет поля объекта метки status, category, contract, merchant
     * Обновление метки
     */
    async labelupdateRaw(requestParameters: DefaultApiLabelupdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling labelupdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/labels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject1,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновляет поля объекта метки status, category, contract, merchant
     * Обновление метки
     */
    async labelupdate(id: string, inlineObject1?: InlineObject1): Promise<void> {
        await this.labelupdateRaw({ id: id, inlineObject1: inlineObject1 });
    }

    /**
     * Метод для входа пользователей в систему. Вход возможен с парой телефон/email и пароль в закодированном формате. Возвращает JWT токен, информацию о пользователе и устанавливает Refresh токен в Cookie
     * Вход пользователя
     */
    async loginUserRaw(requestParameters: DefaultApiLoginUserRequest): Promise<runtime.ApiResponse<AuthInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userLogin,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод для входа пользователей в систему. Вход возможен с парой телефон/email и пароль в закодированном формате. Возвращает JWT токен, информацию о пользователе и устанавливает Refresh токен в Cookie
     * Вход пользователя
     */
    async loginUser(userLogin?: UserLogin): Promise<AuthInfo> {
        const response = await this.loginUserRaw({ userLogin: userLogin });
        return await response.value();
    }

    /**
     * Заполняет или изменяет настройки ТСП
     * Метод сохраняет настройки ТСП
     */
    async marchantsSaveDetailsRaw(requestParameters: DefaultApiMarchantsSaveDetailsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.merchantKey === null || requestParameters.merchantKey === undefined) {
            throw new runtime.RequiredError('merchantKey','Required parameter requestParameters.merchantKey was null or undefined when calling marchantsSaveDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.merchantKey !== undefined) {
            queryParameters['merchant_key'] = requestParameters.merchantKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchants/save-details`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.merchant,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Заполняет или изменяет настройки ТСП
     * Метод сохраняет настройки ТСП
     */
    async marchantsSaveDetails(merchantKey: string, merchant?: Merchant): Promise<void> {
        await this.marchantsSaveDetailsRaw({ merchantKey: merchantKey, merchant: merchant });
    }

    /**
     * Возвращает список ТСП. Доступен только оператору.
     * Список администраторов
     */
    async merchantsListRaw(): Promise<runtime.ApiResponse<Array<MerchantListItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Возвращает список ТСП. Доступен только оператору.
     * Список администраторов
     */
    async merchantsList(): Promise<Array<MerchantListItem>> {
        const response = await this.merchantsListRaw();
        return await response.value();
    }

    /**
     * Открытие смены
     */
    async openShiftRaw(requestParameters: DefaultApiOpenShiftRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interoperability/shifts/open`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.openShift,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Открытие смены
     */
    async openShift(openShift?: OpenShift): Promise<void> {
        await this.openShiftRaw({ openShift: openShift });
    }

    /**
     * Возврат операции
     * Возврат операции
     */
    async operationCorrectedRaw(requestParameters: DefaultApiOperationCorrectedRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.operationId === null || requestParameters.operationId === undefined) {
            throw new runtime.RequiredError('operationId','Required parameter requestParameters.operationId was null or undefined when calling operationCorrected.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operations/{operation_id}/corrected`.replace(`{${"operation_id"}}`, encodeURIComponent(String(requestParameters.operationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject14,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Возврат операции
     * Возврат операции
     */
    async operationCorrected(operationId: string, inlineObject14?: InlineObject14): Promise<void> {
        await this.operationCorrectedRaw({ operationId: operationId, inlineObject14: inlineObject14 });
    }

    /**
     * Создает операцию поездки
     * Создание поездки
     */
    async operationJourneyRaw(requestParameters: DefaultApiOperationJourneyRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operations/journey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createJourney,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Создает операцию поездки
     * Создание поездки
     */
    async operationJourney(createJourney?: CreateJourney): Promise<void> {
        await this.operationJourneyRaw({ createJourney: createJourney });
    }

    /**
     * Возврат операции
     * Возврат операции
     */
    async operationRollbackRaw(requestParameters: DefaultApiOperationRollbackRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.operationId === null || requestParameters.operationId === undefined) {
            throw new runtime.RequiredError('operationId','Required parameter requestParameters.operationId was null or undefined when calling operationRollback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operations/{operation_id}/rollback`.replace(`{${"operation_id"}}`, encodeURIComponent(String(requestParameters.operationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject13,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Возврат операции
     * Возврат операции
     */
    async operationRollback(operationId: string, inlineObject13?: InlineObject13): Promise<void> {
        await this.operationRollbackRaw({ operationId: operationId, inlineObject13: inlineObject13 });
    }

    /**
     * Изменяет флаг подозрительной операции is_suspected
     * Изменение операции
     */
    async operationUpdateRaw(requestParameters: DefaultApiOperationUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.operationId === null || requestParameters.operationId === undefined) {
            throw new runtime.RequiredError('operationId','Required parameter requestParameters.operationId was null or undefined when calling operationUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operations/{operation_id}`.replace(`{${"operation_id"}}`, encodeURIComponent(String(requestParameters.operationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject12,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Изменяет флаг подозрительной операции is_suspected
     * Изменение операции
     */
    async operationUpdate(operationId: string, inlineObject12?: InlineObject12): Promise<void> {
        await this.operationUpdateRaw({ operationId: operationId, inlineObject12: inlineObject12 });
    }

    /**
     * Подробная карточка операции
     * Информация об операции
     */
    async operationViewRaw(requestParameters: DefaultApiOperationViewRequest): Promise<runtime.ApiResponse<Operation>> {
        if (requestParameters.operationId === null || requestParameters.operationId === undefined) {
            throw new runtime.RequiredError('operationId','Required parameter requestParameters.operationId was null or undefined when calling operationView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operations/{operation_id}`.replace(`{${"operation_id"}}`, encodeURIComponent(String(requestParameters.operationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Подробная карточка операции
     * Информация об операции
     */
    async operationView(operationId: string): Promise<Operation> {
        const response = await this.operationViewRaw({ operationId: operationId });
        return await response.value();
    }

    /**
     * Проезды, оплаты связанные с метками
     * Список операций
     */
    async operationsListRaw(requestParameters: DefaultApiOperationsListRequest): Promise<runtime.ApiResponse<InlineResponse2009>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling operationsList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling operationsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.clientType !== undefined) {
            queryParameters['client_type'] = requestParameters.clientType;
        }

        if (requestParameters.operationType !== undefined) {
            queryParameters['operation_type'] = requestParameters.operationType;
        }

        if (requestParameters.journeyType !== undefined) {
            queryParameters['journey_type'] = requestParameters.journeyType;
        }

        if (requestParameters.request !== undefined) {
            queryParameters['request'] = requestParameters.request;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.clientName !== undefined) {
            queryParameters['client_name'] = requestParameters.clientName;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.contractNumber !== undefined) {
            queryParameters['contract_number'] = requestParameters.contractNumber;
        }

        if (requestParameters.paymentMean !== undefined) {
            queryParameters['payment_mean'] = requestParameters.paymentMean;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license_plate'] = requestParameters.licensePlate;
        }

        if (requestParameters.tariffZone !== undefined) {
            queryParameters['tariff_zone'] = requestParameters.tariffZone;
        }

        if (requestParameters.vehicleClass !== undefined) {
            queryParameters['vehicle_class'] = requestParameters.vehicleClass;
        }

        if (requestParameters.location !== undefined) {
            queryParameters['location'] = requestParameters.location;
        }

        if (requestParameters.lane !== undefined) {
            queryParameters['lane'] = requestParameters.lane;
        }

        if (requestParameters.sum !== undefined) {
            queryParameters['sum'] = requestParameters.sum;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.car !== undefined) {
            queryParameters['car'] = requestParameters.car;
        }

        if (requestParameters.shiftId !== undefined) {
            queryParameters['shift_id'] = requestParameters.shiftId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/operations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Проезды, оплаты связанные с метками
     * Список операций
     */
    async operationsList(offset: number, limit: number, dateFrom?: string, dateTo?: string, ordering?: string, clientType?: ClientType, operationType?: OperationType, journeyType?: JourneyType, request?: string, status?: OperationStatus, clientName?: string, client?: number, contractNumber?: string, paymentMean?: string, licensePlate?: string, tariffZone?: string, vehicleClass?: string, location?: string, lane?: string, sum?: string, name?: string, car?: string, shiftId?: number): Promise<InlineResponse2009> {
        const response = await this.operationsListRaw({ offset: offset, limit: limit, dateFrom: dateFrom, dateTo: dateTo, ordering: ordering, clientType: clientType, operationType: operationType, journeyType: journeyType, request: request, status: status, clientName: clientName, client: client, contractNumber: contractNumber, paymentMean: paymentMean, licensePlate: licensePlate, tariffZone: tariffZone, vehicleClass: vehicleClass, location: location, lane: lane, sum: sum, name: name, car: car, shiftId: shiftId });
        return await response.value();
    }

    /**
     * Разрешает выезд с полосы
     */
    async parkingExitRaw(requestParameters: DefaultApiParkingExitRequest): Promise<runtime.ApiResponse<ParkingExitResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parking/exit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.parkingExit,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Разрешает выезд с полосы
     */
    async parkingExit(parkingExit?: ParkingExit): Promise<ParkingExitResponse> {
        const response = await this.parkingExitRaw({ parkingExit: parkingExit });
        return await response.value();
    }

    /**
     * Загрузка операций парковки с сервера в БСТР
     */
    async parkingOperationsRaw(requestParameters: DefaultApiParkingOperationsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parking/operations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.parkingOperations,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Загрузка операций парковки с сервера в БСТР
     */
    async parkingOperations(parkingOperations?: ParkingOperations): Promise<void> {
        await this.parkingOperationsRaw({ parkingOperations: parkingOperations });
    }

    /**
     * Метод сохраняет документ определенного типа в привязке к клиенту.
     * Сохранение документа
     */
    async postDocumentsRaw(requestParameters: DefaultApiPostDocumentsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.fileCreate,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод сохраняет документ определенного типа в привязке к клиенту.
     * Сохранение документа
     */
    async postDocuments(fileCreate?: FileCreate): Promise<void> {
        await this.postDocumentsRaw({ fileCreate: fileCreate });
    }

    /**
     * Метод сохраняет или обновляет шаблон дополнительного документа для клиента определенного типа
     * Сохранение шаблона документа
     */
    async postDocumentsExtraRaw(requestParameters: DefaultApiPostDocumentsExtraRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/documents/extra`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.fileExtraCreate,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод сохраняет или обновляет шаблон дополнительного документа для клиента определенного типа
     * Сохранение шаблона документа
     */
    async postDocumentsExtra(fileExtraCreate?: FileExtraCreate): Promise<void> {
        await this.postDocumentsExtraRaw({ fileExtraCreate: fileExtraCreate });
    }

    /**
     * Входным параметром передается ID клиента.
     * Изменение клиента
     */
    async putClientRaw(requestParameters: DefaultApiPutClientRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling putClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clients/{client_id}/update`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.clientIndividualClientSPClientCompany,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Входным параметром передается ID клиента.
     * Изменение клиента
     */
    async putClient(clientId: string, clientIndividualClientSPClientCompany?: ClientIndividual | ClientSP | ClientCompany): Promise<void> {
        await this.putClientRaw({ clientId: clientId, clientIndividualClientSPClientCompany: clientIndividualClientSPClientCompany });
    }

    /**
     * Входным параметром передается ID пользователя из JWT токена. В случае изменения телефона или email необходимо высылать уникальный код подтверждения на соотвествующий контакт.
     * Изменение пользователя
     */
    async putUserRaw(requestParameters: DefaultApiPutUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling putUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/update`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userBasic,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Входным параметром передается ID пользователя из JWT токена. В случае изменения телефона или email необходимо высылать уникальный код подтверждения на соотвествующий контакт.
     * Изменение пользователя
     */
    async putUser(userId: string, userBasic?: UserBasic): Promise<void> {
        await this.putUserRaw({ userId: userId, userBasic: userBasic });
    }

    /**
     * Получение списка меток для полосы
     */
    async receiveLabelsListRaw(): Promise<runtime.ApiResponse<Array<ControllerLabel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interoperability/receiveLabelsList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Получение списка меток для полосы
     */
    async receiveLabelsList(): Promise<Array<ControllerLabel>> {
        const response = await this.receiveLabelsListRaw();
        return await response.value();
    }

    /**
     * Загрузка операций проезда полосы
     */
    async receiveTransactionListRaw(requestParameters: DefaultApiReceiveTransactionListRequest): Promise<runtime.ApiResponse<InteroperabilityResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interoperability/receiveTransactionList`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.receiveTransactionList,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Загрузка операций проезда полосы
     */
    async receiveTransactionList(receiveTransactionList?: ReceiveTransactionList): Promise<InteroperabilityResponse> {
        const response = await this.receiveTransactionListRaw({ receiveTransactionList: receiveTransactionList });
        return await response.value();
    }

    /**
     * Метод для обновления JWT токена в случае его невалидности (вернулся код 401). Refresh токен хранится в недоступной для клиента cookie и доступен для сервера при запросах. Возвращает валидный новый JWT токен, информацию о пользователе и обновляет Refresh токен.
     * Обновление JWT токена
     */
    async refreshTokenUserRaw(): Promise<runtime.ApiResponse<AuthInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/refresh-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод для обновления JWT токена в случае его невалидности (вернулся код 401). Refresh токен хранится в недоступной для клиента cookie и доступен для сервера при запросах. Возвращает валидный новый JWT токен, информацию о пользователе и обновляет Refresh токен.
     * Обновление JWT токена
     */
    async refreshTokenUser(): Promise<AuthInfo> {
        const response = await this.refreshTokenUserRaw();
        return await response.value();
    }

    /**
     * Метод создаёт пользователя в системе, привязывая к нему роль.
     * Создание юзера c пользовательской ролью
     */
    async registerCustomUserRaw(requestParameters: DefaultApiRegisterCustomUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userCustomRole === null || requestParameters.userCustomRole === undefined) {
            throw new runtime.RequiredError('userCustomRole','Required parameter requestParameters.userCustomRole was null or undefined when calling registerCustomUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/create-custom-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userCustomRole,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод создаёт пользователя в системе, привязывая к нему роль.
     * Создание юзера c пользовательской ролью
     */
    async registerCustomUser(userCustomRole: UserCustomRole): Promise<void> {
        await this.registerCustomUserRaw({ userCustomRole: userCustomRole });
    }

    /**
     * Метод создаёт пользователя в системе
     * Создание пользователя
     */
    async registerUserRaw(requestParameters: DefaultApiRegisterUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userCreate === null || requestParameters.userCreate === undefined) {
            throw new runtime.RequiredError('userCreate','Required parameter requestParameters.userCreate was null or undefined when calling registerUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userCreate,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод создаёт пользователя в системе
     * Создание пользователя
     */
    async registerUser(userCreate: UserCreate): Promise<void> {
        await this.registerUserRaw({ userCreate: userCreate });
    }

    /**
     * Метод создаёт пользователя в системе сразу, без валидации контактов. Предназначено для создания клиентов через административный интерфейс ТСП
     * Создание пользователя без валидации контактов
     */
    async registerUserInstantRaw(requestParameters: DefaultApiRegisterUserInstantRequest): Promise<runtime.ApiResponse<UserInfo>> {
        if (requestParameters.userBasicWithClientType === null || requestParameters.userBasicWithClientType === undefined) {
            throw new runtime.RequiredError('userBasicWithClientType','Required parameter requestParameters.userBasicWithClientType was null or undefined when calling registerUserInstant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/create-instant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userBasicWithClientType,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Метод создаёт пользователя в системе сразу, без валидации контактов. Предназначено для создания клиентов через административный интерфейс ТСП
     * Создание пользователя без валидации контактов
     */
    async registerUserInstant(userBasicWithClientType: UserBasicWithClientType): Promise<UserInfo> {
        const response = await this.registerUserInstantRaw({ userBasicWithClientType: userBasicWithClientType });
        return await response.value();
    }

    /**
     * Входным параметром передается ID клиента.
     * Отклонение клиента
     */
    async rejectClientRaw(requestParameters: DefaultApiRejectClientRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling rejectClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clients/{client_id}/reject`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.message,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Входным параметром передается ID клиента.
     * Отклонение клиента
     */
    async rejectClient(clientId: string, message?: Message): Promise<void> {
        await this.rejectClientRaw({ clientId: clientId, message: message });
    }

    /**
     * Метод пополняет баланс счета на указанную сумму
     * Пополнение баланса счета
     */
    async replenishmentAccountRaw(requestParameters: DefaultApiReplenishmentAccountRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
            throw new runtime.RequiredError('accountId','Required parameter requestParameters.accountId was null or undefined when calling replenishmentAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{account_id}/replenishment`.replace(`{${"account_id"}}`, encodeURIComponent(String(requestParameters.accountId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.moneyAmount,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод пополняет баланс счета на указанную сумму
     * Пополнение баланса счета
     */
    async replenishmentAccount(accountId: string, moneyAmount?: MoneyAmount): Promise<void> {
        await this.replenishmentAccountRaw({ accountId: accountId, moneyAmount: moneyAmount });
    }

    /**
     * Пополнение баланса клиента с паркомата
     */
    async replenishmentFromParkingRaw(requestParameters: DefaultApiReplenishmentFromParkingRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parking/replenishment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.parkingReplenishment,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Пополнение баланса клиента с паркомата
     */
    async replenishmentFromParking(parkingReplenishment?: ParkingReplenishment): Promise<void> {
        await this.replenishmentFromParkingRaw({ parkingReplenishment: parkingReplenishment });
    }

    /**
     * Возвращает список заявок на резерв меток
     * Список меток
     */
    async reservlabelsListRaw(requestParameters: DefaultApiReservlabelsListRequest): Promise<runtime.ApiResponse<InlineResponse2004>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling reservlabelsList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling reservlabelsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reserv-labels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Возвращает список заявок на резерв меток
     * Список меток
     */
    async reservlabelsList(limit: number, offset: number, search?: string, ordering?: string, clientId?: string): Promise<InlineResponse2004> {
        const response = await this.reservlabelsListRaw({ limit: limit, offset: offset, search: search, ordering: ordering, clientId: clientId });
        return await response.value();
    }

    /**
     * Метод для разлогинивания пользователя на стороне сервера (удаление Refresh токена).
     * Отзыв JWT токена
     */
    async revokeTokenUserRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/revoke-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод для разлогинивания пользователя на стороне сервера (удаление Refresh токена).
     * Отзыв JWT токена
     */
    async revokeTokenUser(): Promise<void> {
        await this.revokeTokenUserRaw();
    }

    /**
     * Отправка кода для подтверждения контакта при регистрации или для восстановления пароля
     * Отправка кода валидации для контакта пользователя
     */
    async sendValidationCodeRaw(requestParameters: DefaultApiSendValidationCodeRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/sendcode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userSendValidationCode,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отправка кода для подтверждения контакта при регистрации или для восстановления пароля
     * Отправка кода валидации для контакта пользователя
     */
    async sendValidationCode(userSendValidationCode?: UserSendValidationCode): Promise<void> {
        await this.sendValidationCodeRaw({ userSendValidationCode: userSendValidationCode });
    }

    /**
     * Привязывает метки к заявке
     * Связь меток с машинами из заявки, сформированной клиентом
     */
    async setLabelsToReservlabelRaw(requestParameters: DefaultApiSetLabelsToReservlabelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setLabelsToReservlabel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reserv-labels/{id}/set-labels`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject7,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Привязывает метки к заявке
     * Связь меток с машинами из заявки, сформированной клиентом
     */
    async setLabelsToReservlabel(id: string, inlineObject7?: InlineObject7): Promise<void> {
        await this.setLabelsToReservlabelRaw({ id: id, inlineObject7: inlineObject7 });
    }

    /**
     * Изменить пароль текущему пользователю
     * Изменить пароль текущему пользователю
     */
    async setUserPasswordRaw(requestParameters: DefaultApiSetUserPasswordRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/setpassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject15,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Изменить пароль текущему пользователю
     * Изменить пароль текущему пользователю
     */
    async setUserPassword(inlineObject15?: InlineObject15): Promise<void> {
        await this.setUserPasswordRaw({ inlineObject15: inlineObject15 });
    }

    /**
     * Метод устанавливает стоимость выдаваемой ТСП метки
     * Установка стоимости метки
     */
    async setlLabelCostRaw(requestParameters: DefaultApiSetlLabelCostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/labels/set-label-cost`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.moneyAmount,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод устанавливает стоимость выдаваемой ТСП метки
     * Установка стоимости метки
     */
    async setlLabelCost(moneyAmount?: MoneyAmount): Promise<void> {
        await this.setlLabelCostRaw({ moneyAmount: moneyAmount });
    }

    /**
     * Изменение роли
     * Изменение роли
     */
    async updateRoleRaw(requestParameters: DefaultApiUpdateRoleRequest): Promise<runtime.ApiResponse<Array<RoleItem>>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling updateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/config/roles/{role_id}`.replace(`{${"role_id"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateRole,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Изменение роли
     * Изменение роли
     */
    async updateRole(roleId: string, updateRole?: UpdateRole): Promise<Array<RoleItem>> {
        const response = await this.updateRoleRaw({ roleId: roleId, updateRole: updateRole });
        return await response.value();
    }

    /**
     * Изменение статуса договора и привязка его к индивидуальному тарифу
     * Обновление договора
     */
    async updateTariffRaw(requestParameters: DefaultApiUpdateTariffRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId','Required parameter requestParameters.contractId was null or undefined when calling updateTariff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contracts/{contract_id}`.replace(`{${"contract_id"}}`, encodeURIComponent(String(requestParameters.contractId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.contractUpdate,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Изменение статуса договора и привязка его к индивидуальному тарифу
     * Обновление договора
     */
    async updateTariff(contractId: string, contractUpdate?: ContractUpdate): Promise<void> {
        await this.updateTariffRaw({ contractId: contractId, contractUpdate: contractUpdate });
    }

    /**
     * Обновление тарифа по его id. ля разных типов тарифов набор полей будет отличаться.
     * Редактирование тарифа
     */
    async updateTariffByIdRaw(requestParameters: DefaultApiUpdateTariffByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateTariff === null || requestParameters.updateTariff === undefined) {
            throw new runtime.RequiredError('updateTariff','Required parameter requestParameters.updateTariff was null or undefined when calling updateTariffById.');
        }

        if (requestParameters.tariffId === null || requestParameters.tariffId === undefined) {
            throw new runtime.RequiredError('tariffId','Required parameter requestParameters.tariffId was null or undefined when calling updateTariffById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tariffs/{tariff_id}`.replace(`{${"tariff_id"}}`, encodeURIComponent(String(requestParameters.tariffId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateTariff,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновление тарифа по его id. ля разных типов тарифов набор полей будет отличаться.
     * Редактирование тарифа
     */
    async updateTariffById(updateTariff: UpdateTariff, tariffId: number): Promise<void> {
        await this.updateTariffByIdRaw({ updateTariff: updateTariff, tariffId: tariffId });
    }

    /**
     * Ручная активация или архивирование тарифа
     * Изменение статуса тарифа
     */
    async updateTariffStatusRaw(requestParameters: DefaultApiUpdateTariffStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tariffStatus === null || requestParameters.tariffStatus === undefined) {
            throw new runtime.RequiredError('tariffStatus','Required parameter requestParameters.tariffStatus was null or undefined when calling updateTariffStatus.');
        }

        if (requestParameters.tariffId === null || requestParameters.tariffId === undefined) {
            throw new runtime.RequiredError('tariffId','Required parameter requestParameters.tariffId was null or undefined when calling updateTariffStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tariffs/{tariff_id}/update-status`.replace(`{${"tariff_id"}}`, encodeURIComponent(String(requestParameters.tariffId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.tariffStatus,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Ручная активация или архивирование тарифа
     * Изменение статуса тарифа
     */
    async updateTariffStatus(tariffStatus: TariffStatus, tariffId: number): Promise<void> {
        await this.updateTariffStatusRaw({ tariffStatus: tariffStatus, tariffId: tariffId });
    }

    /**
     * Обновляет ТС
     * Обновляет ТС
     */
    async updateVehiclesRaw(requestParameters: DefaultApiUpdateVehiclesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateVehicles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject8,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновляет ТС
     * Обновляет ТС
     */
    async updateVehicles(id: string, inlineObject8?: InlineObject8): Promise<void> {
        await this.updateVehiclesRaw({ id: id, inlineObject8: inlineObject8 });
    }

    /**
     * Обновляет Удаляет объект резерва меток
     * Обновление резерв меток
     */
    async updatereservlabelsRaw(requestParameters: DefaultApiUpdatereservlabelsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatereservlabels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reserv-labels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.inlineObject6,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновляет Удаляет объект резерва меток
     * Обновление резерв меток
     */
    async updatereservlabels(id: string, inlineObject6?: InlineObject6): Promise<void> {
        await this.updatereservlabelsRaw({ id: id, inlineObject6: inlineObject6 });
    }

    /**
     * Валидация контакта при регистрации или восстановлении пароля. Возвращает JWT токен, данные пользователя и устанавливает Refresh токен в Cookie
     * Валидация контакта пользователя
     */
    async validateUserContactRaw(requestParameters: DefaultApiValidateUserContactRequest): Promise<runtime.ApiResponse<AuthInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userValidateContact,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Валидация контакта при регистрации или восстановлении пароля. Возвращает JWT токен, данные пользователя и устанавливает Refresh токен в Cookie
     * Валидация контакта пользователя
     */
    async validateUserContact(userValidateContact?: UserValidateContact): Promise<AuthInfo> {
        const response = await this.validateUserContactRaw({ userValidateContact: userValidateContact });
        return await response.value();
    }

    /**
     * Карточка TC
     * Карточка TC
     */
    async vehicleItemRaw(requestParameters: DefaultApiVehicleItemRequest): Promise<runtime.ApiResponse<VehicleItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vehicleItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Карточка TC
     * Карточка TC
     */
    async vehicleItem(id: string): Promise<VehicleItem> {
        const response = await this.vehicleItemRaw({ id: id });
        return await response.value();
    }

    /**
     * Возвращает список машин. Админу доступны все ТС, клиенту только свои
     * Список машин
     */
    async vehiclesListRaw(requestParameters: DefaultApiVehiclesListRequest): Promise<runtime.ApiResponse<InlineResponse2005>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling vehiclesList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling vehiclesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Возвращает список машин. Админу доступны все ТС, клиенту только свои
     * Список машин
     */
    async vehiclesList(limit: number, offset: number): Promise<InlineResponse2005> {
        const response = await this.vehiclesListRaw({ limit: limit, offset: offset });
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetOrderingEnum {
    start = 'datetime_start',
    end = 'datetime_end'
}
/**
    * @export
    * @enum {string}
    */
export enum GetStatusEnum {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    ADMIN_CLOSED = 'ADMIN_CLOSED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetContractsStatusEnum {
    NEW = 'NEW',
    WAITING_REVIEW = 'WAITING_REVIEW',
    WAITING_USER = 'WAITING_USER',
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    TERMINATING = 'TERMINATING',
    TERMINATED = 'TERMINATED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetTariffsTariffTypeEnum {
    TARIFF_NK = 'TARIFF_NK',
    FREE = 'FREE',
    DEFAULT_RFID = 'DEFAULT_RFID',
    REGULAR = 'REGULAR',
    PARKING_SUBSCRIPTION = 'PARKING_SUBSCRIPTION'
}
