import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Content = styled.div`
  &.inline {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
`;
const Item = styled.div`
  margin-right: 0rem;
`;

export const CheckboxGroupMUI = ({ fields, options, cl }) => {
  const toggle = (event, option) => {
    if (event.target.checked) {
      fields.push(option.value);
    } else {
      let i = -1;
      fields.forEach((val, idx) => {
        if (val === option.value) {
          i = idx;
        }
      });
      fields.remove(i);
    }
  };

  return (
    <Content className={cl}>
      {options.map((option, idx) => (
        <Item key={idx}>
          <FormControlLabel
            label={option.title}
            control={
              <Checkbox
                //name={fields.name}
                checked={fields.value.includes(option.value)}
                value={option.value.toString()}
                onChange={(event) => toggle(event, option)}
              />
            }
          />
        </Item>
      ))}
    </Content>
  );
};
CheckboxGroupMUI.propTypes = {
  fields: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  cl: PropTypes.string,
};

export default CheckboxGroupMUI;
