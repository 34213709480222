import { createTheme } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';

import { DownIcon, CheckedIcon, UncheckedIcon, RadioCheckedIcon, RadioUncheckedIcon } from 'libs/icons/icons';

export const params = {
  palette: {
    primary: {
      light: '#93D193',
      main: '#5EBB47',
      dark: '#3B9845',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FD8B8B',
      main: '#EB5757',
      dark: '#CC4444',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#DD5656',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  shape: {
    borderRadius: '0.3125rem',
  },
  props: {
    MuiFilledInput: {
      //disableUnderline: false
    },
    MuiCheckbox: {
      checkedIcon: <CheckedIcon />,
      icon: <UncheckedIcon />,
    },
    MuiRadio: {
      checkedIcon: <RadioCheckedIcon />,
      icon: <RadioUncheckedIcon />,
    },
    MuiSelect: {
      IconComponent: DownIcon,
    },
    MuiMenu: {
      elevation: 0,
      getContentAnchorEl: null,
      disableScrollLock: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
    MuiListItemText: {
      secondaryTypographyProps: {
        component: 'div',
      },
    },
  },
  overrides: {
    // popover
    MuiPopover: {
      paper: {
        //top: '100%'
      },
    },

    // tab
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },

    // button
    MuiButton: {
      root: {
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&$containedPrimary': {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#3B9845',
          },
          '&$disabled': {
            background: '#93D193',
            cursor: 'not-allowed',
            color: '#fff',
          },
        },
        '&$containedSecondary': {
          '&:hover': {
            backgroundColor: '#CC4444',
          },
          '&$disabled': {
            background: '#FD8B8B',
            cursor: 'not-allowed',
            color: '#fff',
          },
        },
      },
      containedSizeLarge: {
        fontSize: '1rem',
        height: '4rem',
      },
      containedSizeSmall: {
        height: '2rem',
      },
      outlinedSizeSmall: {
        height: '2rem',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#9299A2',
        lineHeight: '1.25',
      },
      filled: {
        transform: 'translate(1.0625rem, 1.4375rem) scale(1)',
        '&$shrink': {
          transform: 'translate(1.0625rem, 0.625rem) scale(0.75)',
        },
        '&$focused': {
          color: '#9299A2',
        },
        '&$error': {
          color: '#9299A2',
        },
        '&$disabled': {
          color: 'rgba(146, 153, 162, .5)',
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        //borderColor: '#999',
        paddingTop: '2rem !important',
        paddingBottom: '0.8125rem !important',
        paddingLeft: '1.0625rem !important',
        '&$focused': {
          borderRadius: '0.3125rem 0.3125rem 0 0 ',
        },
        '&.MuiInputBase-marginDense': {
          paddingTop: '0.625rem !important',
          paddingBottom: '0.6875rem !important',
        },
      },
      input: {
        padding: '0 !important',
      },
      paper: {
        margin: '0',
        borderRadius: '0 0 0.3125rem 0.3125rem',
        boxShadow: 'none',
      },
      popperDisablePortal: {
        width: '100% !important',
      },
      listbox: {
        padding: '0',
        border: '1px solid #DADADA',
        borderTop: '0',
        borderRadius: '0 0 0.3125rem 0.3125rem',
        //maxHeight: '11.3rem',
      },
      option: {
        '&[aria-selected="true"]': {
          backgroundColor: '#ffffff',
        },
        '&:last-child': {
          borderRadius: '0 0 0.3125rem 0.3125rem',
        },
      },
    },

    MuiFilledInput: {
      root: {
        //border: '1px solid #F2F4F5',
        borderRadius: '0.3125rem',
        //overflow: 'hidden',
        backgroundColor: '#f2f4f5',
        //transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&$focused': {
          '&.opened': {
            borderRadius: '0.3125rem 0.3125rem 0 0',
          },
          borderColor: '#DDDFE0',
          backgroundColor: '#fff',
          '&:hover': {
            borderColor: '#DDDFE0',
            backgroundColor: '#fff',
          },
        },
        '&$adornedEnd': {
          paddingRight: 0,
        },
        '&$disabled': {
          backgroundColor: 'rgba(233, 236, 238, .5)',
          color: 'rgba(0, 0, 0, .5)',
        },
      },
      input: {
        //padding: '1.875rem 1.0625rem 0.8125rem 1.0625rem',
        padding: '2rem 1.0625rem 0.8125rem 1.0625rem',
        backgroundColor: 'transparent!important',
        '&.MuiFilledInput-inputAdornedEnd': {
          paddingRight: '1.0625rem', //2.2rem', //'1.0625rem',
        },
      },
      multiline: {
        padding: '1.875rem 1.0625rem 0.8125rem 1.0625rem',
      },
      underline: {
        '&:before': {
          top: 0,
          borderRadius: '0.3125rem',
          border: '1px solid #F2F4F5',
          borderBottom: '1px solid #F2F4F5',
        },
        '&:after': {
          display: 'none',
        },
        '&:hover:before': {
          borderBottom: '1px solid #F2F4F5',
        },
        '&$focused': {
          '&:before': {
            border: '1px solid #DDDFE0',
          },
        },
        '&$error:before': {
          borderColor: '#DD5656',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        paddingLeft: '0',
        position: 'absolute',
        lineHeight: 1,
        maxWidth: '100%',
        marginTop: '.25rem',
        //top: '70px',
      },
    },
    MuiCheckbox: {
      root: {
        padding: '12px',
        fontSize: '2rem',
        '& .MuiSvgIcon-root': {
          fontSize: '2rem',
        },
        '& .MuiSvgIcon-fontSizeSmall': {
          fontSize: '1.3125rem',
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        lineHeight: 1,
        position: 'absolute',
        background: 'none',
        height: '1.125rem',
        right: '0.9375rem',
        top: 'calc(50% - 0.5625rem)',
        '& .MuiSvgIcon-root': {
          fontSize: '1.125rem',
        },
      },
    },

    MuiFormControlLabel: {
      root: {
        '&.not-inline': {
          padding: '.64rem .5rem',
          border: '1px solid #DDDFE0',
          borderRadius: '5px',
          marginBottom: '2rem',
          marginRight: '0',
          '&:last-child': {
            marginBottom: '0',
          },
        },
      },
    },

    MuiCssBaseline: {
      '@global': {
        ':focus': {
          outline: 'none' /* -webkit-focus-ring-color auto 1px;*/,
        },
        html: {
          WebkitFontSmoothing: 'auto',
          height: '100%',
        },
        body: {
          backgroundColor: '#EFF2F4',
          height: '100%',
          '& > div#root': {
            height: '100%',
          },
        },
      },
    },
    // dropzone
    MuiDropzoneArea: {
      icon: {
        display: 'none',
      },
      root: {
        display: 'flex',
        alignItems: 'center',

        minHeight: '148px',
        borderColor: '#74A3C7',
        borderWidth: '0px',
        backgroundImage:
          "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2374A3C7FF' stroke-width='1' stroke-dasharray='12,7' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\")",

        '&.success': {
          cursor: 'pointer',
          border: '1.5px solid #5EBB47',
          position: 'relative',
          background: 'url(/images/ok.svg) no-repeat 97% 50%',
        },
      },
      text: {
        fontWeight: 'normal',
        fontSize: '1rem',
        lineHeight: 1.1875,
        color: '#4F4F4F',
        marginTop: '0',
        marginBottom: '0',
        padding: '0 30px 0 148px',
        textAlign: 'left',
      },
      active: {
        background:
          "#D6E4EF url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2374A3C7FF' stroke-width='1' stroke-dasharray='12,7' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\") !important",
        //backgroundColor: '#D6E4EF !important',
        borderColor: '#74A3C7',
        borderWidth: '0',
        '&::after': {
          display: 'none!important',
        },
        '& $textContainer': {
          width: '100%',
        },
        '& $textContainer::after': {
          content: '"Перетащите файлы сюда"',
          display: 'block',
          position: 'absolute',
          top: '50%',
          marginTop: '-.5em',
          left: '0',
          right: '0',
          textAlign: 'center',
        },
        '& $text': {
          display: 'none',
          width: '100%',
          padding: '0 30px',
          textAlign: 'center !important',
        },
      },
    },
    MuiDropzonePreviewList: {
      root: {
        margin: 0,
        width: '100px',
        position: 'absolute',
        left: '25px',
        top: '50%',
        height: '100px',
        marginTop: '-50px',
        background: '#fff',
        zIndex: '999',
      },
      imageContainer: {
        padding: '0 !important',
        maxWidth: '100% !important',
        flexBasis: '100% !important',
      },
      image: {},
    },
    MuiFormControl: {
      marginDense: {
        margin: '0 !important',
      },
    },
    MuiTableContainer: {
      root: {
        overflowX: 'visible',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
    },
    MuiDialog: {
      paper: {
        overflowY: 'visible',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '32px 48px',
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: '1.75rem',
        margin: '0 0 1.625rem',
        padding: '2.25rem 2.375rem 0',
      },
    },
    MuiDialogContent: {
      root: {
        margin: '1.625rem 0 0',
        padding: '0 2.375rem 2.375rem',
        overflowY: 'visible',
      },
    },
    MuiTable: {
      root: {
        tableLayout: 'fixed',
        '& td': {
          wordWrap: 'break-word !important',
        },
      },
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer',
        /*
                '&:nth-of-type(even)': {
                    backgroundColor: '#F2F4F5',//theme.palette.action.hover,
                },
                */
        '&.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: '#D6EED1',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: '#F3F3F3',
        '&.row-actions': {
          textAlign: 'right',
        },
      },
      head: {
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 1.25,
        letterSpacing: '0.02em',
        color: '#828282',
      },
      body: {
        '& .MuiButton-containedSizeSmall': {
          height: 'auto',
          fontSize: '.875rem',
          lineHeight: 1,
          width: 'auto',

          '& .MuiButton-label': {
            width: 'auto',
            padding: '.2rem',
          },
        },
        '& i': {
          color: '#A6ACB3',
          fontStyle: 'normal',
        },
      },
      footer: {
        border: 0,
      },
    },
    MuiTablePagination: {
      root: {
        color: '#828282',
      },
      toolbar: {
        paddingTop: '0px',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#F2F4F5',
      },
      barColorPrimary: {
        backgroundColor: '#74A3C7',
      },
    },
    MuiAvatar: {
      root: {
        '& .MuiSvgIcon-root': {
          fontSize: '1rem',
        },
      },
      colorDefault: {
        backgroundColor: '#EFF2F4',
      },
    },
    MuiSelect: {
      filled: {
        borderRadius: '0.3125rem 0.3125rem 0 0 ',
      },
      iconFilled: {
        fontSize: '.625rem',
        top: 'calc(50% - 5px)',
        right: '1rem',
        '&$filled': {
          right: '1rem',
        },
      },
      icon: {
        width: '9px',
        rigyht: '4px',
      },
    },
    MuiMenu: {
      paper: {
        marginTop: '.3125rem',
        //borderRadius: '0',
        //minWidth: '608px !important',
        //transformOrigin: '610px 0px !important',
        //transform: 'translate(1px, -1px) !important',
        padding: 0,
        backgroundColor: 'none',
        boxShadow: '0px 10px 10px rgba(205, 205, 205, 0.5)',
        borderRadius: '0.3125rem',
      },
      list: {
        padding: '0',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1rem',
        lineHeight: '1.1875rem',
        background: '#FFFFFF',
        border: '1px solid #DADADA',
        borderTop: '0',
        borderBottom: '0',
        margin: '0',
        borderColor: '#EAECEE',
        borderRadius: '0', //'0.3125rem ',
        //borderRadius: 0,
        //transition: 'none',
        '&$selected,&:hover': {
          //background: '#DADADA',
          //padding: '1.4375rem 1.0625rem',
          //padding: '0.6875rem 1.0625rem 0.625rem',
        },

        /*
                '&:hover, &:focus, &:active': {
                    background: '#DADADA',
                    //padding: '1.4375rem 1.0625rem',
                    padding: '0.6875rem 1.0625rem 0.625rem',
                },
                '&:first-child': {
                    //borderRadius: '0',
                },
                */
        '&:first-child': {
          borderRadius: '0.3125rem 0.3125rem 0 0',
          //border: '1px solid #DADADA',
          borderTop: '1px solid #DADADA',
        },
        '&:last-child': {
          borderRadius: '0 0 0.3125rem 0.3125rem',
          borderBottom: '1px solid #DADADA',
        },
        '&:hover': {
          background: '#DADADA',
          borderColor: '#DADADA',
        },
        '&$selected': {
          background: '#F6F7F8',
          borderColor: '#F6F7F8',
        },
        '&$selected&:hover': {
          background: '#DADADA',
          borderColor: '#DADADA',
        },
      },
      dense: {},
      gutters: {
        background: '#FFFFFF',
        padding: '0.6875rem 1.0625rem 0.625rem',
      },
    },
    MuiListItem: {
      root: {
        padding: '1rem',
      },
      divider: {
        borderBottomColor: '#EEEEEE',
      },
    },
    MuiListItemText: {
      root: {
        fontWeight: 400,
        '&.inverted .MuiListItemText-primary': {
          color: '#757575',
          fontSize: '.875rem',
        },
        '&.inverted .MuiListItemText-secondary': {
          fontSize: '1rem',
          color: '#000',
        },
      },
      primary: {
        color: '#000',
        fontSize: '1rem',
        margin: '.25rem 0',
      },
      secondary: {
        color: '#757575',
        fontSize: '.875rem',
        marginBottom: '.25rem 0',
      },
    },
  },
};

const theme = createTheme(params, ruRU);

// breakpoints
//console.log(theme.overrides.MuiMenuItem.root, theme.breakpoints.up('sm'));
const sm = theme.breakpoints.up('sm');
//console.log(theme.overrides.MuiMenuItem.dense[theme.breakpoints.up('sm')])
theme.overrides.MuiMenuItem.root[sm] = { minHeight: '4rem' };
theme.overrides.MuiMenuItem.dense[sm] = { minHeight: '2rem' };

export default theme;
