import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { Toolbar, Button } from 'libs/ui';
import { StyledDialog, StyledDialogContent, IconPlace } from './dialog.styled';

export { IconPlace };

export const Dialog = (props) => {
  const { children, title, open, actions, buttons, withToolbar, toolbarContent, $big, $xbig } = props;

  const handleClose = () => {
    props.onClose();
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let maxWidth = $big ? 'md' : 'sm';
  if ($xbig) {
    maxWidth = 'lg';
  }

  const buttonsEl = buttons.length
    ? buttons.map((el, idx) => {
        return (
          <Button key={idx} color={'primary'} onClick={el.action}>
            {el.title}
          </Button>
        );
      })
    : null;

  return (
    <StyledDialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      scroll={'body'} //paper
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      $withToolbar={withToolbar}
    >
      <Toolbar empty={!withToolbar} actions={actions} handleClose={handleClose}>
        {toolbarContent}
      </Toolbar>
      {title && <DialogTitle disableTypography={true}>{title}</DialogTitle>}
      <StyledDialogContent>{children}</StyledDialogContent>
      {buttons.length > 0 && <DialogActions>{buttonsEl}</DialogActions>}
    </StyledDialog>
  );
};

Dialog.defaultProps = {
  open: false,
  $big: false,
  $xbig: false,
  timeout: 300,
  actions: [],
  buttons: [],
  withToolbar: true,
  toolbarContent: null,
};

Dialog.propTypes = {
  toolbarContent: PropTypes.node,
  actions: PropTypes.array,
  buttons: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  $big: PropTypes.bool,
  $xbig: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  withToolbar: PropTypes.bool,
};

export default Dialog;
