import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  EditedIcon,
  OperationsPassageIcon,
  OperationsTopupIcon,
  OperationsCorrectIcon,
  OperationsReturnIcon,
  OperationsPurchaseIcon,
} from 'libs/icons/icons';
import { config } from 'app/config';
const { consts } = config;

const IconPlace = styled.div`
  position: relative;
  width: ${(props) => (props.$big ? '4rem' : '2.5rem')};
  height: ${(props) => (props.$big ? '4rem' : '2.5rem')};
  background: ${(props) => (props.$suspected ? '#FFE19B' : '#EFF2F4')};
  color: #9299a2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .main-icon {
    font-size: ${(props) => (props.$big ? '2rem' : '1rem')};
  }
  .second-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: ${(props) => (props.$big ? '1.3rem' : '1rem')};
  }
`;

export const OperationIcon = (props) => {
  const getIcon = (type) => {
    let out;
    switch (type) {
      default:
      case 'JOURNEY':
      case 'PASSAGE':
        out = OperationsPassageIcon;
        break;
      case 'BANK_TOPUP':
      case 'TOPUP':
        out = OperationsTopupIcon;
        break;
      case 'ISSUE_A_LABEL':
        out = OperationsPurchaseIcon;
        break;
      case 'RETURN':
        out = OperationsReturnIcon;
        break;
      case 'CORRECTION':
        out = OperationsCorrectIcon;
        break;
      case 'BALANCE_CORRECTION':
        out = OperationsCorrectIcon;
        break;
    }
    return out;
  };

  const { type, suspected, corrected, big } = props;
  const Icon = getIcon(type);
  const title = consts.opTypesLabels[type];

  return (
    <IconPlace title={title} $suspected={suspected} $big={big}>
      <Icon className="main-icon" title={title} />
      {corrected && <EditedIcon className="second-icon" />}
    </IconPlace>
  );
};

OperationIcon.propTypes = {
  type: PropTypes.string.isRequired,
  big: PropTypes.bool,
  suspected: PropTypes.bool,
  corrected: PropTypes.bool,
};

OperationIcon.defaultProps = {
  suspected: false,
  corrected: false,
  big: false,
};

export default OperationIcon;
