import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';

import { Files, FormFooter, FormGroup, FormHeader, FormInfo, FormLegend, FormTitle } from './blocks';
export { FormFooter, FormHeader, FormGroup, FormInfo, FormLegend, FormTitle, Files };

export const FormGrid = styled(Grid)`
  padding: 0 0 2rem;
`;

export const Block = styled.div`
  margin: 0 0 3.625rem;
  &:last-child {
    margin: 0;
  }
`;
export const BlockTitle = styled.div`
  margin: 0 0 ${(props) => (props.$noMargin ? '0.625rem' : '1.5rem')};
  font-weight: 500;
  color: #000;
  font-size: 1.5rem;
  line-height: 1.208333333333333;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & a {
    margin-left: 0.875rem;
  }
`;
export const BlockSubTitle = styled.div`
  font-size: 1.125rem;
  margin: 0 0 1rem;
`;

export const Status = styled.div`
  position: relative;
  padding-left: 1.1875rem;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: -3.5px 0 0;
    background: #00a0dd;
    ${(props) =>
      props.$type == 'ACTIVE' &&
      css`
        background: #5ebb47;
      `}
    ${(props) =>
      props.$type == 'SUSPENDED' &&
      css`
        background: #dd5656;
      `}        
        ${(props) =>
      props.$type == 'BLOCKED' &&
      css`
        background: #dd5656;
      `}        
        ${(props) =>
      props.$type == 'DELETED' &&
      css`
        background: #fab619;
      `}        
        ${(props) =>
      props.$type == 'RESERVATION' &&
      css`
        background: #999;
      `}
  }
`;

export const StatusLabel = styled.div`
  ${(props) =>
    props.$type == 'PROCESSED' &&
    css`
      color: #5ebb47;
    `}
  ${(props) =>
    props.$type == 'ENROLLED' &&
    css`
      color: #dd5656;
    `}        
    ${(props) =>
    props.$type == 'NOENROLLED' &&
    css`
      color: #dd5656;
    `}        
    ${(props) =>
    props.$type == 'ARCHIVE' &&
    css`
      color: #fab619;
    `}
`;

export const BottomLinks = styled.div`
  text-align: right;
  margin: 3rem 0 0;
`;

export const ShortContent = styled.div`
  max-width: 664px;
`;

export const ErrorTitle = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.222;
  margin: 0 0 1.75rem;
`;
export const Error = styled.div`
  color: #000;
  font-size: 1rem;
  line-height: 1.1875;
  margin: 0 0 0.3rem;
`;

export const Content = styled.div``;
