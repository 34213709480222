import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';

import { Container } from 'libs/ui';
import theme from 'themes/themeBackend';
import Header from './Header';
import Footer from './Footer';
import { Backend } from './LayoutBackend.styled';

export const LayoutBackend = (props) => {
  const { readyForBackend, children } = props;
  const userLogined = useSelector((state) => state.user.userLogined);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const location = useLocation();
  let doNavigate = false;

  useEffect(() => {
    if (!readyForBackend /* && (['/confirmEmail', '/restorepass', '/registration', '/prefill'].indexOf(rest.path) === -1)*/) {
      console.log(' >> /login');
      if (!window.continueLinkAfterLogout) {
        window.continueLink = location;
      }
      window.continueLinkAfterLogout = false;
      console.log('set link old ', location);
      doNavigate = true;
      navigate('/login');
    }
  }, [readyForBackend]);

  if (doNavigate) {
    return null;
  }
  
  //console.log('🚀 ~ LayoutBackend', theme.overrides.MuiCssBaseline['@global'].body.backgroundColor);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Backend>
          <CssBaseline />
          <Header user={user} userLogined={userLogined} $fluid />
          <Container $main $fluid>
            {children}
          </Container>
          <Footer />
        </Backend>
      </StylesProvider>
    </ThemeProvider>
  );
};

LayoutBackend.propTypes = {
  children: PropTypes.node,
  readyForBackend: PropTypes.bool.isRequired,
  root: PropTypes.bool,
};

export default LayoutBackend;
