import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'libs/ui';
import { Actions, MenuButton, MenuIcon, MenuPlace } from './popup-menu.styled';

export class PopupMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (!this.wrapperRef || !this.wrapperRef.current) {
      return;
    }
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.hideActions();
    }
  };

  toggleActions = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState((state) => {
      return { show: !state.show };
    });
  };

  hideActions = () => {
    this.setState({ show: false });
  };

  doAction = (__ignored, action) => {
    this.setState({ show: false });
    action();
  };

  render() {
    const { actions } = this.props;
    const { show } = this.state;

    const items = actions.map((el, idx) => {
      const params = {};
      if (el.action) {
        params.onClick = (event) => this.doAction(event, el.action);
      }
      if (el.to) {
        params.to = el.to;
      }
      return (
        <Button {...params} className="toolbar-btn" $color="link" key={idx}>
          {el.title}
        </Button>
      );
    });

    if (!items.length) {
      return null;
    }

    return (
      <MenuPlace ref={this.wrapperRef}>
        <MenuButton onClick={this.toggleActions}>
          <MenuIcon>
            <i></i>
            <i></i>
            <i></i>
          </MenuIcon>
        </MenuButton>
        {show && <Actions>{items}</Actions>}
      </MenuPlace>
    );
  }
}

PopupMenu.propTypes = {
  actions: PropTypes.array.isRequired,
};

export default PopupMenu;
