import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { StyledLink } from './link.styled';

export const Link = (props) => {
  const { action, actionParams, children, to, ...rest } = props;

  const startAction = (event) => {
    event.stopPropagation();
    action(actionParams);
  };

  const routerMode = to !== undefined;
  if (routerMode) {
    rest.component = RouterLink;
    rest.to = to;
  }

  if (action) {
    rest.onClick = startAction;
  }

  return <StyledLink {...rest}>{children}</StyledLink>;
};

Link.propTypes = {
  $upperCase: PropTypes.bool,
  to: PropTypes.string,
  action: PropTypes.string,
  actionParams: PropTypes.object,
  children: PropTypes.node,
  color: PropTypes.string,
  underline: PropTypes.oneOf(['none', 'hover', 'always']),
};

Link.defaultProps = {
  $upperCase: false, // always button none
  $color: '#74A3C7', // secondary textPrimary textSecondary
  underline: 'hover', // always button none
};

export default Link;
