import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { DownloadIcon, UploadIcon } from 'libs/icons/icons';
import { StyledButton } from './button.styled';

export const Button = (props) => {
  const { action, actionParams, to, icon, ...rest } = props;

  const startAction = (event) => {
    event.stopPropagation();
    action(actionParams);
  };

  const routerMode = to !== undefined;
  if (routerMode) {
    rest.component = RouterLink;
    rest.to = to;
  }

  if (action) {
    rest.onClick = startAction;
  }

  if (icon) {
    if (icon === 'plus') {
      rest.variant = 'contained';
      rest.startIcon = <AddCircleIcon />;
    } else if (icon === 'download') {
      rest.variant = 'contained';
      rest.startIcon = <DownloadIcon />;
    } else if (icon === 'upload') {
      rest.variant = 'contained';
      rest.startIcon = <UploadIcon />;
    } else if (icon === 'submit') {
      rest.variant = 'contained';
      rest.startIcon = <CheckIcon />;
    } else if (icon === 'cancel') {
      rest.variant = 'contained';
      rest.startIcon = <CloseIcon />;
    } else if (icon === 'close') {
      rest.startIcon = <CloseIcon />;
    } else {
      rest.startIcon = icon;
    }
  }

  if (rest.color === 'green' || rest.color === 'cyan') {
    rest.$color = rest.color;
    rest.color = 'primary';
  }
  if (rest.color === 'outline') {
    rest.$color = rest.color;
    rest.color = 'primary';
  }

  return <StyledButton {...rest}>{props.children}</StyledButton>;
};

Button.propTypes = {
  size: PropTypes.oneOf(['large', 'small']),
  color: PropTypes.oneOf(['green', 'outline', 'primary', 'secondary', 'inherit', 'default', 'cyan']),
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  children: PropTypes.node,
  icon: PropTypes.any, //PropTypes.oneOf(['plus', 'download', 'submit', 'cancel', 'close', '', PropTypes.any]),
  $color: PropTypes.oneOf(['green', 'outline', 'link']),
  $onlyicon: PropTypes.bool,
  tabIndex: PropTypes.number,
  type: PropTypes.string,

  //onlyIcon: PropTypes.bool,
  component: PropTypes.string,
  action: PropTypes.func,
  actionParams: PropTypes.any,
  to: PropTypes.string,
  className: PropTypes.string,
  $nopad: PropTypes.bool,
  $mr: PropTypes.bool,
};

Button.defaultProps = {
  size: 'large',
  icon: '',
  children: '',
  component: 'button',
  //color: undefined,
  variant: 'contained',
  $onlyicon: false,
  $nopad: false,
  $mr: false,
};

export default Button;
