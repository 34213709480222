import PropTypes from 'prop-types';
import styled from 'styled-components';

import FileLoader from './file-loader';
const FilesContent = styled.div`
  padding: ${(props) => (props.admin ? '0 0 0 4.25rem' : '0')};
`;

export const Files = (props) => {
  const items = props.docs.map((el, idx) => {
    const file = props.files.find((val) => {
      return val.type_code === el.code;
    });

    return <FileLoader key={idx} file={file} item={el} first={idx === 0} last={idx === props.docs.length - 1} />;
  });

  return <FilesContent admin={props.admin}>{items}</FilesContent>;
};

Files.propTypes = {
  admin: PropTypes.bool,
  docs: PropTypes.array.isRequired,
  files: PropTypes.array.isRequired,
};

Files.defaultProps = {
  admin: false,
};

export default Files;
