import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Link, Icon } from 'libs/ui';

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  a,
  button {
    margin-left: 0.5rem;
  }
`;

class TableActions extends React.PureComponent {
  getActions = () => {
    const { actions, defaultColor } = this.props;

    let color, to, onClick, key;
    return actions.map((item, idx) => {
      key = 'table-action-' + idx;
      color = item.color ? item.color : defaultColor;
      to = typeof item.action === 'function' ? undefined : item.action;
      onClick = typeof item.action === 'function' ? item.action : undefined;
      //console.log(to)
      return (
        <Link key={key} color={color} to={to} onClick={onClick}>
          {item.icon && (
            <>
              <Icon kvsize="20px" size="20px" type={item.icon} />
              &nbsp;{' '}
            </>
          )}
          {item.title}
        </Link>
      );
    });
  };

  render() {
    const actions = this.getActions();

    console.log('actions render');

    return <Actions>{actions}</Actions>;
  }
}

TableActions.propTypes = {
  defaultColor: PropTypes.string,
  actions: PropTypes.array.isRequired,
};
TableActions.defaultProps = {
  defaultColor: 'success',
};

export default TableActions;
