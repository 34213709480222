import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & .MuiIconButton-label {
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

`;

export const MenuStyled = styled(Menu)`
  .MuiListItem-root {
    text-align: center;
    justify-content: center;
  }
`;

export const UserName = styled.div`
  font-weight: 500;
  font-size: 0.6875rem;
  line-height: 1.181818181818182;
  text-align: right;
  text-transform: uppercase;
  padding: 0 1rem;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    background: #c4c4c4;
    margin-top: -0.6875rem;
    height: 1.375rem;
  }
`;
