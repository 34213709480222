import userService from 'services/userService';

const defaultState = { readyForBackend: false, userChecked: false, userLogined: false, user: {}, root: false };

const userReducer = function (state = defaultState, action) {
  switch (action.type) {
    case 'USER_CHECKED':
      return Object.assign({}, state, { userChecked: action.value, userLogined: action.result });

    case 'SET_USER':
      return Object.assign({}, state, {
        userChecked: true,
        user: action.user,
        root: userService.isRoot(action.user),
        userLogined: true,
        readyForBackend: userService.isUserFilled(action.user),
      });

    case 'UNSET_USER':
      return Object.assign({}, state, { user: {}, userLogined: false, readyForBackend: false });

    default:
      return state;
  }
};

export default userReducer;
