import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
moment.locale('ru');
import TextField from '@material-ui/core/TextField';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Button } from 'libs/ui';

const customLocale = {
  // months list by order
  months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],

  // week days by order
  weekDays: [
    {
      name: 'Sunday', // used for accessibility
      short: 'S', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
    {
      name: 'Monday',
      short: 'M',
    },
    {
      name: 'Tuesday',
      short: 'T',
    },
    {
      name: 'Wednesday',
      short: 'W',
    },
    {
      name: 'Thursday',
      short: 'T',
    },
    {
      name: 'Friday',
      short: 'F',
    },
    {
      name: 'Saturday',
      short: 'S',
      isWeekend: true,
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 6,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Следующий месяц',
  previousMonth: 'Предыдущий месяц',
  openMonthSelector: 'Открыть выбор месяца',
  openYearSelector: 'Открыть выбор года',
  closeMonthSelector: 'Закрыть выбор месяца',
  closeYearSelector: 'Закрыть выбор года',
  defaultPlaceholder: 'Выберите...',

  // for input range value
  from: 'from',
  to: 'to',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
};

const Place = styled.div`
  position: relative;
`;
const PlaceCalendar = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1rem 1rem;
`;

const Menu = styled.div`
  padding: 1.25rem 0 0;
  background: #fff;
  box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.06), -0.25rem -0.25rem 0.625rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  max-width: 20rem;
`;
const MenuItem = styled.a`
  display: block;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: 0.0357143em;
  color: #101820;
  transition: 0.2s all ease-in;
  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
const Last = styled.div`
  margin: 1.125rem 0 0;
  padding: 0.5625rem 0 0.75rem;
  border-top: 1px solid #dddfe0;
`;

const fillMenu = () => {
  const today = moment();
  const today1 = today.clone().add(1, 'd');
  const yesterday = today.clone().subtract(1, 'day');
  const startWeek = today.clone().weekday(0);
  const endWeek = today.clone().weekday(7);
  const last7days = today.clone().subtract(7, 'days');
  const last30days = today.clone().subtract(30, 'days');
  const lastMonth1 = today.clone().date(1).subtract(1, 'month'); //.date(1);
  const lastMonth2 = lastMonth1.clone().add(1, 'month');

  return [
    {
      title: 'Любые даты',
      date: {
        from: null,
        to: null,
      },
    },
    {
      title: 'Сегодня',
      date: {
        from: { day: today.date(), month: today.month(), year: today.year() },
        to: { day: today1.date(), month: today1.month(), year: today1.year() },
      },
    },
    {
      title: 'Вчера',
      date: {
        from: { day: yesterday.date(), month: yesterday.month(), year: yesterday.year() },
        to: { day: today.date(), month: today.month(), year: today.year() },
      },
    },
    {
      title: 'Текущая неделя',
      date: {
        from: { day: startWeek.date(), month: startWeek.month(), year: startWeek.year() },
        to: { day: endWeek.date(), month: endWeek.month(), year: endWeek.year() },
      },
    },
    {
      title: 'Последние 7 дней',
      date: {
        from: { day: last7days.date(), month: last7days.month(), year: last7days.year() },
        to: { day: today.date(), month: today.month(), year: today.year() },
      },
    },
    /*
        {title: 'Последняя неделя', date: {
            from: {day: yesterday.date(), month: yesterday.month() + 1, year: yesterday.year()}, 
            to: {day: today.date(), month: today.month() + 1, year: today.year()}
        }},*/
    {
      title: 'Последние 30 дней',
      date: {
        from: { day: last30days.date(), month: last30days.month(), year: last30days.year() },
        to: { day: today.date(), month: today.month(), year: today.year() },
      },
    },
    {
      title: 'Последний месяц',
      date: {
        from: { day: lastMonth1.date(), month: lastMonth1.month(), year: lastMonth1.year() },
        to: { day: lastMonth2.date(), month: lastMonth2.month(), year: lastMonth2.year() },
      },
    },
  ];
};

const getTextValue = (value) => {
  //console.log('getTextValue', value)

  const newValue = JSON.parse(JSON.stringify(value));
  if (newValue.from) {
    //newValue.from.month--;
  }
  if (newValue.to) {
    //newValue.to.month--;
  }
  let out = '';
  if (newValue.from) {
    out += moment(newValue.from).format('DD MMM YYYY');
  }
  if (newValue.to) {
    out += ' - ' + moment(newValue.to).format('DD MMM YYYY');
  }
  return out;
};

export class DateRangeMUI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateRange: { from: null, to: null },
      open: false,
      openCalendar: false,
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (!this.wrapperRef || !this.wrapperRef.current) {
      return;
    }
    if (!this.wrapperRef.current.contains(event.target)) {
      if (this.state.open !== false || this.state.openCalendar !== false) {
        this.setState({ open: false, openCalendar: false });
      }
    }
  };

  changeOpen = () => {
    this.setState((state) => {
      return { open: !state.open, openCalendar: false };
    });
  };

  setInput = (val) => {
    const { dateRange } = this.state;
    const { onChange } = this.props;

    console.log('setInput', val)
    if (val === undefined) {
      val = JSON.parse(JSON.stringify(dateRange));
    }
    const out = { ...val };
    //out.from.month ++;
    //out.to.month ++;
    onChange(out);
    this.setState({ open: false, openCalendar: false, dateRange: out });
  };

  render() {
    const { open, openCalendar, dateRange } = this.state;
    const { value, name, ...other } = this.props;
    const dateValues = fillMenu();

    const textValue = getTextValue(value);
    other.InputProps.endAdornment = (
      <InputAdornment position="end">
        <CalendarTodayIcon />
      </InputAdornment>
    );

    // menu
    const menu = dateValues.map((item, idx) => {
      return (
        <MenuItem
          onClick={() => {
            //setDateRange(item.date);
            this.setInput(item.date);
          }}
          key={idx}
        >
          {item.title}
        </MenuItem>
      );
    });
    menu.push(
      <Last key="last">
        <MenuItem onClick={() => this.setState({ openCalendar: true })}>Указать период...</MenuItem>
      </Last>
    );

    const changedDateRange = JSON.parse(JSON.stringify(dateRange));
    //console.log('🚀 ~ render ~ changedDateRange', changedDateRange);
    //console.log('changedDateRange', changedDateRange);
    if (changedDateRange.from && changedDateRange.from.month) {
      changedDateRange.from.month++;
    }
    if (changedDateRange.to && changedDateRange.to.month) {
      changedDateRange.to.month++;
    }

    //console.log('render', dateRange)
    return (
      <Place ref={this.wrapperRef}>
        <TextField
          {...other}
          onFocus={this.changeOpen}
          //onBlur={this.onBlur}
          name={name}
          value={textValue}
          onChange={null} //() => { }
          onClick={this.changeOpen}
          disabled={true}
        />

        {open && (
          <PlaceCalendar>
            {!openCalendar ? (
              <Menu>{menu}</Menu>
            ) : (
              <Calendar
                locale={customLocale}
                value={changedDateRange}
                onChange={(val) => {
                  //console.log('ttt',val)
                  const out = JSON.parse(JSON.stringify(val));
                  if (out.from) {
                    out.from.month--;
                  }
                  if (out.to) {
                    out.to.month--;
                  }

                  this.setState({ dateRange: out });
                }}
                renderFooter={() => {
                  return (
                    <Buttons>
                      <Button $color="link" onClick={() => this.setInput()}>
                        Применить
                      </Button>
                    </Buttons>
                  );
                }}
                shouldHighlightWeekends
              />
            )}
          </PlaceCalendar>
        )}
      </Place>
    );
  }
}

DateRangeMUI.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

DateRangeMUI.defaultProps = {
  value: { from: null, to: null },
};

export default DateRangeMUI;
