import { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from 'libs/ui';
import { MenuIcon } from 'libs/icons/icons';
import { Actions, CloseButton, Place } from './toolbar.styled';

export const Toolbar = (props) => {
  const { actions, children, empty } = props;
  const [show, setShow] = useState(false);
  const noActions = actions.length === 0;

  const toggleActions = () => {
    if (noActions) {
      return;
    }
    const newVal = !show;
    setShow(newVal);
  };

  const doAction = (__event, action) => {
    setShow(false);
    action();
  };

  const items = actions.map((el, idx) => {
    return (
      <Button disabled={noActions} className="toolbar-btn" $color="link" onClick={(event) => doAction(event, el.action)} key={idx}>
        {el.title}
      </Button>
    );
  });

  if (empty) {
    return (
      <CloseButton $absolute aria-label="close" onClick={props.handleClose}>
        <CloseIcon />
      </CloseButton>
    );
  }

  return (
    <Place>
      {actions.length > 0 ? (
        <Button className="toolbar-big-btn" $color="link" icon={<MenuIcon />} onClick={toggleActions}>
          Действия
        </Button>
      ) : (
        <div></div>
      )}
      {show && <Actions>{items}</Actions>}

      {children}

      <CloseButton aria-label="close" onClick={props.handleClose}>
        <CloseIcon />
      </CloseButton>
    </Place>
  );
};

Toolbar.defaultProps = {
  empty: false,
  actions: [],
};

Toolbar.propTypes = {
  empty: PropTypes.bool,
  children: PropTypes.node,
  handleClose: PropTypes.func,
  actions: PropTypes.array,
};

export default Toolbar;
