import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
//import IconButton from '@material-ui/core/IconButton';

import * as common from 'services/common';
import { FinalForm } from 'libs/form';
import { InputFieldFinal } from 'libs/form';
import { SearchIcon } from 'libs/icons/icons';
import { Button } from 'libs/ui';
import { Filter, FilterControls, FilterItem, FilterItemLabel } from './styled';

class TableFilter extends React.Component {
    constructor(props) {
        super(props);

        // initial filters
        const current = {};
        props.filters.forEach((el) => {
            current[el.name] = el.initial ? el.initial : '';
        });
        this.initialFilters = current;

        this.state = {
            //withFilter: false,
            //active: false,
            filterExpanded: false,
            time: 0,
        };

        this.searchFields = props.searchFields;
        //this.fields = {};
        this.timer = null;
        this.data = {};
        //console.log('filters created', this.initialFilters)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!common.equalObjects(this.state, nextState)) {
            return true;
        }

        if (!common.equalObjects(this.props.filters, nextProps.filters)) {
            return true;
        }

        return false;
    }

    /**
     * get filters components for render
     */
    getFilters = () => {
        const { filterExpanded } = this.state;
        const { filters, maxFilters } = this.props;

        let cnt = 0;
        const out = filters.map((item) => {
            const { name, type, title, hideLabel, width, icon, ...rest } = item;
            cnt++;
            if (icon === 'search') {
                rest.endAdornment = (
                    <InputAdornment position="end">
                        {/*<IconButton aria-label="search" onClick={() => { }}>*/}
                        <SearchIcon />
                        {/*</IconButton>*/}
                    </InputAdornment>
                );
            }
            const title2 = 'Поиск';
            const hidden = cnt > maxFilters && !filterExpanded;

            if (['text', 'number'].includes(type)) {
                this.searchFields.push(name);
            }

            //console.log('rest', name, rest)

            return (
                <FilterItem key={name} $width={width} $hidden={hidden}>
                    <FilterItemLabel>{hideLabel ? '' : title}</FilterItemLabel>
                    <InputFieldFinal
                        hiddenLabel
                        margin="dense"
                        {...rest}
                        size="small"
                        key={name}
                        name={name}
                        type={type}
                        placeholder={title2}
                    />
                </FilterItem>
            );
        });
        return out;
    };

    /**
     * on filter field change
     *  submit filter form
     * @param {*} data
     */
    filterChange = (data) => {
        //console.log('table filter changed', data)
        clearTimeout(this.timer);
        const changedFields = [];
        this.props.filters.forEach((item) => {
            if (this.initialFilters[item.name] !== data[item.name]) {
                changedFields.push(item.name);
            }
        });

        let delayed = false;
        changedFields.forEach((item) => {
            if (this.searchFields.indexOf(item) !== -1) {
                delayed = true;
            }
        });

        this.data = { ...data };
        //console.log('changedFields', changedFields);

        // TODO: remove to params
        if (changedFields.includes('location')) {
            this.data.lane = '-1';
        }
        this.props.filters.forEach((filter) => {
            if (changedFields.includes(filter.name)) {
                if (filter.afterChange) {
                    this.data[filter.name] = filter.afterChange(
                        this.data[filter.name]
                    );
                    this.setState({ time: new Date().getMilliseconds() });
                    //console.log('filter', this.data[filter.name])
                }
            }
        });

        if (delayed) {
            this.timer = setTimeout(() => {
                //console.log('filter to', this.data.pan);
                this.doFilter(this.data);
            }, 300);
            return;
        }

        this.doFilter(this.data);
    };

    /*
    clearFilter = () => {
    
        this.props.filters.forEach((item) => {
            //console.log(item);
            if (this.fields[item].filterClear) {
                this.fields[item].filterClear(this.filterForm, item);
            } else {
                this.filterForm.setValue(item, '');
            }
        });
        this.setState({ withFilter: false, active: false });
    
        setTimeout(() => {
            this.filterForm.acceptForm();
        }, 150);
    }
    */

    doFilter = (data) => {
        //console.log('table filter submit', data)
        //console.log(data);
        let changed = false;
        const out = {};
        Object.keys(data).forEach((key) => {
            let item = data[key];
            if (item !== this.initialFilters[key]) {
                changed = true;
            }
            if (item === '-1') {
                item = '';
            }
            out[key] = item;
        });
        //this.setState({ active: changed });
        if (changed) {
            this.initialFilters = out;
            this.props.onFilterChange(out);
        }
    };

    /**
     * change filter and button state
     *
     * @memberof Operations
     */
    toggleFilter = () => {
        this.setState((state) => {
            return { filterExpanded: !state.filterExpanded };
        });
    };

    render() {
        const { filters, filtersLine, maxFilters } = this.props;
        const { /*withFilter, active,*/ filterExpanded } = this.state;
        //const cl = active ? 'with-filter' : '';

        if (!filters || filters.length === 0) {
            return null;
        }
        //console.log('fgdfgdfg', this.data)
        const current = this.data;
        filters.forEach((el) => {
            if (!current[el.name]) {
                current[el.name] = el.initial ? el.initial : '';
            }
        });

        const filterFields = this.getFilters();
        //const other = {className: this.props.filtersLine ? 'filters-line' : ''};

        const btnTitle = filterExpanded
            ? '↑ Свернуть фильтр'
            : '↓ Развернуть фильтр';
        const showControls = filters.length > maxFilters;

        //console.log('filters render', current)
        return (
            <Filter filtersLine={filtersLine}>
                {showControls && (
                    <FilterControls>
                        <Button
                            onClick={this.toggleFilter}
                            $color="link"
                            $nopad
                            size="small"
                        >
                            {btnTitle}
                        </Button>
                    </FilterControls>
                )}
                <FinalForm
                    onSubmit={this.doFilter}
                    onChange={this.filterChange}
                    initialValues={current}
                >
                    {filterFields}
                    {/*}
                    <FormGroup className="row-actions">
                        <Button onlyIcon title="Найти" size="medium" color="primary" type="submit"><Icon size="16px" kvsize="26px" type="search" /></Button>
                        <Button disabled={!withFilter} onlyIcon onClick={this.clearFilter} type="button" title="Очистить" size="medium" color="secondary"><Icon size="16px" kvsize="26px" type="clear" /></Button>
                    </FormGroup>
                    */}
                </FinalForm>
            </Filter>
        );
    }
}

TableFilter.propTypes = {
    filtersLine: PropTypes.bool,
    onFilterChange: PropTypes.func.isRequired,
    filters: PropTypes.array,
    maxFilters: PropTypes.number,
    searchFields: PropTypes.arrayOf(PropTypes.string),
};

TableFilter.defaultProps = {
    filtersLine: false,
    maxFilters: 8,
    searchFields: [],
};

export default TableFilter;
