import bstrApi from 'api/bstr';
//import { api } from 'api';
import * as common from 'services/common';
import { hmac } from 'services/hmac';
import validators from 'services/validators';
import clientService from './clientService';

const tokenName = 'user_token';
const tokenUserIdName = 'user_id';
const tokenClientIdName = 'client_id';
const tokenState = 'user_state';
import { config } from 'app/config';
const { consts } = config;

/**
 * service interaction with user api
 *
 * @class UserService
 */
class UserService {
  constructor() {
    this.state = {};
  }

  isLoggedIn() {
    return localStorage.getItem(tokenName) ? true : false;
  }

  getState() {
    let out = this.getToken(tokenState);
    //console.log('🚀 ~ UserService:getState', out);
    try {
      out = JSON.parse(out);
    } catch (error) {
      console.log('error', error, out);
      out = {};
      this.setState(out);
    }
    return out;
  }

  setState(state) {
    this.setToken(tokenState, JSON.stringify(state));
  }

  setToken(name, val) {
    localStorage.setItem(name, val);
    this.state[name] = val;
  }

  getToken(name = tokenName) {
    //console.log('user::getToken', this.state);
    if (!this.state[name]) {
      this.state[name] = localStorage.getItem(name);
    }
    return this.state[name];
  }

  /**
   * Check user token
   *
   * @param {*} next callback
   * @memberof User
   */
  init(next) {
    //console.log('user::init');
    //console.log('1a');
    new Promise((resolve) => {
      //console.log('2a');
      this.checkToken(resolve);
    }).then((result) => {
      //console.log('3a');
      if (!result) {
        //console.log('4a');
        this.logoutUser(false);
      }
      next(result);
    });
  }

  /**
   * Check user token
   *
   * @param {*} next
   * @memberof User
   */
  checkToken(next) {
    //console.log('user::checkToken');

    // if token exists - check for it valid
    if (!this.isLoggedIn()) {
      next(false);
    } else {
      if (config.localhost) {
        next(true);
      } else {
        this.refreshTokenUser().then(
          (result) => {
            this.initUser(result, false);
            next(true);
          },
          () => {
            this.logoutUser(false);
            next(false);
          }
        );
      }
    }
  }

  /**
   * Login request
   *
   * @param {*} data {alias, password}
   * @param {*} onSuccess
   * @param {*} onError
   * @memberof User
   */
  login(data, onSuccess, onError) {
    //console.log('user::login');

    //const recaptcha = '123';

    // login
    this.loginUser({
      alias: data.alias,
      merchant_key: config.API_KEY,
      password: data.password,
      recaptcha: data.token,
    }).then((result) => {
      //client_id: 3
      //expires: "2020-10-09T15:06:36Z"
      //user_id: 1
      //console.log(result)
      //const jwt = jwt_decode(result.access_token);
      //console.log('user::login', jwt);

      // save token and data
      this.initUser(result);
      onSuccess();
      //});
    }, onError);
  }

  logoutUser(__redirect = true, doNext) {
    //console.log('user::logout');
    this.state[tokenName] = '';
    this.state[tokenUserIdName] = '';
    this.state[tokenClientIdName] = '';
    localStorage.removeItem(tokenName);
    localStorage.removeItem(tokenUserIdName);
    localStorage.removeItem(tokenClientIdName);
    localStorage.removeItem(tokenState);

    if (doNext) {
      doNext();
    }
  }

  /**
   * get user information after login with root support
   * - userGet
   * - clientGet
   * - requestDocumentList
   * - contractGet
   *
   * @param {*} onSuccess
   * @param {*} onError
   * @memberof User
   */
  getUser = async (onSuccess, onError) => {
    //console.log('user::getUser');
    const user_id = this.getToken(tokenUserIdName);
    //console.log(user_id)

    // get user
    const out = await bstrApi.getUser(user_id).catch(onError);
    // const out = await api.get('/users/' + user_id).catch(onError);

    if (!out) {
      onError();
      return;
    }

    // admin
    if (out.org_type === consts.role.ROOT) {
      out.client = { contract: {}, accountObj: {}, docsTypes: {} };
      onSuccess(out);
      return;
    }

    // get client
    out.client = await clientService.getItem(out.client_id).catch(onError);
    onSuccess(out);
  };

  /**
   * get user object by it id
   * @param {*} id
   */
  getUserById = (id) => {
    return bstrApi.getUser(id);
  };

  /**
   * Save token to local
   *
   * @param {*} token
   * @memberof User
   */
  initUser(result) {
    console.log('user::loginUser(set token)', result);
    this.state[tokenName] = result.access_token;
    this.state[tokenUserIdName] = result.user_id;
    this.state[tokenClientIdName] = result.client_id;
    localStorage.setItem(tokenName, result.access_token);
    localStorage.setItem(tokenUserIdName, result.user_id);
    localStorage.setItem(tokenClientIdName, result.client_id);
    localStorage.setItem(tokenState, JSON.stringify({}));
  }

  /**
   * register user and get jwt key
   *
   * @data object form data
   * @orgType type of org
   * @memberof UserService
   */
  async registerUser(data, orgType) {
    let errors = {},
      out = {};

    const phone = data.phone === '+7' ? '' : validators.preparePhone(data.phone);
    const email = data.email;
    const password = hmac(data.new_password1);
    const captcha = 'test';

    const params = {
      org_type: orgType,
      phone: phone,
      email: email,
      password: password,
      recaptcha: captcha,
    };

    // register user
    await bstrApi
      .registerUser(params)
      .then((result) => {
        out = result;
      })
      .catch((result) => {
        errors = result;
      });

    if (!common.isEmptyObject(errors)) {
      return { errors };
    }

    // get client
    let client = {};
    await bstrApi
      .getClient(out.client_id)
      .then((result) => {
        client = result;
      })
      .catch((result) => {
        errors = result;
      });

    return { errors, client, phone };
  }

  /**
   * register user from admin
   *
   * @data object form data
   * @orgType type of org
   * @memberof UserService
   */
  async registerUserInstant(data, orgType) {
    let errors = {},
      out = {};

    const phone = data.phone === '+7' ? '' : validators.preparePhone(data.phone);
    const email = data.email;

    const params = {
      org_type: orgType,
      phone: phone,
      email: email,
    };

    // register user
    await bstrApi
      .registerUserInstant(params)
      .then((result) => {
        out = result;
      })
      .catch((result) => {
        errors = result;
      });

    if (typeof errors === 'string' && errors) {
      errors = [errors];
    }

    if (!common.isEmptyObject(errors)) {
      return { errors: errors };
    }

    console.log('🚀 ~ file: userService.js ~ line 305 ~ registerUserInstant ~ errors', errors);

    // get client
    let client = {};
    await bstrApi
      .getClient(out.client_id)
      .then((result) => {
        client = result;
      })
      .catch((result) => {
        errors = result;
      });

    return { errors, client, phone };
  }

  isRoot(user) {
    return user.org_type === consts.role.ROOT;
  }

  isUserFilled(user) {
    let out = false;

    // is root
    out = out || this.isRoot(user);
    if (out) {
      return out;
    }

    if (!user.client) {
      return out;
    }

    // filled
    // attachments
    let out1 = true;
    if (user.client.attachment_list && user.client.attachment_list.length) {
      user.client.attachment_list.forEach((el) => {
        if (!el.name) {
          out1 = false;
        }
      });
    } else {
      out1 = false;
    }

    // fields
    //console.log('userrrr', user)
    let out2 = [consts.contract.ACTIVE, consts.contract.CONTROL].includes(user.client.contract.status); //consts.client.ACTIVE;
    //out = out || (action.user.client.flag_init === '1' && action.user.contract.status === consts.contract.ACTIVE);

    let out3 = !!user.client.tariff_id;

    console.log('isUserFilled', out1, out2, out3);

    out = out || (out1 && out2 && out3);

    return out;
  }

  changePassword = (new1, old) => {
    const out = {
      old_password: hmac(old),
      new_password: hmac(new1),
    };
    return bstrApi.changeUserPassword(out);
  };

  /*******************************
   * new api
   *******************************/
  refreshTokenUser = () => {
    //return api.get('/users/refresh-token');
    return bstrApi.refreshTokenUser();
  };

  loginUser = async (params) => {
    //return await api.post('/users/login', params);
    return bstrApi.loginUser(params);
  };
}

export default new UserService();
