import styled, { css } from 'styled-components';

export const Content = styled.div`
  margin: 0 0 32px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 5px;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: ${props => props.minHeight}px;
`;

export const Left = styled.div`
  width: 40%;
`;

export const Right = styled.div`
  width: 40%;
`;
