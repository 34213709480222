import React from 'react';
import PropTypes from 'prop-types';

import TableFilter from './TableFilter';
import TableActions from './TableActions';
import { FilterActions } from './styled';

const TableTop = (props) => {

    const { actions, filters, filtersLine, maxFilters, title, preTitle, filterChange } = props;

    const showFilters = filters.length > 0;
    const showActions = actions.length > 0;
    const showFiltersActions = showFilters || showActions;
    if (!showFiltersActions) {
        return null;
    }

    return (
        <FilterActions>
            {showFilters && <TableFilter maxFilters={maxFilters} filtersLine={filtersLine} filters={filters} onFilterChange={filterChange} title={title} preTitle={preTitle} />}
            {showActions && <TableActions actions={actions} />}
        </FilterActions>
    );
};

TableTop.defaultProps = {
    actions: []
};

TableTop.propTypes = {
    actions: PropTypes.array,
    filters: PropTypes.array,
    filtersLine: PropTypes.bool,
    maxFilters: PropTypes.number,
    filterChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    preTitle: PropTypes.string,
};

export default TableTop;