import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLegend = styled.legend`
  margin: 0;
  padding: 0.5rem 0 0;
  font-size: 1rem;
  line-height: normal;
  font-weight: 500;
`;

export const FormLegend = (props) => {
  const { children } = props;
  return <StyledLegend>{children}</StyledLegend>;
};

FormLegend.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormLegend;
