import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ContainerMUI from '@material-ui/core/Container';

export const Container = styled(ContainerMUI)`
  max-width: 1008px;
  ${(props) =>
    props.$fluid &&
    css`
      max-width: 1280px;
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    `}
  ${(props) =>
    props.$main &&
    css`
      padding: 3.125rem 2.25rem 2rem;
    `}
    ${(props) =>
    props.$height100 &&
    css`
      display: block;
      flex-direction: column;
      flex-grow: 1;
      align-self: stretch;
      justify-self: stretch;
      padding-bottom: 0rem;
      height: calc(100% - 84px);
    `}
    @media(max-width: 959px) {
    padding: 0 1rem;
    background-color: #fff;
  }
`;

Container.defaultProps = {
  $fluid: false,
  $main: false,
};

Container.propTypes = {
  $fluid: PropTypes.bool,
  $main: PropTypes.bool,
  $height100: PropTypes.bool,
};

export default Container;
