import styled from 'styled-components';

export const IconHolder = styled.span`
  border-radius: 6px;
  display: inline-block;
  height: ${(props) => props.kvsize};
  left: 0;
  line-height: ${(props) => props.kvsize};
  position: relative;
  text-align: center;
  transition: all 0.3s ease;
  width: ${(props) => props.kvsize};
  font-size: ${(props) => props.size};
  & i {
    font-size: ${(props) => props.size};
    line-height: ${(props) => props.kvsize};
    transition: 0.3s all;
  }
`;
