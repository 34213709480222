import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';

import { Toolbar } from 'libs/ui';
import { CloseButton, Inner, InnerContent, StyledModal, StyledPaper, StyledTitle } from './modal.styled';

export const Modal = (props) => {
  const { children, title, timeout, open, $minWidth, $big, $leftbold, actions } = props;

  const handleClose = () => {
    props.onClose();
  };

  const withToolbar = actions.length > 0;

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: timeout,
      }}
    >
      <Fade in={open}>
        <StyledPaper $minWidth={$minWidth} $big={$big} $withToolbar={withToolbar}>
          {withToolbar && <Toolbar actions={actions} />}
          <CloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          {title && <StyledTitle $leftbold={$leftbold}>{title}</StyledTitle>}
          <Inner>
            <InnerContent>{children}</InnerContent>
          </Inner>
        </StyledPaper>
      </Fade>
    </StyledModal>
  );
};

Modal.defaultProps = {
  open: false,
  timeout: 300,
  $big: false,
  actions: [],
  $minWidth: '44rem',
  $leftbold: false,
};

Modal.propTypes = {
  $leftbold: PropTypes.bool,
  $big: PropTypes.bool,
  $minWidth: PropTypes.string,
  actions: PropTypes.array,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  timeout: PropTypes.number,
};

export default Modal;
