import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Button } from 'libs/ui';

const MenuStyled = styled(Menu)`
    .MuiListItem-root {
        text-align: center;
        justify-content: center;
    }
`;

const DownloadLinks = (props) => {

    const [menuopen, setMenuopen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
        setMenuopen(false);
    };
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuopen(true);
    };

    const downloadXLSX = () => {
        props.download('xlsx');
        handleClose();
    }
    const downloadCSV = () => {
        props.download('csv');        
        handleClose();
    }

    return (
        <>
            <Button size="small" onClick={handleOpen} /*component='a' href={link}*/ icon='download' $nopad={true} $color="link" $mr={true}>Скачать таблицу</Button>
            <MenuStyled
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={menuopen}
                onClose={handleClose}
            >
                <MenuItem dense onClick={downloadXLSX}>XLSX</MenuItem>
                <MenuItem dense onClick={downloadCSV}>CSV</MenuItem>
            </MenuStyled>
        </>
    );
}

DownloadLinks.propTypes = {
    download: PropTypes.func.isRequired,
};
export default DownloadLinks;