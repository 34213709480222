import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import MaskedInputMUI from './masked-input';
let oldInputValue = '';
let timer = null;

const AutocompleteMUI = (props) => {
  const def = props.def ? props.def : props.value;

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(def);
  const [inputValue, setInputValue] = React.useState(def);
  //const [realValue, setRealValue] = React.useState(props.value);
  const [options, setOptions] = React.useState(props.options);
  const [loading, setLoading] = React.useState(false);
  const fetcher = props.fetcher();

  const needRefresh = open && (options.length === 0 || (oldInputValue !== inputValue && inputValue.length >= props.minLength));

  React.useEffect(() => {
    if (needRefresh && !loading) {
      setLoading(true);
    }
  }, [needRefresh]);

  React.useEffect(() => {
    let active = true;

    if (!needRefresh) {
      return undefined;
    }

    if (inputValue === '' && options.length > 0) {
      setOptions([]); //value ? [value] : []);
      return undefined;
    }

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      oldInputValue = inputValue;
      fetcher(inputValue, props.name)
        .finally(() => {
          setLoading(false);
        })
        .then((results) => {
          if (active) {
            let newOptions = [];

            if (results) {
              newOptions = [...newOptions, ...results.items];
            }
            setOptions(newOptions);
          }
        })
        .catch(() => {
          setOptions([]);
        });
    }, props.fetchTimeout);

    return () => {
      active = false;
    };
  }, [needRefresh, inputValue]);

  const other = {};
  if (props.size) {
    other.size = props.size;
  }

  return (
    <Autocomplete
      {...other}
      //debug={true}
      autoComplete
      handleHomeEndKeys={false}
      disablePortal={true}
      popupIcon={null}
      loading={loading}
      loadingText="Загрузка..."
      noOptionsText="Ничего не найдено"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      disabled={props.disabled}
      value={value}
      id={props.name}
      name={props.name}
      onChange={(__ignored, newValue) => {
        const text = newValue && newValue['id'] ? newValue.id : newValue;
        const val = newValue && newValue['idReal'] ? newValue.idReal : text;
        setValue(text);

        if (props.onChange) {
          props.onChange(val, newValue);
        }
      }}
      onInputChange={(__ignored, newInputValue) => {
        //console.log('onInputChange:' + newInputValue)
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        const { inputProps, ...attr } = params;
        const { value, onChange, ...other } = inputProps;
        const name = props.name + '_';
        const attrTextField = { ...params };
        if (props.margin) {
          attrTextField.margin = props.margin;
        }
        if (props.size) {
          attrTextField.size = props.size;
        }
        return (
          <>
            {props.mask ? (
              <MaskedInputMUI
                {...attr}
                name={name}
                error={props.error}
                inputProps={other}
                onChange={onChange}
                value={value}
                label={props.label}
                mask={props.mask}
                variant="filled"
              />
            ) : (
              <TextField {...attrTextField} name={name} label={props.label} variant="filled" />
            )}
          </>
        );
      }}
      getOptionLabel={(option) => {
        //console.log(option)
        return typeof option === 'object' ? option.text : option;
      }}
      filterOptions={(options) => {
        return options;
      }}
      getOptionSelected={() => {
        return true;
      }}
      renderOption={(option) => {
        const matches = match(option.text, inputValue);
        const parts = parse(option.text, matches);
        return (
          <Grid container alignItems="center">
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} /*style={{ fontWeight: part.highlight ? 700 : 400 }}*/>{part.text}</span>
              ))}

              <Typography color="textSecondary">{option.region}</Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

AutocompleteMUI.propTypes = {
  options: PropTypes.array,
  def: PropTypes.string,
  value: PropTypes.any,
  mask: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.bool,
  minLength: PropTypes.number.isRequired,
  fetcher: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  fetchTimeout: PropTypes.number,
  disabled: PropTypes.bool,
};

AutocompleteMUI.defaultProps = {
  minLength: 2,
  fetchTimeout: 350,
  disabled: false,
};

export default AutocompleteMUI;
