import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledFormGroup = styled.div`
    position: relative;
    display: inline-block;
    vertical-align: top;
    ${props => props.fullWidth && css`
        display: block;
        width: 100%;
        margin: 0 0 2rem;

        &:last-child{
            margin: 0;
        }
    `}
    /*
    ${props => props.last && css`
        margin: 0;
    `}
    */
    ${props => props.noMargin && css`
        margin: 0;
    `}
    @media (max-width: 639px) {
        margin: 0 0 1rem;
        &:last-child{
            margin: 0;
        }
    }    
`;

export const FormGroup = (props) => {
    return (
        <StyledFormGroup {...props}>
            {props.children}
        </StyledFormGroup>
    );
};

FormGroup.propTypes = {
    fullWidth: PropTypes.bool,
    noMargin: PropTypes.bool,    
    children: PropTypes.node
};

FormGroup.defaultProps = {
    fullWidth: false,
    noMargin: false
};

export default FormGroup;
