import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const CloseButton = styled(IconButton)`
  color: #828282;
  margin-right: 0.5rem;
  width: 3.5rem;
  ${(props) =>
    props.$absolute &&
    css`
      border-radius: 0;
      margin-right: 0;
      position: absolute;
      z-index: 101;
      right: 0.625rem;
      top: 0.625rem;
    `}
`;

export const Place = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #f2f4f5;
  border-radius: 8px 8px 0 0;
  height: 3.5rem;
  .toolbar-big-btn {
    z-index: 10;
    padding: 0px 19px;
    color: #9299a2;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    min-width: 0;
    height: 3.5rem;
    overflow: hidden;
    svg {
      margin-right: 5px;
      stroke: #9299a2;
    }
  }
`;

export const Actions = styled.div`
  position: absolute;
  z-index: 10;
  top: 40px;
  left: 16px;
  background: #ffffff;
  border: 1px solid #eff2f4;
  box-sizing: border-box;
  box-shadow: 2px 10px 20px rgba(163, 177, 198, 0.25);
  border-radius: 5px;
  min-width: 160px;
  .toolbar-btn {
    display: block;
    width: 100%;
    padding: 0px 16px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #000000;
    text-align: left;
    min-width: 0;
    height: 40px;
    border-bottom: 1px solid #eff2f4;
    white-space: nowrap;
  }
`;
