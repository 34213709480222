import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { NavLink } from 'react-router-dom';

import { Button } from 'libs/ui';
import * as userActions from 'store/actions/userActions';
import * as common from 'services/common';
import { MenuStyled, UserName, Content } from './UserMenu.styled';

const UserMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const userLogined = useSelector((state) => state.user.userLogined);
  const [anchorEl, setAnchorEl] = useState(null);
  const fullname = common.getClientName(user.client, user);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = (event) => {
    event.preventDefault();
    setAnchorEl(null);
    dispatch(userActions.logoutUser());
    dispatch(userActions.checkUser());
  };

  const profileAction = () => {
    //event.preventDefault();
    navigate('/profile');
    setAnchorEl(null);
  };

  if (!userLogined) {
    return (
      <Button to="/login" size="small" variant="outlined">
        Войти
      </Button>
    );
  }

  return (
    <Content>
      <UserName>{fullname}</UserName>
      <IconButton aria-label={fullname} aria-controls="menu-appbar1" aria-haspopup="true" onClick={handleOpen} color="inherit">
        <AccountCircle style={{ color: '#959BA4', fontSize: '1.75rem' }} />
      </IconButton>
      <MenuStyled id="menu-appbar1" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem dense onClick={profileAction}>
          Профиль
        </MenuItem>
        <MenuItem dense onClick={logout}>
          Выход
        </MenuItem>
      </MenuStyled>
    </Content>
  );
};

export default UserMenu;
