import { createTheme } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';

import { params } from './themeCommon';
import { DownIcon, CheckedIcon, UncheckedIcon } from 'libs/icons/icons';

const paramsBackend = JSON.parse(JSON.stringify(params));
paramsBackend.overrides.MuiCssBaseline['@global'].body.backgroundColor = '#fff!important';

paramsBackend.props.MuiCheckbox = { checkedIcon: <CheckedIcon />, icon: <UncheckedIcon /> };
paramsBackend.props.MuiSelect = { IconComponent: DownIcon };

const theme = createTheme(paramsBackend, ruRU);

export default theme;