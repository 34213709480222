import styled, { css } from 'styled-components';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import { Title } from 'libs/ui/title/title';

export const StyledTitle = styled(Title)`
  margin: 0 0 4.25rem;
  ${(props) =>
    props.$leftbold &&
    css`
      font-weight: bold;
      text-align: left;
      margin: 0 0 2.25rem;
    `}
`;

export const InnerContent = styled.div`
  height: 100%;
  padding: 3rem 3rem 2rem;
`;

export const Inner = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    border: 0;
  }

  iframe * {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #4f4f4f;
  }
`;

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPaper = styled(Paper)`
  outline: none;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.2);
  padding: 0;
  border: 0;
  display: flex;
  max-height: 100%;
  flex-direction: column;
  min-width: ${(props) => props.$minWidth};
  ${(props) =>
    props.$big &&
    css`
      padding: 5.625rem 5.75rem;
      padding-right: 0;
      width: 80%;
      height: 80%;
      ${Inner} {
        padding: 0;
      }
    `}
  ${(props) =>
    props.$withToolbar &&
    css`
      ${Inner} {
        padding: 0 38px 20px;
      }
    `}
    @media(max-width: 639px) {
    min-width: 280px;
    padding: 3rem 2rem;
    width: 100%;
    height: 100%;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 101;
  right: 0.625rem;
  top: 0.625rem;
  color: #828282;
`;
