import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.div`
  background: #333;
  text-align: center;
  color: #fff;
  font-size: 0.9rem;
  line-height: normal;
  margin: 0 -2rem 2rem;
  padding: 0.6rem 1rem;
`;

export const FormTitle = (props) => {
  return <Title>{props.children}</Title>;
};

FormTitle.propTypes = {
  children: PropTypes.node,
};

export default FormTitle;
