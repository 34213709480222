import styled from 'styled-components';

import { Button  } from 'libs/ui';

export const Place = styled.div`
  position: fixed;
  z-index: 10000;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1rem;
  line-height: 1.87;
  background: #fff;
  color: #4f4f4f;
  padding: 36px 86px 16px;
  border-radius: 3px;
  border-top: 1px solid #e0e0e0;

  @media (max-width: 959px) {
    font-size: 0.8rem;
    line-height: 1.5;
    padding: 1rem 3.5rem 1rem 1rem;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 1.5rem;
  top: 0.75rem;
  transition: 0.2s all ease-in;
  padding: 1rem;
  background-color: #fff;
  min-width: 0;
  border: 0;
  box-shadow: none;
  &:hover {
    opacity: 0.8;
    background-color: #fff;
    box-shadow: none;
  }
  .MuiButton-startIcon {
    margin: 0;
  }
  @media (max-width: 959px) {
    right: 0rem;
    top: 0;
  }
`;
