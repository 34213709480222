import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';


export const StyledDialog = styled(Dialog)``;
export const StyledDialogContent = styled(DialogContent)`
  min-width: 35px;
`;

export const IconPlace = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 101;
  margin-left: -32px;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background: #eff2f4;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
