import styled from 'styled-components';

export const Box = styled.div`
  margin: 1.75rem 0 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const StyledTabPanel = styled.div`
  flex-grow: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;
