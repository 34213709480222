import React from 'react';
import bstrApi from 'api/bstr';
import moment from 'moment';
moment.locale('ru');

import * as common from './common';
import { Button  } from 'libs/ui';

const workDateFormat = config.localeConfig.workDateFormat;
//const viewDateFormat = config.localeConfig.viewDateFormat;

class DocService {

    constructor() {
        this.state = {
        };
    }
  
    /**
     * get document types 
     * @param {*} orgType - one of consts.orgTypes
     * @param {*} type - one of consts.docTypes
     * @param {*} code - one of spec, act, profile, contract, invoice
     */
    getDocumentsTypes = async (orgType, type = '', code ='') => {

        return await bstrApi.getDocuments(orgType, type, code).then((data) => data, this.onError);
    }

    /**
     * send client files to server with test for optional 
     * 
     * @param {*} data - form data
     * @param {*} docs - req docs for client type
     * @param {*} client_id - client id
     * @returns errors in promise
     * @memberof ClientService
     */
    async saveFiles(data, docs, client_id) {

        let errors = {};

        for (const el of docs) {

            const code = el.code.replaceAll('.', '_');
            if (!data[code]) {
                continue;
            }
            const file = data[code];
            const fileContent = await common.readFile(file);

            await bstrApi.postDocuments({
                client_id: client_id,
                file_tag_id: el.id,
                name: file.name,
                body: fileContent//window.btoa(fileContent)
            }).catch(() => {
                errors[el.code] = common.getError(232);
            });
        }
        return errors;
    }

    async loadExtraDoc(client_id, file_tag_id, reservationId = undefined, amount = undefined) {
        const params = {client_id, file_tag_id};
        if (reservationId) {
            params.reservation_id = parseInt(reservationId);
        }
        if (amount) {
            params.amount = parseFloat(amount.replaceAll(',', '.')).toString();
        }
        const body = await bstrApi.generateDocuments(params);
        return body;
        //return decodeURIComponent(escape(window.atob(body.body)));
    }

    genDownloadDoc = (clientId, doc, idx, reservationId) => {
        const file_tag_id = doc.id;
        const onClick = async () => {
            const data = await this.loadExtraDoc(clientId, file_tag_id, reservationId);
            common.downloadFile(data.link, true);
        };
        return <Button onClick={onClick} /*component='a' href={link}*/ key={idx} download icon='download' $nopad={true} $color="link" $mr={true}>{doc.description}</Button>;
    }

    /**
     * generate file report
     * {client_id: string; operation_type: OperationTypesEnum; date_from?: string; date_to?: string; format?: ReportFormatEnum}
     *
     * @memberof ClientService
     */
     generateReport = async (data) => {
        const out = {...data};
        out.date_from = out.date_from ? moment(out.date_from).format(workDateFormat) : undefined;
        out.date_to = out.date_to ? moment(out.date_to).format(workDateFormat) : undefined;

        return await bstrApi.generateReports(out);
    }
    

}

export default new DocService();