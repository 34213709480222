import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const MenuPlace = styled.div`
  position: relative;
`;

export const MenuIcon = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  i {
    position: relative;
    display: block;
    width: 100%;
    height: 9px;
  }
  i::after {
    transition: 0.2s all ease-out;
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -2px;
    border-radius: 50%;
    background: #c4c4c4;
  }
`;

export const MenuButton = styled(Button)`
  margin: -1rem -1rem 0 1rem;
  &.MuiButton-text {
    padding: 0;
    height: 56px;
    min-width: 32px;
  }
  &:hover {
    background-color: transparent;
    i::after {
      background-color: #555;
    }
  }
`;

export const Actions = styled.div`
  position: absolute;
  z-index: 10;
  top: 42px;
  left: 100%;
  margin: 0 0 0 -0.5rem;
  background: #ffffff;
  border: 1px solid #eff2f4;
  box-sizing: border-box;
  box-shadow: 2px 10px 20px rgba(163, 177, 198, 0.25);
  border-radius: 5px;
  max-width: 195px;
  .toolbar-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0px 16px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #000000;
    text-align: left;
    min-width: 0;
    height: 40px;
    border-bottom: 1px solid #eff2f4;
  }
`;
