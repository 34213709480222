//import { applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
//import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';
import formReducer from './reducers/formReducer';
import commonReducer from './reducers/commonReducer';
import contragentReducer from './reducers/contragentReducer';

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({
  reducer: {
    common: commonReducer,
    form: formReducer,
    user: userReducer,
    contragent: contragentReducer,
  },
  //middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [],
});

export default store;
