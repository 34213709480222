import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import styled from 'styled-components';

export const StyledBC = styled(Breadcrumbs)`
  padding-left: 1.75rem;
  position: relative;
  margin: 0 0 1.875rem; //0.9375rem;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin: -10px 0 0;
    width: 20px;
    height: 20px;
    background: url('/images/left.svg') no-repeat 50%;
  }
  a {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #74a3c7;
  }
`;
