import PropTypes from 'prop-types';

import { Link } from 'libs/ui';
import { LeftArrowIcon } from 'libs/icons/icons';
import { StyledBC } from './bc.styled';

export const BC = (props) => {
  const items = props.items.map((item, index) => {
    return (
      <Link key={index} to={item.link}>
        {item.title}
      </Link>
    );
  });

  return (
    <StyledBC separator={<LeftArrowIcon fontSize="small" />} aria-label="breadcrumb">
      {items}
    </StyledBC>
  );
};

BC.propTypes = {
  items: PropTypes.array.isRequired,
};

export default BC;
