import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { InputFieldFinal } from 'libs/form';
import { config } from 'app/config';
const { consts } = config;

const File = styled.div`
  margin: 0 0 3.5rem;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: -3.1875rem;
    top: 0;
    bottom: -3rem;
    width: 4px;
    background: #e9ecee;
    ${(props) =>
      props.$last &&
      css`
        bottom: auto;
        height: 0;
      `}
  }
  &:after {
    content: '';
    position: absolute;
    left: -4.44rem;
    top: -0.9rem;
    width: 2.75rem;
    height: 2.75rem;
    background: #ffffff;
    border: 4px solid #dadada;
    border-radius: 50%;
    ${(props) =>
      props.$ready &&
      css`
        border-color: #5ebb47;
        background-color: #5ebb47;
        background-image: url('/images/checked_white.svg');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 20px 15px;
      `}
  }
`;
const FileTitle = styled.div`
  font-weight: ${(props) => (props.$required ? '500' : '400')};
  font-size: 1.125rem;
  line-height: 1.2222rem;
  color: ${(props) => (props.$required ? '#4F4F4F' : '#9299A2')};
  margin: 0 0 1.875rem;
`;

export class FileLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: props.file.name,
    };
  }

  change = (file) => {
    //console.log('params', params);
    this.setState({ ready: file && !!file.name });
  };

  render() {
    const { file, item, first, last } = this.props;
    const { ready } = this.state;

    const code = item.code.replaceAll('.', '_');
    const icon = '/images/docs/DEFAULT.svg';
    let label = item.title;
    if (file.name) {
      label += ' (загружен)';
    }

    //console.log('file', item);

    return (
      <File $ready={ready} $first={first} $last={last}>
        <FileTitle $required={item.category === consts.docTypes.REQUIRED}>{label}</FileTitle>
        <InputFieldFinal reactChange={this.change} small icon={icon} name={code} type="filedrop" title={'Перетащите или загрузите фотографию'} />
      </File>
    );
  }
}

FileLoader.propTypes = {
  item: PropTypes.object.isRequired,
  file: PropTypes.object,
  first: PropTypes.bool,
  last: PropTypes.bool,
};

FileLoader.defaultProps = {
  file: {},
};

export default FileLoader;
