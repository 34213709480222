import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

export class MaskedInputMUI extends React.Component {
  render() {
    const { value, onChange, mask, disabled, name, maskChar, ...other } = this.props;
    let maskReal = mask;
    switch (maskReal) {
      default:
        maskReal = mask;
        break;
      case 'tel':
        maskReal = '+7 (999) 999-99-99';
        break;
      case 'passport':
        maskReal = '99 99 999999';
        break;
    }

    return (
      <>
        <InputMask
          //onFocus={this.onFocus}
          //onBlur={this.onBlur}
          mask={maskReal}
          maskPlaceholder={maskChar}
          alwaysShowMask={false}
          //guide={true}
          //keepCharPositions={true}
          //showMask={true}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          <TextField {...other} />
        </InputMask>
      </>
    );
  }
}

MaskedInputMUI.propTypes = {
  mask: PropTypes.any.isRequired,
  maskChar: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

MaskedInputMUI.defaultProps = {
  maskChar: null, //'\u2000'
};

export default MaskedInputMUI;
