import styled, { css } from 'styled-components';

export const Content = styled.div`
  background: rgba(255, 255, 255, 0.7);
  min-height: 100px;
  position: relative;
  &:after {
    content: '';
    width: 31px;
    height: 31px;
    background: url('/images/loader.gif') no-repeat center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
  }
  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 999999;
    `}
`;
