import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledFormHeader = styled.div`
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.2083;
    text-align: left;
    color: #4F4F4F;
    margin: 0 0 3rem;

    ${props => props.small && css`
        font-size: 1.375rem;
        margin: 0 0 2.3125rem;
    `}
    ${props => props.small && props.inner && css`
        margin: 0 0 1.25rem;
    `}

    ${props => props.center && css`
        text-align: center;
    `}
    a{
        display: inline-block;
        vertical-align: top;
    }
    a::after {
        opacity: 0 !important;
    }
    a:hover::after{
        opacity: 1 !important;
    }

    @media(max-width: 959px) {
        font-size: 1.3rem;
        line-height: 1.2;
    }    
`;

export const FormHeader = (props) => {
    const { children, ...rest } = props;
    return (
        <StyledFormHeader {...rest}>
            {children}
        </StyledFormHeader>
    );
};
FormHeader.defaultProps = {
    inner: false,
    center: false,
    small: false
};

FormHeader.propTypes = {
    inner: PropTypes.bool,
    center: PropTypes.bool,
    small: PropTypes.bool,
    children: PropTypes.node
};

export default FormHeader;
