import styled, { css } from 'styled-components';

import { Title } from 'libs/ui';

export const Bar = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 2rem;
  ${(props) =>
    props.$center &&
    css`
      justify-content: space-between;
    `}
  ${(props) =>
    props.$bigMargin &&
    css`
      margin: 0 0 3rem;
    `}
    @media (max-width: 639px) {
    margin: 0 0 1rem;
  }
`;

export const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 1.2;
  text-align: left;
  color: #000;
  margin: 0 1rem 0 0;
  ${(props) =>
    props.$nowrap &&
    css`
      white-space: nowrap;
    `}

  ${(props) =>
    props.$small &&
    css`
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.208333333333333;
    `}
`;
