import PropTypes from 'prop-types';

import { Container, Logo } from 'libs/ui';
import MainMenu from './MainMenu';
import UserMenu from './UserMenu';
import { Left, Right, StyledToolbar, StyledTopBar } from './Header.styled';

const Header = (props) => {
  const { $fluid } = props;

  return (
    <StyledTopBar $fluid={$fluid} position="static" color="default">
      <Container $fluid={$fluid} className="header-container">
        <StyledToolbar>
          <Left>
            <Logo />
            <MainMenu />
          </Left>
          <Right>
            <UserMenu />
          </Right>
        </StyledToolbar>
      </Container>
    </StyledTopBar>
  );
};

Header.defaultProps = {
  $fluid: false,
};

Header.propTypes = {
  $fluid: PropTypes.bool,
};

export default Header;
