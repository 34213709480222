//import { PlaceSharp } from '@material-ui/icons';
import moment from 'moment';
moment.locale('ru');

//const workDateFormat = config.localeConfig.workDateFormat;
const viewDateFormat = config.localeConfig.viewDateFormat;
const workDateFormat = config.localeConfig.workDateFormat;
import { config } from 'app/config';
const { consts } = config;

class Validators {
  // prepare
  preparePhone(phone) {
    if (!phone) {
      return '';
    }
    let out = phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').trim();
    if (!out) {
      return out;
    }
    if (out.charAt(0) !== '+') {
      if (out.charAt(0) === '8') {
        out = out.replaceAt(0, '7');
      }
      out = '+' + out;
    }
    if (out === '+7') {
      out = '';
    }
    return out;
  }

  prepareEmail(email) {
    return email.replaceAll(' ', '').trim();
  }

  prepareEmailPhone(alias) {
    const type = alias.indexOf('@') === -1 ? 'phone' : 'email';
    return type === 'phone' ? this.preparePhone(alias) : this.prepareEmail(alias);
  }

  // test
  isEmail(email) {
    const test =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //const test = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return test.test(email);
  }

  isPhone(phone) {
    const test = /^[+][1-9]{1}[0-9]{3}[0-9]{3}[0-9]{4}$/im;
    return test.test(this.preparePhone(phone).trim());
  }

  isEmptyObject(val) {
    return Object.keys(val).length === 0;
  }

  isEmailPhone(alias) {
    const type = alias.indexOf('@') === -1 ? 'phone' : 'email';
    const val = this.prepareEmailPhone(alias);
    return type === 'phone' ? this.isPhone(val) : this.isEmail(val);
  }

  fioTest(fn, data, errors) {
    if (data[fn]) {
      if (data[fn].trim().split(' ').length !== 3) {
        errors[fn] = 10;
      } else {
        const reg = /^[A-ZА-ЯЁ\s-]+$/i;
        if (!reg.test(data[fn])) {
          errors[fn] = 11;
        }
      }
    } else {
      errors[fn] = 9;
    }
  }

  phoneTest(fn, data, errors) {
    if (data[fn]) {
      if (!this.isPhone(data[fn])) {
        errors[fn] = 232;
      }
    } else {
      errors[fn] = 2;
    }
  }

  emailTest(fn, data, errors) {
    if (data[fn]) {
      if (!this.isEmail(data[fn])) {
        errors[fn] = 232;
      }
    } else {
      errors[fn] = 3;
    }
  }

  dateTest(fn, data, errors) {
    if (!data[fn]) {
      errors[fn] = 12;
      return;
    }

    const d = moment(data[fn], viewDateFormat, true);
    if (!d.isValid()) {
      errors[fn] = 232;
      return;
    }

    const year = d.year();
    const now = moment().year();
    if (now - year > 100 || now - year < 18) {
      errors[fn] = 13;
    }
  }

  dateTestSimple(fn, data, errors, testOldness = true) {
    if (!data[fn]) {
      errors[fn] = 15;
      return;
    }

    const d = moment(data[fn], viewDateFormat, true);
    if (!d.isValid()) {
      //console.log(data[fn], viewDateFormat, d.invalidAt());
      errors[fn] = 15;
      return;
    }

    if (testOldness) {
      const year = d.year();
      const now = moment().year();
      //console.log(d, year, now)
      if (now - year > 100 || now - year < 0) {
        errors[fn] = 15;
      }
    }
  }

  dateTestOnlyPast(fn, data, errors, maxYears = 100) {
    let date = data[fn];
    if (!date) {
      return;
    }
    if (typeof date !== 'string') {
      date = moment(date).format(workDateFormat);
    }

    const format = date.indexOf('-') !== -1 ? workDateFormat : viewDateFormat;
    const d = moment(date, format, true);
    if (!d.isValid()) {
      console.log(date, viewDateFormat, d.invalidAt());
      errors[fn] = 15;
      return;
    }

    const year = d.year();
    const now = moment().year();
    //console.log(d, year, now)
    if (year > now || now - year > maxYears) {
      errors[fn] = 15;
    }
  }

  indexTest(fn, data, errors) {
    if (data[fn].length !== 6) {
      errors[fn] = 16;
    }
  }

  passportTest(fn, data, errors) {
    if (data[fn].length !== 10) {
      errors[fn] = 17;
    }
  }

  innTest(fn, data, errors, ip = false) {
    const test = ip ? 12 : 10;
    if (data[fn].length !== test) {
      errors[fn] = ip ? 23 : 17;
    }
  }

  accountTest(fn, data, errors) {
    if (data[fn].length !== 20) {
      errors[fn] = 18;
    }
  }

  ogrnTest(fn, data, errors) {
    if (data[fn].length !== 13) {
      errors[fn] = 19;
    }
  }

  bicTest(fn, data, errors) {
    if (!data[fn] || data[fn].length !== 9) {
      errors[fn] = 20;
      return;
    }

    if (data[fn].substring(0, 2) !== '04') {
      errors[fn] = 22;
    }
  }

  nameTest(fn, data, errors) {
    if (!data[fn]) {
      errors[fn] = 24;
    }
  }

  numberTest(val, withMinusPlus = false, float = true) {
    //console.log('1111', val, typeof val)
    if (typeof val !== 'string') {
      val += ' ';
    }
    val = val.trim();
    if (withMinusPlus) {
      if (val === '-' || val === '+') {
        return true;
      }
    } else {
      const val1 = val.split(/[-+]/);
      if (val1.length > 1) {
        return false;
      }
    }
    if (float) {
      const val1 = val.split(/[,.]/);
      if (val1.length > 2) {
        return false;
      }
      //console.log('4444',val1);
      if (val1.length === 2) {
        val1[1] = val1[1].trim();
        if (val1[1].length > 2) {
          return false;
        }
        if (isNaN(+val1[1])) {
          return false;
        }
      }
      if (isNaN(+val1[0])) {
        return false;
      }
    } else {
      const val1 = val.split(/[,.]/);
      if (val1.length > 1) {
        return false;
      }
      if (isNaN(+val1[0])) {
        return false;
      }
    }
    /*
        if (isNonZero) {
            return (parseFloat(val1[0]) !== 0);
        }
        */
    return true;

    //const test = /^[\+\-][0-9]{1-9}$/im; //([\.\,][0-9]{2})?
    //return test.test(val);
  }

  /**
   * test for files in client forms
   * @param {*} data
   */
  docsTest(docs, data) {
    let errors = {};

    // files
    docs.forEach((el) => {
      const code = el.code.replaceAll('.', '_');

      if (el.category === consts.docTypes.REQUIRED && !data[code]) {
        errors[code] = 25;
      }
    });

    return errors;
  }

  isPlate(plate) {
    const tr = {
      A: 'А',
      B: 'В',
      E: 'Е',
      K: 'К',
      M: 'М',
      H: 'Н',
      O: 'О',
      P: 'Р',
      C: 'С',
      T: 'Т',
      Y: 'У',
      X: 'Х',
    };

    let plateOk = '';
    plate
      .trim()
      .split('')
      .forEach((v) => {
        if (tr[v]) {
          v = tr[v];
        }
        plateOk += v;
      });
    //console.log('plateOk',plateOk)

    //const test = /^[АВЕКМНОРСТУХ]d{3}(?<!000)[АВЕКМНОРСТУХ]{2}d{2,3}$/i; //im
    const test =
      /^(([АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{1,2})(\d{2,3})|(\d{4}[АВЕКМНОРСТУХ]{2})(\d{2})|(\d{3}C?D{1,2}\d{3})(\d{2})|([АВЕКМНОРСТУХ]{2}\d{3}[АВЕКМНОРСТУХ])(\d{2})|([АВЕКМНОРСТУХ]\d{4})(\d{2})|(\d{3}[АВЕКМНОРСТУХ])(\d{2})|(\d{4}[АВЕКМНОРСТУХ])(\d{2}))$/i;
    return test.test(plateOk);
  }
}

export default new Validators();
