import styled, { css } from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export const StyledTopBar = styled(AppBar)`
  background: #fff;
  color: #000;
  position: relative;
  padding: 2px 0;
  height: auto;
  box-shadow: none;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background: #eee;
  }

  ${(props) =>
    props.$fluid &&
    css`
      background: #f5f5f6;
    `}
  @media(max-width: 959px) {
    padding: 0;
    .header-container {
      background: #f2f4f5;
      padding: 0.75rem 1rem;
    }
    .MuiButton-outlined {
      background-color: #fff;
    }
  }
`;
export const StyledToolbar = styled(Toolbar)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  min-height: 0;
  min-height: 52px;
  @media (max-width: 959px) {
    min-height: 0;
  }
`;

export const Left = styled.div`
  margin: 0 1rem 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Right = styled.div`
  color: #959ba4;
`;
