import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';

export const StyledButton = styled(Button)`
  ${(props) =>
    props.$mr &&
    css`
      margin-right: 48px;
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      min-width: 206px;
    `}
    
    ${(props) =>
    props.size === 'small' &&
    css`
      font-size: 0.75rem;
      line-height: 1.25;
      letter-spacing: 0.02em;
      /*
        padding-right: 1rem;
        */
    `}
    ${(props) =>
    (props.$color === 'green' || props.$color === 'cyan') &&
    css`
      color: #000;
      border-radius: 1.5rem;
      background-color: rgba(57, 181, 74, 0.1);
      svg {
        color: #5ebb47;
      }
      &:hover {
        background-color: rgba(57, 181, 74, 0.3) !important;
      }
    `}

    ${(props) =>
    props.$color === 'cyan' &&
    css`
      background-color: #ccecf8;
      color: #004092;
      &:hover {
        background-color: #acccd8 !important;
      }
    `}

    ${(props) =>
    props.$color === 'link' &&
    css`
      font-size: 1rem;
      color: #74a3c7;
      background-color: transparent;
      box-shadow: none;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05) !important;
        box-shadow: none;
      }
    `}
    ${(props) =>
    props.$nopad &&
    props.$color === 'link' &&
    css`
      padding: 0 !important;
      transition: 0.2s all ease-out;
      ${props.color === 'default' &&
      css`
        color: #9299a2;
      `}
      ${props.size === 'small' &&
      css`
        font-size: 0.75rem;
      `}

        &:hover {
        color: #000;
        background-color: transparent !important;
      }
      & svg path {
        stroke: ${props.color === 'default' ? '#9299A2' : '#74A3C7'};
      }
    `}
    
    ${(props) =>
    props.$color === 'outline' &&
    css`
      background: #ffffff;
      border: 1px solid #74a3c7;
      box-sizing: border-box;
      color: #000;
      font-size: 0.75rem;
      line-height: 1.25;
      text-align: left;
      margin: 0 0 1rem;
      padding: 1rem;
      width: 100%;
      & .MuiButton-label {
        justify-content: flex-start;
      }
      & .MuiButton-startIcon {
        width: 3rem;
        height: 3rem;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(116, 163, 199, 0.1);
        border-radius: 50%;
        margin-right: 1rem;
      }
      &:hover {
        background-color: rgba(116, 163, 199, 0.05) !important;
        border-color: #74a3c7;
      }
    `}

    ${(props) =>
    props.$onlyicon &&
    css`
      margin: 0;
      padding: 0;
      border-radius: 50%;
      min-width: 0;
      height: 2rem;
      width: 2rem;
      & .MuiButton-startIcon {
        margin: 0;
      }
    `};

  &.dotted {
    color: #9299a2;
    border: 1px dashed #dadada;
    box-sizing: border-box;
    border-radius: 5px;
  }
`;
