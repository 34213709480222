import PropTypes from 'prop-types';

import { IconHolder } from './icon.styled';

export const Icon = (props) => {
  const { type, ...rest } = props;

  return (
    <IconHolder {...rest}>
      <i className="material-icons">{type}</i>
    </IconHolder>
  );
};

Icon.propTypes = {
  kvsize: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  kvsize: '35px',
  size: '24px',
};

export default Icon;
