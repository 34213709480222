import { useState } from 'react';
import Cookies from 'universal-cookie';

import { StyledButton, Place } from './CookieConfirm.styled';

const token = 'userCookiesConfirm';
const cookies = new Cookies();

export const CookieConfirm = () => {
  const [confirmed, setConfirmed] = useState(cookies.get(token));

  const close = () => {
    cookies.set(token, true, { path: '/', maxAge: 3600 * 24 * 365 });
    setConfirmed(true);
  };

  if (confirmed) {
    return null;
  }

  return (
    <Place>
      Мы используем файлы cookie, чтобы улучшить предоставляемый нашим пользователям сервис.
      <br />
      Продолжая пользоваться сайтом без изменения настроек, вы соглашаетесь с использованием файолв cookie.
      <StyledButton $onlyicon={true} onClick={close} icon="cancel" />
    </Place>
  );
};

export default CookieConfirm;
