import styled from 'styled-components';
import Link from '@material-ui/core/Link';

export const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: ${(props) => (props.$upperCase ? '1rem' : 'inherit')};
  line-height: 1.1875;
  color: ${(props) => props.$color};
  text-transform: ${(props) => (props.$upperCase ? 'uppercase' : 'none')};
`;
