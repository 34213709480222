import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const Alert = styled.div`
  position: relative;
  ${(props) =>
    props.$danger &&
    css`
      padding: 0 0 0 2.5rem;
      background: url('/images/alert.svg') no-repeat 0 0;
    `}
  text-align: ${(props) => (props.$center ? 'center' : 'left')};
  font-size: 0.9375rem;
  line-height: 1.4;
  color: #4f4f4f;
  p {
    margin: 0 0 1em;
  }
  margin: 0 0 1.6875rem;
`;

Alert.propTypes = {
  $danger: PropTypes.bool,
  $center: PropTypes.bool,
};

Alert.defaultProps = {
  $danger: false,
  $center: false,
};

export default Alert;
