import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #eee;
  & .MuiTabs-indicator {
    background-color: #5ebb47;
  }
`;

export const StyledTab = styled(Tab)`
  padding: 1rem 0;
  margin: 0 2rem 0 0;
  min-width: 0;
`;
